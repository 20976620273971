.Bank-heading h2{
  font-size: 18px;
  padding-top: 8px;
}

.Bank-heading i{
  border: 1px solid transparent;
  width: 30px;
  height: 30px;
  padding-left: 2px;
  background-color: #e03131;
  color: white;
  font-size: 25px;
}

.bank-form{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.Bank-heading{
  display: flex;
  align-items: center;
  gap: 8px;
}

.Bank-details form{
width:100%;
height: 70vh;
background: white;
display: flex;
padding-top: 20px;
justify-content: space-around;
/* align-items: center; */
margin-top: 20px;
border-radius: 10px;
gap: 10px;
}

.Bank-details{
  font-family:   monospace;

}

.second-form{
  width:100%;
height: 20vh;
background: white;
padding-top: 20px;
display: flex;
justify-content: space-between;
margin-top: 20px;
border-radius: 10px;
padding-left: 60px;
padding-right: 60px;
}

.btn-6 {
  background: rgb(247,150,192);
background: radial-gradient(circle, rgba(247,150,192,1) 0%, rgba(118,174,241,1) 100%);
  line-height: 42px;
  padding: 0;
  border: none;
}
.btn-6 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.btn-6:before,
.btn-6:after {
  position: absolute;
  content: "";
  height: 0%;
  width: 1px;
 box-shadow:
   -1px -1px 20px 0px rgba(255,255,255,1),
   -4px -4px 5px 0px rgba(255,255,255,1),
   7px 7px 20px 0px rgba(0,0,0,.4),
   4px 4px 5px 0px rgba(0,0,0,.3);
}
.btn-6:before {
  right: 0;
  top: 0;
  transition: all 500ms ease;
}
.btn-6:after {
  left: 0;
  bottom: 0;
  transition: all 500ms ease;
}
.btn-6:hover{
  background: transparent;
  color: #76aef1;
  box-shadow: none;
}
.btn-6:hover:before {
  transition: all 500ms ease;
  height: 100%;
}
.btn-6:hover:after {
  transition: all 500ms ease;
  height: 100%;
}
.btn-6 span:before,
.btn-6 span:after {
  position: absolute;
  content: "";
  box-shadow:
   -1px -1px 20px 0px rgba(255,255,255,1),
   -4px -4px 5px 0px rgba(255,255,255,1),
   7px 7px 20px 0px rgba(0,0,0,.4),
   4px 4px 5px 0px rgba(0,0,0,.3);
}
.btn-6 span:before {
  left: 0;
  top: 0;
  width: 0%;
  height: .5px;
  transition: all 500ms ease;
}
.btn-6 span:after {
  right: 0;
  bottom: 0;
  width: 0%;
  height: .5px;
  transition: all 500ms ease;
}
.btn-6 span:hover:before {
  width: 100%;
}
.btn-6 span:hover:after {
  width: 100%;
}



.Bank-lables{
  display: flex;
  gap: 3px;
  flex-direction: column;
}

.Bank-lables input{
  width:350px;
  height: 7vh;
  border-radius: 5px;
  border: 1px solid #cccccc;
  padding: 5px;
}



.Bank-lables input:focus{
  outline: none;
}


.Bank-details button {
color:white;
background-color: #e03131;
width: 100px;
height: 7vh;
border: transparent;
border-radius: 5px;
margin-top: 20px;
font-size: 18px;
}




@media only screen and (max-width: 790px) {
  .Bank-details form {
      width: 80%;
      height: 120vh;
      background: white;
      display: flex;
      margin: auto;
      margin-top: 20px;
      flex-direction: column;
      align-items: center;
      border-radius: 10px;
  }
  
}

@media only screen and (max-width: 790px) {
  .second-form{
      width:80%;
      height: 30vh;
      background: white;
       display: flex;
       flex-direction: column;
       margin: auto;
       padding-bottom: 30px;
      margin-top: 20px;
      align-items: center;
      margin-top: 20px;
      border-radius: 10px;
  }
}


@media only screen and (max-width: 530px) {
  .Bank-details form {
      width: 90%;
      height: 120vh;
      background: white;
      display: flex;
      margin: auto;
      margin-top: 20px;
      flex-direction: column;
      align-items: center;
      border-radius: 10px;
  }
  .Bank-lables input{
      width:300px;
      height: 7vh;
      border-radius: 5px;
      border: 1px solid #cccccc;
      padding: 5px;
  }
}

@media only screen and (max-width: 530px) {
  .second-form{
      width:90%;
      height: 30vh;
      background: white;
       display: flex;
       flex-direction: column;
       margin: auto;
      margin-top: 20px;
      align-items: center;
      margin-top: 20px;
      border-radius: 10px;
  }
  .Bank-lables input{
      width:300px;
      height: 7vh;
      border-radius: 5px;
      border: 1px solid #cccccc;
      padding: 5px;
  }
}


@media only screen and (max-width: 420px) {
  .Bank-details form {
      width: 90%;
      height: 120vh;
      background: white;
      display: flex;
      margin: auto;
      margin-top: 20px;
      flex-direction: column;
      align-items: center;
      border-radius: 10px;
  }
  .Bank-lables input{
      width:250px;
      height: 7vh;
      border-radius: 5px;
      border: 1px solid #cccccc;
      padding: 5px;
  }
}


@media only screen and (max-width: 420px) {
  .second-form{
      width:90%;
      height: 30vh;
      background: white;
       display: flex;
       flex-direction: column;
       margin: auto;
      margin-top: 20px;
      align-items: center;
      margin-top: 20px;
      border-radius: 10px;
  }
  .Bank-lables input{
      width:250px;
      height: 7vh;
      border-radius: 5px;
      border: 1px solid #cccccc;
      padding: 5px;
  }
}


@media only screen and (max-width: 350px) {
  .Bank-details form {
      width: 90%;
      height: 120vh;
      background: white;
      display: flex;
      margin: auto;
      margin-top: 20px;
      flex-direction: column;
      align-items: center;
      border-radius: 10px;
  }
  .Bank-lables input{
      width:200px;
      height: 6vh;
      font-size: 16px;
      border-radius: 5px;
      border: 1px solid #cccccc;
      padding: 5px;
  }
}


@media only screen and (max-width: 350px) {
  .second-form{
      width:90%;
      height: 25vh;
      background: white;
       display: flex;
       flex-direction: column;
       margin: auto;
      margin-top: 20px;
      align-items: center;
      margin-top: 20px;
      border-radius: 10px;
  }
  .Bank-lables input{
      width:200px;
      height: 6vh;
      font-size: 16px;
      border-radius: 5px;
      border: 1px solid #cccccc;
      padding: 5px;
  }
 
}

@media only screen and (max-width: 300px) {
  .Bank-details form {
      width: 90%;
      height: 100vh;
      background: white;
      display: flex;
      margin: auto;
      margin-top: 20px;
      flex-direction: column;
      align-items: center;
      border-radius: 10px;
  }
  .Bank-lables input{
      width:150px;
      height: 5vh;
      font-size: 14px;
      border-radius: 5px;
      border: 1px solid #cccccc;
      padding: 5px;
  }
}


@media only screen and (max-width: 300px) {
  .second-form{
      width:90%;
      height: 30vh;
      background: white;
       display: flex;
       flex-direction: column;
       margin: auto;
      margin-top: 20px;
      align-items: center;
      margin-top: 20px;
      border-radius: 10px;
  }
  .Bank-lables input{
      width:150px;
      height: 5vh;
      border-radius: 5px;
      font-size: 14px;
      border: 1px solid #cccccc;
      padding: 5px;
  }
 
}

.hidden {
  display: none;
}
