html {
    width: 100%;
    height: 100%;
}

body {
    font-family: 'Open Sans', sans-serif;
    font-family: 'Roboto', sans-serif;
    background-color: #f6f6f6;
    letter-spacing: .1px;
    font-size: 14px;
    overflow-x: hidden
}

a {
    text-decoration: none;
    color: #673ab7;
}

a:hover {
    text-decoration: none;
}

/*Header*/

.wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.top-header {
    width: auto;
    height: 70px;
    line-height: 70px;
    background: #fff;
    position: fixed;
    top: 0;
    left: 260px;
    right: 0;
    z-index: 15;
    border-bottom: 1px solid #e4e4e4;
    box-shadow: none;
}

.sticky-top-header {
    background: #fff;
    border-bottom: 0px solid #e4e4e4;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, .075);
}

.toggle-btn {
    margin-right: 10px;
    font-size: 28px;
    color: #404142;
    width: 45px;
    height: 45px;
    line-height: 45px;
    background: transparent;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
}

.toggle-btn:hover {
    color: #404142;
}

.toggle-btn:active {
    color: #404142;
    background: #f1f1f1;
}

.logo-icon {
    width: 145px;
}

.logo-icon-2 {
    width: 35px;
    margin-left: 10px;
}

.logo-text {
    color: #404142;
    font-size: 25px;
    margin-bottom: 0;
    margin-left: 10px;
}

.top-header .navbar {
    padding: 0 1.5rem;
    background: transparent;
}

.left-topbar {
    width: auto;
    height: 70px;
    float: left;
    background: transparent;
}

.right-topbar {
    width: auto;
    height: 70px;
}

.sidebar-header {
    width: 260px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    height: 70px;
    background: #fff;
    border-right: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    z-index: 1;
    transition: all .2s ease-out;
}

.right-topbar .navbar-nav .nav-link {
    padding: 0rem 1.4rem;
    border-left: 1px solid #e4e4e4;
    color: #404142;
    height: 70px;
    font-size: 25px;
}

.search-bar {
    padding-right: 1.5rem
}

.search-arrow-back {
    display: none;
}

.search-btn-mobile {
    display: none;
}

.search-bar .btn-search-back {
    background: #ffffff;
    border: 1px solid #eaeaea;
    padding: 0 14px;
    border-radius: 0px;
    height: calc(1.6em + 0.75rem + 2px);
}

.search-bar .btn-search-back i {
    font-size: 20px;
    color: #727171;
}

.search-bar .btn-search {
    background: #ffffff;
    border: 1px solid #eaeaea;
    padding: 0 25px;
    border-radius: 0px;
    height: calc(1.6em + .75rem + 2px);
}

.search-bar .btn-search i {
    font-size: 16px;
    color: #727171;
}

.search-bar input {
    height: calc(1.6em + .75rem + 2px);
    border: 0;
    border: 1px solid #eaeaea;
    width: 100%;
    border-radius: 2px;
}

.dropdown-toggle-nocaret:after {
    display: none
}

.user-box {
    line-height: 1.3;
    height: 70px;
}

.user-info {
    text-align: right;
    margin-right: 15px;
}

.user-info .user-name {
    font-size: 16px;
    font-weight: 500;
}

.user-info .designattion {
    font-size: 14px;
    color: #a9a8a8;
}

.user-img {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    border: 1px solid #e5e5e5;
    padding: 3px;
}

.user-img img {
    border-radius: 10%;
}

.msg-count {
    position: absolute;
    left: 36px;
    top: 19px;
    font-size: 11px;
    font-weight: 500;
    width: 17px;
    height: 17px;
    text-align: center;
    border-radius: 50%;
    z-index: 5;
    padding: 0;
    color: #fff;
    background-color: #673ab7;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lang i {
    font-size: 18px !important;
}

.lang span {
    font-size: 18px !important;
    padding-left: 6px;
}

.dropdown-menu {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
    line-height: initial;
    border: 0px solid #e9ecef;
    margin: 0;
    font-size: 14px;
    border-radius: 15px;
}

.top-header .navbar .dropdown-menu {
    -webkit-animation: .6s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 animdropdown;
    animation: .6s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 animdropdown;
}

.top-header .navbar .dropdown-lg .dropdown-menu::after {
    content: '';
    width: 13px;
    height: 13px;
    background: #673ab7;
    position: absolute;
    top: -6px;
    right: 28px;
    transform: rotate(45deg);
    border-top: 1px solid #673ab7;
    border-left: 1px solid #673ab7;
}

.top-header .navbar .dropdown-menu::after {
    content: '';
    width: 13px;
    height: 13px;
    background: #ffff;
    position: absolute;
    top: -6px;
    right: 28px;
    transform: rotate(45deg);
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
}

@-webkit-keyframes animdropdown {
    from {
        -webkit-transform: translate3d(0, 6px, 0);
        transform: translate3d(0, 6px, 0);
        opacity: 0
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

@keyframes animdropdown {
    from {
        -webkit-transform: translate3d(0, 6px, 0);
        transform: translate3d(0, 6px, 0);
        opacity: 0
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

.dropdown-lg {
    position: relative;
}

.dropdown-lg .dropdown-menu {
    width: 355px;
    padding: 0px;
    margin: 0;
    border: 0;
}

.dropdown-lg .dropdown-menu .dropdown-item {
    padding: .5rem 1rem;
    border-bottom: 1px solid #ededed;
}

.dropdown-lg .user-online {
    position: relative;
}

.dropdown-lg .user-online:after {
    content: '';
    position: absolute;
    bottom: 1px;
    right: 17px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #fff;
    background: #16e15e;
}

.dropdown-lg .msg-avatar {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 15px;
}

.dropdown-lg .msg-name {
    font-size: 14px;
    margin-bottom: 0;
}

.dropdown-lg .msg-info {
    font-size: 13px;
    margin-bottom: 0;
}

.dropdown-lg .msg-time {
    font-size: 12px;
    margin-bottom: 0;
    color: #919191;
}

.dropdown-lg .msg-header {
    padding: .5rem 1rem;
    color: #fff;
    border-bottom: 0px solid #ededed;
    background: #673ab7;
    text-align: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.dropdown-lg .msg-header .msg-header-title {
    font-size: 20px;
    margin-bottom: 0;
}

.dropdown-lg .msg-header .msg-header-subtitle {
    font-size: 14px;
    margin-bottom: 0;
}

.dropdown-lg .msg-footer {
    padding: .72rem 1.2rem;
    color: #673ab7;
    border-top: 1px solid #ededed;
    background: transparent;
    font-size: 14px;
    font-weight: 500;
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem;
}

.dropdown-lg .notify {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 22px;
    text-align: center;
    border-radius: 50%;
    background-color: #f1f1f1;
    margin-right: 15px;
}

.dropdown-user-profile .dropdown-menu i {
    vertical-align: middle;
    margin-right: 10px;
}

.dropdown-user-profile .dropdown-menu span {
    vertical-align: middle;
}

.dropdown-language .dropdown-menu i {
    vertical-align: middle;
    margin-right: 10px;
    font-size: 14px;
}

.dropdown-language .dropdown-menu span {
    vertical-align: middle;
    font-size: 14px;
}

.header-notifications-list {
    position: relative;
    height: 370px;
}

.header-message-list {
    position: relative;
    height: 370px;
}

.dashboard-social-list {
    position: relative;
    height: 240px;
}

.dashboard-top-countries {
    position: relative;
    height: 322px;
}

/*Page Wrapper*/

.page-wrapper {
    position: relative;
    margin-top: 70px;
}

.sidebar-wrapper {
    width: 260px;
    height: calc(100% - 70px);
    position: fixed;
    top: 70px;
    left: 0;
    bottom: 0;
    z-index: 16;
    background: #fff;
    border-right: 1px solid #e4e4e4;
    box-shadow: none;
    transition: all .2s ease-out;
}

.wrapper.toggled .top-header {
    left: 80px;
}

.page-content-wrapper {
    margin-left: 260px;
    transition: all .3s ease-out;
    margin-top: 70px;
    padding-bottom: 20px;
}

.wrapper.toggled .page-content-wrapper {
    margin-left: 80px;
}

.wrapper.toggled .footer {
    margin-left: 80px;
}

.page-content {
    padding: 1.5rem;
}

/*Pricing Table */

.lis-bg-light {
    background-color: #00000005
}

.lis-light {
    color: #707070
}

.price-table {
    -webkit-transition: 0.3s ease
}

.lis-brd-light {
    border-color: #dadada !important
}

.lis-rounded-top {
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
}

.lis-bg-light {
    background-color: #f7f7f7
}

.lis-latter-spacing-2 {
    letter-spacing: 2px
}

.lis-font-weight-500 {
    font-weight: 500
}

.price-table sup {
    top: -1.5em
}

.price-table sup,
.price-table small {
    font-size: 1.25rem
}

.price-table small {
    font-size: 1.25rem
}

sup {
    position: relative;
    font-size: 75%;
    line-height: 0
}

.lis-brd-light {
    border-color: #dadada !important
}

.lis-line-height-3 {
    line-height: 3 !important
}

.list-unstyled {
    padding-left: 0;
    list-style: none
}

.lis-line-height-3 {
    line-height: 3 !important
}

.lis-rounded-circle-50 {
    border-radius: 50px
}

.lis-bg-primary {
    background-color: #673ab7
}

.price-table.active {
    transform: scale(1.045);
    -webkit-transform: scale(1.045)
}

.price-table {
    -webkit-transition: 0.3s ease
}

.lis-rounded-bottom {
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px
}

/*Metismenu*/

.sidebar-wrapper ul {
    padding: 0;
    margin: 0;
    list-style: none;
    background: transparent;
}

.sidebar-wrapper .metismenu {
    background: transparent;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.sidebar-wrapper .metismenu li+li {
    margin-top: 5px;
}

.sidebar-wrapper .metismenu li:first-child {
    margin-top: 5px;
}

.sidebar-wrapper .metismenu li:last-child {
    margin-bottom: 5px;
}

.sidebar-wrapper .metismenu>li {
    /*-webkit-box-flex: 1;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
}

.sidebar-wrapper .metismenu a {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 8px 15px;
    font-size: 15px;
    color: #5f5f5f;
    outline-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    letter-spacing: .5px;
    border: 1px solid #ffffff00;
    transition: all .3s ease-out;
}

.sidebar-wrapper .metismenu a .parent-icon {
    font-size: 24px;
    line-height: 1;
}

.sidebar-wrapper .metismenu a .menu-title {
    margin-left: 10px;
}

.sidebar-wrapper .metismenu ul a {
    padding: 6px 15px 6px 15px;
    font-size: 15px;
    border: 0;
}

.sidebar-wrapper .metismenu ul a i {
    margin-right: 10px;
}

.sidebar-wrapper .metismenu ul {
    border: 1px solid #ededed;
    background: #ffffff;
}

.sidebar-wrapper .metismenu ul ul a {
    padding: 8px 15px 8px 30px;
}

.sidebar-wrapper .metismenu ul ul ul a {
    padding: 8px 15px 8px 45px;
}

.sidebar-wrapper .metismenu a:hover,
.sidebar-wrapper .metismenu a:focus,
.sidebar-wrapper .metismenu a:active,
.sidebar-wrapper .metismenu .mm-active>a {
    color: #673ab7;
    text-decoration: none;
    background: rgb(103 58 183 / 10%);
}

.menu-label {
    padding: 20px 15px 5px 5px;
    color: #b0afaf;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.5px;
}

.metismenu .has-arrow:after {
    position: absolute;
    content: "";
    width: .50em;
    height: .50em;
    border-style: solid;
    border-width: 1.2px 0 0 1.2px;
    border-color: initial;
    right: 15px;
    transform: rotate(-45deg) translateY(-50%);
    transform-origin: top;
    top: 50%;
    transition: all .3s ease-out;
}

.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ffffff;
    border-top: 1px solid #cccbcb;
    padding: 8px 15px;
    font-size: 13px;
    text-align: center;
    margin-left: 260px;
    transition: all .3s ease-out;
    z-index: 10;
}

.p-tab-name {
    display: none !important;
}

@media screen and (min-width: 576px) {
    .p-tab-name {
        display: block !important;
    }
}

@media screen and (min-width: 1025px) {
    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .sidebar-header .logo-text {
        display: none;
    }

    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .sidebar-header .logo-icon-2 {
        margin-left: 0px;
    }

    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .sidebar-header .toggle-btn {
        display: none;
    }

    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .sidebar-header {
        justify-content: center;
        padding: 10px;
    }

    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper {
        width: 80px;
    }

    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .sidebar-header {
        width: 80px;
    }

    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .metismenu a {
        justify-content: center;
    }

    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .metismenu .menu-title {
        display: none;
    }

    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .metismenu li ul {
        display: none;
    }

    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .metismenu li.menu-label {
        display: none;
    }

    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .metismenu .has-arrow:after {
        display: none;
    }

    /* .sidebar-header {
        display: none !important;
    } */
    .chat-toggle-btn {
        display: none !important;
    }

    .email-toggle-btn {
        display: none !important;
    }

    .left-topbar {
        display: none !important;
    }
}

/*Forms */

input::placeholder {
    color: #000 !important;
    opacity: .3 !important
}

.custom-file-label {
    font-size: 1rem;
}

.form-check-label {
    font-size: 1rem;
}

.form-text {
    font-size: 13px;
}

.form-check-input:checked {
    background-color: #673ab7;
    border-color: #673ab7;
}

.form-check-input[type=checkbox]:indeterminate {
    background-color: rgba(104, 58, 183, 0.5);
    border-color: rgba(104, 58, 183, 0.5);
}

.form-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(104, 58, 183, 0.25);
}

.form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(104, 58, 183, 0.25);
}

.form-range::-moz-range-thumb {
    background-color: #673ab7;
}

.invalid-feedback {
    font-size: 100%;
    margin-top: 0.55rem;
}

.custom-control-label {
    font-size: 1rem;
}

.select2-container--bootstrap4 .select2-selection--single {
    font-size: 1rem;
}

.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__rendered {
    font-size: 1rem;
}

.select2-sm .select2-container--bootstrap4 .select2-selection {
    height: calc(1.5em + .5rem + 2px);
    font-size: .875rem;
}

.select2-container--bootstrap4 .select2-dropdown {
    border-color: rgba(206, 212, 218, 0.55);
    border-radius: 0;
    box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, .10);
}

.g-r-left .select2-container--bootstrap4 .select2-selection {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.g-r-right .select2-container--bootstrap4 .select2-selection {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.scrollspy-1 {
    height: 200px;
    position: relative;
    overflow-y: scroll;
}

.scrollspy-2 {
    height: 200px;
    position: relative;
    overflow-y: scroll;
}

.chip {
    display: inline-block;
    height: 32px;
    padding: 0 12px;
    margin-right: 1rem;
    margin-bottom: 1rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.70);
    cursor: pointer;
    background-color: #f1f1f1;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
    box-shadow: none;
}

.chip img {
    float: left;
    width: 32px;
    height: 32px;
    margin: 0 8px 0 -12px;
    border-radius: 50%;
}

.chip .closebtn {
    padding-left: 10px;
    font-weight: bold;
    float: right;
    font-size: 16px;
    cursor: pointer;
}

.chip.chip-md {
    height: 42px;
    line-height: 42px;
    border-radius: 21px;
}

.chip.chip-md img {
    height: 42px;
    width: 42px;
}

.chip.chip-lg {
    height: 52px;
    line-height: 52px;
    border-radius: 26px;
}

.chip.chip-lg img {
    height: 52px;
    width: 52px;
}

/*Components */

.card {
    box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, .10);
    border: 1px solid rgba(0, 0, 0, 0);
    margin-bottom: 1.5rem;
}

.card-header {
    background-color: transparent;
    font-size: 16px;
    text-transform: capitalize;
}

.card-group {
    margin-bottom: 30px;
}

.card-group {
    box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, .10);
}

.card-group .card {
    box-shadow: none;
}

.metismenu-card .card-header {
    cursor: pointer;
}

.card-deck .card {
    margin-bottom: 30px;
}

/*Buttons*/

.btn {
    text-transform: capitalize;
}

.btn-primary {
    color: #fff;
    background-color: #673ab7;
    border-color: #673ab7;
}

.btn-primary {
    color: #fff;
    background-color: #673ab7;
    border-color: #673ab7
}

.btn-primary:hover {
    color: #fff;
    background-color: #5930a1;
    border-color: #5930a1
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
    color: #fff;
    background-color: #5930a1;
    border-color: #5930a1;
    box-shadow: 0 0 0 .25rem rgba(89, 48, 161, 0.5)
}

.btn-check:active+.btn-primary,
.btn-check:checked+.btn-primary,
.btn-primary.active,
.btn-primary:active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #5930a1;
    border-color: #5930a1
}

.btn-check:active+.btn-primary:focus,
.btn-check:checked+.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(89, 48, 161, 0.5)
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #673ab7;
    border-color: #673ab7
}

.btn-success {
    color: #fff;
    background-color: #32ab13;
    border-color: #32ab13
}

.btn-success:hover {
    color: #fff;
    background-color: #2c9b10;
    border-color: #2c9b10
}

.btn-check:focus+.btn-success,
.btn-success:focus {
    color: #fff;
    background-color: #2c9b10;
    border-color: #2c9b10;
    box-shadow: 0 0 0 .25rem rgba(44, 155, 16, 0.5)
}

.btn-check:active+.btn-success,
.btn-check:checked+.btn-success,
.btn-success.active,
.btn-success:active,
.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #2c9b10;
    border-color: #2c9b10
}

.btn-check:active+.btn-success:focus,
.btn-check:checked+.btn-success:focus,
.btn-success.active:focus,
.btn-success:active:focus,
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(44, 155, 16, 0.5)
}

.btn-success.disabled,
.btn-success:disabled {
    color: #fff;
    background-color: #32ab13;
    border-color: #32ab13
}

.btn-danger {
    color: #fff;
    background-color: #f02769;
    border-color: #f02769
}

.btn-danger:hover {
    color: #fff;
    background-color: #e42061;
    border-color: #e42061
}

.btn-check:focus+.btn-danger,
.btn-danger:focus {
    color: #fff;
    background-color: #e42061;
    border-color: #e42061;
    box-shadow: 0 0 0 .25rem rgba(228, 32, 97, 0.5)
}

.btn-check:active+.btn-danger,
.btn-check:checked+.btn-danger,
.btn-danger.active,
.btn-danger:active,
.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #e42061;
    border-color: #e42061
}

.btn-check:active+.btn-danger:focus,
.btn-check:checked+.btn-danger:focus,
.btn-danger.active:focus,
.btn-danger:active:focus,
.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(228, 32, 97, 0.5)
}

.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: #e42061;
    border-color: #e42061
}

.btn-warning {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-warning:hover {
    color: #000;
    background-color: #f5c127;
    border-color: #f5c127
}

.btn-check:focus+.btn-warning,
.btn-warning:focus {
    color: #000;
    background-color: #f5c127;
    border-color: #f5c127;
    box-shadow: 0 0 0 .25rem rgba(245, 194, 39, 0.5)
}

.btn-check:active+.btn-warning,
.btn-check:checked+.btn-warning,
.btn-warning.active,
.btn-warning:active,
.show>.btn-warning.dropdown-toggle {
    color: #000;
    background-color: #f5c127;
    border-color: #f5c127
}

.btn-check:active+.btn-warning:focus,
.btn-check:checked+.btn-warning:focus,
.btn-warning.active:focus,
.btn-warning:active:focus,
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(245, 194, 39, 0.5)
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #000;
    background-color: #f5c127;
    border-color: #f5c127
}

.btn-info {
    color: #ffffff;
    background-color: #198fed;
    border-color: #198fed
}

.btn-info:hover {
    color: #ffffff;
    background-color: #1684dd;
    border-color: #1684dd
}

.btn-check:focus+.btn-info,
.btn-info:focus {
    color: #ffffff;
    background-color: #1684dd;
    border-color: #1684dd;
    box-shadow: 0 0 0 .25rem rgba(22, 132, 221, 0.5)
}

.btn-check:active+.btn-info,
.btn-check:checked+.btn-info,
.btn-info.active,
.btn-info:active,
.show>.btn-info.dropdown-toggle {
    color: #ffffff;
    background-color: #3dd5f3;
    border-color: #25cff2
}

.btn-check:active+.btn-info:focus,
.btn-check:checked+.btn-info:focus,
.btn-info.active:focus,
.btn-info:active:focus,
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(22, 132, 221, 0.5)
}

.btn-info.disabled,
.btn-info:disabled {
    color: #ffffff;
    background-color: #198fed;
    border-color: #198fed
}

.btn-outline-primary {
    color: #673ab7;
    border-color: #673ab7
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #673ab7;
    border-color: #673ab7
}

.btn-check:focus+.btn-outline-primary,
.btn-outline-primary:focus {
    box-shadow: 0 0 0 .25rem rgba(89, 48, 161, 0.5)
}

.btn-check:active+.btn-outline-primary,
.btn-check:checked+.btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
    color: #fff;
    background-color: #673ab7;
    border-color: #673ab7
}

.btn-check:active+.btn-outline-primary:focus,
.btn-check:checked+.btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus {
    box-shadow: 0 0 0 .25rem rgba(89, 48, 161, 0.5)
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #673ab7;
    background-color: transparent
}

.btn-outline-success {
    color: #32ab13;
    border-color: #32ab13
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #32ab13;
    border-color: #32ab13
}

.btn-check:focus+.btn-outline-success,
.btn-outline-success:focus {
    box-shadow: 0 0 0 .25rem rgba(44, 155, 16, 0.5)
}

.btn-check:active+.btn-outline-success,
.btn-check:checked+.btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active {
    color: #fff;
    background-color: #32ab13;
    border-color: #32ab13
}

.btn-check:active+.btn-outline-success:focus,
.btn-check:checked+.btn-outline-success:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus,
.btn-outline-success:active:focus {
    box-shadow: 0 0 0 .25rem rgba(44, 155, 16, 0.5)
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #32ab13;
    background-color: transparent
}

.btn-outline-danger {
    color: #f02769;
    border-color: #f02769
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #f02769;
    border-color: #f02769
}

.btn-check:focus+.btn-outline-danger,
.btn-outline-danger:focus {
    box-shadow: 0 0 0 .25rem rgba(228, 32, 97, 0.5)
}

.btn-check:active+.btn-outline-danger,
.btn-check:checked+.btn-outline-danger,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show,
.btn-outline-danger:active {
    color: #fff;
    background-color: #f02769;
    border-color: #f02769
}

.btn-check:active+.btn-outline-danger:focus,
.btn-check:checked+.btn-outline-danger:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus,
.btn-outline-danger:active:focus {
    box-shadow: 0 0 0 .25rem rgba(228, 32, 97, 0.5)
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #f02769;
    background-color: transparent
}

.btn-outline-info {
    color: #198fed;
    border-color: #198fed
}

.btn-outline-info:hover {
    color: #000;
    background-color: #198fed;
    border-color: #198fed
}

.btn-check:focus+.btn-outline-info,
.btn-outline-info:focus {
    box-shadow: 0 0 0 .25rem rgba(22, 132, 221, 0.5)
}

.btn-check:active+.btn-outline-info,
.btn-check:checked+.btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active {
    color: #000;
    background-color: #198fed;
    border-color: #198fed
}

.btn-check:active+.btn-outline-info:focus,
.btn-check:checked+.btn-outline-info:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus,
.btn-outline-info:active:focus {
    box-shadow: 0 0 0 .25rem rgba(22, 132, 221, 0.5)
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #198fed;
    background-color: transparent
}

.accordion-button:not(.collapsed) {
    color: #673ab7;
    background-color: rgb(103 58 183 / 12%);
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-button:focus {
    border-color: #673ab7;
    box-shadow: 0 0 0 0.25rem rgb(103 58 183 / 25%);
}

.list-group-item.active {
    background-color: #673ab7;
    border-color: #673ab7;
}

.nav-link {
    color: #673ab7;
}

.page-link {
    color: #673ab7;
}

.page-link:focus {
    z-index: 3;
    color: #673ab7;
    background-color: #e9ecef;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(104, 58, 183, 0.5)
}

.page-item.active .page-link {
    background-color: #673ab7;
    border-color: #673ab7;
}

.progress-bar {
    background-color: #673ab7;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #673ab7;
}

.btn i {
    vertical-align: middle;
    font-size: 1.3rem;
    margin-top: -1em;
    margin-bottom: -1em;
}

.btn-white {
    color: #212529;
    background-color: #ffffff;
    border-color: #ced4da;
}

.btn-light-primary {
    color: #673ab7;
    background-color: rgb(103 58 183 / 30%);
    border-color: rgb(103 58 183 / 30%);
}

.btn-light-success {
    color: #32ab13;
    background-color: rgb(50 171 19 / 30%);
    border-color: rgb(50 171 19 / 30%);
}

.btn-light-danger {
    color: #f02769;
    background-color: rgb(240 39 105 / 30%);
    border-color: rgb(240 39 105 / 30%);
}

.btn-light-info {
    color: #198fed;
    background-color: rgb(25 143 237 / 30%);
    border-color: rgb(25 143 237 / 30%);
}

.btn-light-warning {
    color: #212529;
    background-color: rgb(255 193 7 / 30%);
    border-color: rgb(255 193 7 / 30%);
}

.btn-light-secondary {
    color: #5a7684;
    background-color: rgb(90 118 132 / 30%);
    border-color: rgb(90 118 132 / 30%);
}

.btn-light-dark {
    color: #343a40;
    background-color: rgb(52 58 64 / 30%);
    border-color: rgb(52 58 64 / 30%);
}

.btn-facebook {
    box-shadow: 0 2px 2px 0 rgba(59, 89, 152, 0.14), 0 3px 1px -2px rgba(59, 89, 152, 0.2), 0 1px 5px 0 rgba(59, 89, 152, 0.12);
    background-color: #3b5998;
    border-color: #3b5998;
    color: #fff;
}

.btn-facebook:hover {
    color: #fff;
}

.btn-facebook:focus {
    box-shadow: 0 0 0 .2rem rgba(59, 89, 152, 0.5)
}

.btn-twitter {
    box-shadow: 0 2px 2px 0 rgba(85, 172, 238, 0.14), 0 3px 1px -2px rgba(85, 172, 238, 0.2), 0 1px 5px 0 rgba(85, 172, 238, 0.12);
    background-color: #55acee;
    border-color: #55acee;
    color: #fff;
}

.btn-twitter:hover {
    color: #fff;
}

.btn-twitter:focus {
    box-shadow: 0 0 0 .2rem rgba(85, 172, 238, 0.5)
}

.btn-linkedin {
    box-shadow: 0 2px 2px 0 rgba(9, 118, 180, 0.14), 0 3px 1px -2px rgba(9, 118, 180, 0.2), 0 1px 5px 0 rgba(9, 118, 180, 0.12);
    background-color: #0976b4;
    border-color: #0976b4;
    color: #fff;
}

.btn-linkedin:hover {
    color: #fff;
}

.btn-linkedin:focus {
    box-shadow: 0 0 0 .2rem rgba(9, 118, 180, 0.5)
}

.btn-youtube {
    box-shadow: 0 2px 2px 0 rgba(229, 45, 39, 0.14), 0 3px 1px -2px rgba(229, 45, 39, 0.2), 0 1px 5px 0 rgba(229, 45, 39, 0.12);
    background-color: #e52d27;
    border-color: #e52d27;
    color: #fff;
}

.btn-youtube:hover {
    color: #fff;
}

.btn-youtube:focus {
    box-shadow: 0 0 0 .2rem rgba(229, 45, 39, 0.5)
}

.btn-github {
    box-shadow: 0 2px 2px 0 rgba(51, 51, 51, 0.14), 0 3px 1px -2px rgba(51, 51, 51, 0.2), 0 1px 5px 0 rgba(51, 51, 51, 0.12);
    background-color: #333333;
    border-color: #333333;
    color: #fff;
}

.btn-github:hover {
    color: #fff;
}

.btn-github:focus {
    box-shadow: 0 0 0 .2rem rgba(51, 51, 51, 0.5)
}

.btn-skype {
    box-shadow: 0 2px 2px 0 rgba(85, 172, 238, 0.14), 0 3px 1px -2px rgba(85, 172, 238, 0.2), 0 1px 5px 0 rgba(85, 172, 238, 0.12);
    background-color: #00aff0;
    border-color: #00aff0;
    color: #fff;
}

.btn-skype:hover {
    color: #fff;
}

.btn-skype:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 175, 240, 0.5)
}

.btn-pinterest {
    box-shadow: 0 2px 2px 0 rgba(204, 33, 39, 0.14), 0 3px 1px -2px rgba(204, 33, 39, 0.2), 0 1px 5px 0 rgba(204, 33, 39, 0.12);
    background-color: #cc2127;
    border-color: #cc2127;
    color: #fff;
}

.btn-pinterest:hover {
    color: #fff;
}

.btn-pinterest:focus {
    box-shadow: 0 0 0 .2rem rgba(204, 33, 39, 0.5)
}

.btn-dribbble {
    box-shadow: 0 2px 2px 0 rgba(234, 76, 137, 0.14), 0 3px 1px -2px rgba(234, 76, 137, 0.2), 0 1px 5px 0 rgba(234, 76, 137, 0.12);
    background-color: #ea4c89;
    border-color: #ea4c89;
    color: #fff;
}

.btn-dribbble:hover {
    color: #fff;
}

.btn-dribbble:focus {
    box-shadow: 0 0 0 .2rem rgba(234, 76, 137, 0.5)
}

.btn-group-round button:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.btn-group-round button:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

/*Background Colors*/

.bg-primary {
    background-color: #673ab7 !important;
}

.bg-secondary {
    background-color: #5a7684 !important;
}

.bg-success {
    background-color: #32ab13 !important;
}

.bg-danger {
    background-color: #f02769 !important;
}

.bg-warning {
    background-color: #ffc107 !important;
}

.bg-info {
    background-color: #198fed !important;
}

.bg-dark {
    background-color: #343a40 !important;
}

.bg-light {
    background-color: #f8f9fa !important;
}

.bg-login-color {
    background-color: #5f4a99 !important;
}

.bg-rose {
    background-color: #ff007c !important;
}

.bg-voilet {
    background-color: #7d00b5 !important;
}

.bg-dribbble {
    background-color: #ea4c89 !important;
}

.bg-facebook {
    background-color: #3b5998 !important;
}

.bg-twitter {
    background-color: #55acee !important;
}

.bg-google {
    background-color: #e52d27 !important;
}

.bg-linkedin {
    background-color: #0976b4 !important;
}

.bg-youtube {
    background-color: #f8130b !important;
}

.bg-tumblr {
    background-color: #34526f !important;
}

.bg-vimeo {
    background-color: #86c9ef !important;
}

.bg-body {
    background-color: #f6f6f6 !important;
}

.bg-gray {
    background-color: #dee1e6 !important;
}

.bg-red-light {
    background-color: #fe6555 !important;
}

.bg-primary-blue {
    background-color: #265ed7 !important;
}

.bg-dark-blue {
    background-color: #0c1b7a !important;
}

.bg-shine-info {
    background-color: #54dee4 !important;
}

.bg-wall {
    background-color: #00cd98 !important;
}

.bg-sunset {
    background-color: #ff8b01 !important;
}

.bg-light-primary {
    background-color: rgb(103 58 183 / 15%) !important;
}

.bg-light-success {
    background-color: rgb(50 171 19 / 15%) !important
}

.bg-light-danger {
    background-color: rgb(250 146 180 / 15%) !important;
}

.bg-light-warning {
    background-color: rgb(255 193 7 / 15%) !important
}

.bg-light-info {
    background-color: rgb(205, 233, 255) !important;
}

.bg-light-purple {
    background-color: #dcdcff !important;
}

.bg-light-shineblue {
    background-color: rgb(1 215 255 / 15%) !important
}

.bg-light-cyne {
    background-color: rgb(0 139 139 / 15%) !important
}

.bg-split-primary {
    background-color: #4f2894;
    border-color: #4f2894;
}

.bg-light-sinata {
    background-color: rgb(165 43 187 / 15%) !important
}

.bg-light-mehandi {
    background: rgb(195 240 36 / 26%) !important
}

.bg-gradient-danger {
    background-image: linear-gradient(to left, #ff758c 0%, #ff7eb3 100%);
}

.bg-gradient-voilet {
    background-image: linear-gradient(to left, #a18cd1 0%, #fbc2eb 100%);
}

.bg-gradient-success {
    background-image: linear-gradient(to left, #05ac9d 0%, #20cfbf 100%);
}

.bg-gradient-mehandi {
    background-image: linear-gradient(to right, #dbe287, #cac531);
}

/*Text Colors*/

.text-primary {
    color: #673ab7 !important;
}

.text-danger {
    color: #f02769 !important;
}

.text-success {
    color: #32ab13 !important;
}

.text-facebook {
    color: #3b5998 !important;
}

.text-twitter {
    color: #55acee !important;
}

.text-youtube {
    color: #f8130b !important;
}

.text-linkedin {
    color: #0a66c2 !important;
}

.text-skype {
    color: #00aff0 !important;
}

.text-purple {
    color: #8280ff !important;
}

.text-shineblue {
    color: #01d7ff !important;
}

.text-cyne {
    color: #008b8b !important;
}

.text-primary-blue {
    color: #265ed7 !important;
}

.text-dark-blue {
    color: #0c1b7a !important;
}

.text-shine-info {
    color: #54dee4 !important;
}

.text-grey {
    color: #dee1e6 !important;
}

.text-red {
    color: #ff392b !important;
}

.text-sinata {
    color: #a52bbb !important;
}

.text-mehandi {
    color: #839838 !important;
}

.border-lg-top-primary {
    border-top: 4px solid #673ab7;
}

.border-lg-top-info {
    border-top: 4px solid #198fed;
}

.border-lg-top-danger {
    border-top: 4px solid #f02769;
}

.icon-color-1 {
    color: #ff3030;
}

.icon-color-2 {
    color: #ff007c;
}

.icon-color-3 {
    color: #4CAF50;
}

.icon-color-4 {
    color: #ff8b01;
}

.icon-color-5 {
    color: #a400ed;
}

.icon-color-6 {
    color: #009688;
}

.icon-color-7 {
    color: #03A9F4;
}

.icon-color-8 {
    color: #c37458;
}

.icon-color-9 {
    color: #265ed7;
}

.icon-color-10 {
    color: #30d4e4;
}

.icon-color-11 {
    color: #ca9805;
}

.icon-color-12 {
    color: #a24f30;
}

/*Authentication*/

.section-authentication-login {
    height: 100vh;
    padding-top: 1.0rem;
    padding-bottom: 1.0rem
}

.section-authentication-register {
    height: 100%;
    padding: 1.5rem;
}

.authentication-forgot {
    height: 100vh;
    padding: 0 1rem;
}

.forgot-box {
    width: 27rem;
    border-radius: 30px;
}

.authentication-reset-password {
    height: 100vh;
    padding: 0 1rem;
}

.authentication-lock-screen {
    height: 100vh;
    padding: 0 1rem;
}

.error-404 {
    height: 100vh;
    padding: 0 1rem;
}

.login-card {
    border-right: 1px solid #dee2e6 !important;
}

.error-social a {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    margin: 5px;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

.profile-social a {
    display: inline-block;
    width: 33px;
    height: 33px;
    line-height: 33px;
    font-size: 15px;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    margin: 2px;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

.bg-forgot {
    /* background-image: url(../images/login-images/bg-forgot-password.jpg); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.bg-lock-screen {
    /* background-image: url(../images/login-images/bg-lock-screen.jpg); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.bg-login {
    /* background-image: url(../images/login-images/bg-login-img.jpg); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.bg-register {
    /* background-image: url(../images/login-images/bg-register-img.jpg); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.bg-coming-soon {
    /* background-image: url(../images/login-images/bg-register-img.jpg); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

/*File Manager*/

.fm-wrapper {
    position: relative;
}

.fm-left-sidebar {
    background: #ffffff;
    width: 220px;
    height: 100%;
    position: fixed;
    padding: 10px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.fm-body {
    background: white;
    width: 100%;
    height: 100%;
}

.fm-menu .list-group a {
    font-size: 16px;
    color: #5f5f5f;
    display: flex;
    align-items: center;
}

.fm-menu .list-group a i {
    font-size: 23px;
}

.fm-menu .list-group a:hover {
    background: #673ab7;
    color: #ffffff;
    transition: all .2s ease-out;
}

.fm-icon-box {
    font-size: 32px;
    background: #ffffff;
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .25rem;
}

.fm-file-box {
    font-size: 25px;
    background: #e9ecef;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .25rem;
}

.user-groups img {
    margin-left: -14px;
    border: 1px solid #e4e4e4;
    padding: 2px;
    cursor: pointer;
}

.user-plus {
    width: 33px;
    height: 33px;
    margin-left: -14px;
    line-height: 33px;
    background: #ffffff;
    border-radius: 50%;
    text-align: center;
    font-size: 22px;
    cursor: pointer;
    border: 1px dotted #a9b2bb;
    color: #404142;
}

/*Tables*/

.table-responsive {
    white-space: nowrap;
}

.table td,
.table th {
    vertical-align: middle;
    color: #6d757b;
}

/*Invoice Page*/

#invoice {
    padding: 0px;
}

.invoice {
    position: relative;
    background-color: #FFF;
    min-height: 680px;
    padding: 15px
}

.invoice header {
    padding: 10px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid #673ab7
}

.invoice .company-details {
    text-align: right
}

.invoice .company-details .name {
    margin-top: 0;
    margin-bottom: 0
}

.invoice .contacts {
    margin-bottom: 20px
}

.invoice .invoice-to {
    text-align: left
}

.invoice .invoice-to .to {
    margin-top: 0;
    margin-bottom: 0
}

.invoice .invoice-details {
    text-align: right
}

.invoice .invoice-details .invoice-id {
    margin-top: 0;
    color: #673ab7
}

.invoice main {
    padding-bottom: 50px
}

.invoice main .thanks {
    margin-top: -100px;
    font-size: 2em;
    margin-bottom: 50px
}

.invoice main .notices {
    padding-left: 6px;
    border-left: 6px solid #673ab7;
    background: #e7f2ff;
    padding: 10px;
}

.invoice main .notices .notice {
    font-size: 1.2em
}

.invoice table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 20px
}

.invoice table td,
.invoice table th {
    padding: 15px;
    background: #eee;
    border-bottom: 1px solid #fff
}

.invoice table th {
    white-space: nowrap;
    font-weight: 400;
    font-size: 16px
}

.invoice table td h3 {
    margin: 0;
    font-weight: 400;
    color: #673ab7;
    font-size: 1.2em
}

.invoice table .qty,
.invoice table .total,
.invoice table .unit {
    text-align: right;
    font-size: 1.2em
}

.invoice table .no {
    color: #fff;
    font-size: 1.6em;
    background: #673ab7
}

.invoice table .unit {
    background: #ddd
}

.invoice table .total {
    background: #673ab7;
    color: #fff
}

.invoice table tbody tr:last-child td {
    border: none
}

.invoice table tfoot td {
    background: 0 0;
    border-bottom: none;
    white-space: nowrap;
    text-align: right;
    padding: 10px 20px;
    font-size: 1.2em;
    border-top: 1px solid #aaa
}

.invoice table tfoot tr:first-child td {
    border-top: none
}

.invoice table tfoot tr:last-child td {
    color: #673ab7;
    font-size: 1.4em;
    border-top: 1px solid #673ab7
}

.invoice table tfoot tr td:first-child {
    border: none
}

.invoice footer {
    width: 100%;
    text-align: center;
    color: #777;
    border-top: 1px solid #aaa;
    padding: 8px 0
}

@media print {
    .invoice {
        font-size: 11px !important;
        overflow: hidden !important
    }

    .invoice footer {
        position: absolute;
        bottom: 10px;
        page-break-after: always
    }

    .invoice>div:last-child {
        page-break-before: always
    }
}

.main-row {
    height: 100vh;
}

.main-col {
    max-width: 500px;
    min-height: 300px;
}

.todo-done {
    text-decoration: line-through;
}

/*Chat box*/

.chat-wrapper {
    width: auto;
    height: 600px;
    border-radius: 0.25rem;
    position: relative;
    background: #ffffff;
    box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, .10);
}

.chat-sidebar {
    width: 340px;
    height: 100%;
    position: absolute;
    background: #ffffff;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    overflow: hidden;
    border-right: 1px solid rgba(0, 0, 0, .125);
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.chat-sidebar-header {
    width: auto;
    height: auto;
    position: relative;
    background: #ffffff;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    border-right: 0px solid rgba(0, 0, 0, .125);
    border-top-left-radius: 0.25rem;
    padding: 15px;
}

.chat-sidebar-content {
    padding: 0px;
}

.chat-user-online {
    position: relative;
}

.chat-sidebar-header .chat-user-online:before {
    content: '';
    position: absolute;
    bottom: 7px;
    left: 40px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #fff;
    background: #16e15e;
}

.chat-list .chat-user-online:before {
    content: '';
    position: absolute;
    bottom: 7px;
    left: 36px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #fff;
    background: #16e15e;
}

.chat-content {
    margin-left: 340px;
    padding: 85px 15px 15px 15px;
}

.chat-header {
    position: absolute;
    height: 70px;
    left: 340px;
    right: 0;
    top: 0;
    padding: 15px;
    background: #ffffff;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    border-top-right-radius: 0.25rem;
    z-index: 1;
}

.chat-footer {
    position: absolute;
    height: 70px;
    left: 340px;
    right: 0;
    bottom: 0;
    padding: 15px;
    background: #f8f9fa;
    border-top: 1px solid rgba(0, 0, 0, .125);
    border-bottom-right-radius: 0.25rem;
}

.chat-footer-menu a {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    color: #6c757d;
    text-align: center;
    border-radius: 50%;
    margin: 3px;
    background-color: white;
    border: 1px solid rgb(0 0 0 / 15%);
}

.chat-tab-menu li a.nav-link {
    padding: .3rem 0.2rem;
    line-height: 1.2;
    color: #4a4b4c;
}

.chat-tab-menu .nav-pills .nav-link.active,
.chat-tab-menu .nav-pills .show>.nav-link {
    color: #673ab7;
    background-color: rgb(0 123 255 / 0%);
}

.chat-title {
    font-size: 16px;
    color: #272b2f;
}

.chat-msg {
    font-size: 14px;
    color: #6c757d;
}

.chat-time {
    font-size: 13px;
    color: #6c757d;
}

.chat-list {
    position: relative;
    height: 300px;
}

.chat-list .list-group-item {
    border: 1px solid rgb(0 0 0 / 0%);
    background-color: transparent;
}

.chat-list .list-group-item:hover {
    border: 1px solid rgb(0 0 0 / 0%);
    background-color: rgb(103 58 183 / 15%);
}

.chat-list .list-group-item.active {
    background-color: rgb(103 58 183 / 15%);
}

.chart-online {
    color: #16e15e;
}

.chat-top-header-menu a {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    color: #6c757d;
    text-align: center;
    border-radius: 50%;
    margin: 3px;
    background-color: white;
    border: 1px solid rgb(0 0 0 / 15%);
}

.chat-content {
    position: relative;
    width: auto;
    height: 520px;
}

.chat-content-leftside .chat-left-msg {
    width: fit-content;
    background-color: #eff2f5;
    padding: 0.80rem;
    border-radius: 12px;
    max-width: 480px;
    text-align: left;
    border-top-left-radius: 0;
}

.chat-content-rightside .chat-right-msg {
    width: fit-content;
    background-color: #dcedff;
    padding: 0.80rem;
    border-radius: 12px;
    float: right;
    max-width: 480px;
    text-align: left;
    border-bottom-right-radius: 0;
}

.chat-toggle-btn {
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin-right: 15px;
    text-align: center;
    font-size: 24px;
    color: #6c757d;
    border-radius: 50%;
    cursor: pointer;
    background-color: white;
    border: 1px solid rgb(0 0 0 / 15%);
}

/*Email box*/

.email-wrapper {
    width: auto;
    height: 600px;
    overflow: hidden;
    border-radius: 0.25rem;
    position: relative;
    background: #ffffff;
    box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, .10);
}

.email-sidebar {
    width: 250px;
    height: 100%;
    position: absolute;
    background: #fff;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    overflow: hidden;
    border-right: 1px solid rgba(0, 0, 0, .125);
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.email-sidebar-header {
    width: auto;
    height: auto;
    position: relative;
    background: #ffffff;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    border-right: 0px solid rgba(0, 0, 0, .125);
    border-top-left-radius: 0.25rem;
    padding: 15px;
}

.email-navigation {
    position: relative;
    padding: 0px;
    height: 345px;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.email-header {
    position: absolute;
    height: 70px;
    left: 250px;
    right: 0;
    top: 0;
    padding: 15px;
    background: #ffffff;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    border-top-right-radius: 0.25rem;
    z-index: 1;
}

.email-content {
    position: absolute;
    left: 0;
    right: 0;
    width: auto;
    top: 70px;
    height: auto;
    margin-left: 250px;
    padding: 0;
    background: #ffffff;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.email-navigation a.list-group-item {
    color: #404142;
    padding: .35rem 1.25rem;
    background-color: white;
    border-bottom: 1px solid rgb(0 0 0 / 0%);
    transition: all .3s ease-out;
}

.email-navigation a.list-group-item:hover {
    background-color: rgb(103 58 183 / 12%);
}

.email-navigation a.list-group-item.active {
    color: #673ab7;
    font-weight: 600;
    background-color: rgb(103 58 183 / 12%);
}

.email-meeting {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

.email-meeting a.list-group-item {
    color: #404142;
    padding: .35rem 1.25rem;
    background-color: white;
    border-bottom: 1px solid rgb(0 0 0 / 0%);
}

.email-meeting a.list-group-item:hover {
    background-color: rgb(0 123 255 / 15%);
    transition: all .3s ease-out;
}

.email-hangout .chat-user-online:before {
    content: '';
    position: absolute;
    bottom: 7px;
    left: 37px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #fff;
    background: #16e15e;
}

.email-toggle-btn {
    width: auto;
    height: auto;
    margin-right: 10px;
    text-align: center;
    font-size: 24px;
    color: #404142;
    border-radius: 0;
    cursor: pointer;
    background-color: white;
    border: 0px solid rgb(0 0 0 / 15%);
}

.email-actions {
    width: 230px;
    ;
}

.email-time {
    font-size: 13px;
    color: #6c757d;
}

.email-list div.email-message {
    background: #ffffff;
    border-bottom: 1px solid rgb(0 0 0 / 8%);
    color: #383a3c;
}

.email-list div.email-message:hover {
    transition: all .2s ease-out;
    background-color: #eceef1;
}

.email-list {
    position: relative;
    height: 530px;
}

.email-star {
    color: #6c757d;
}

.email-read-box {
    position: relative;
    height: 530px;
}

/*Compose Mail*/

.compose-mail-popup {
    width: 42%;
    position: fixed;
    bottom: -30px;
    right: 30px;
    z-index: 15;
    display: none;
}

.compose-mail-toggled {
    display: block;
}

.compose-mail-title {
    font-size: 16px;
}

.compose-mail-close {
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    font-size: 14px;
    border-radius: 2px;
    background-color: rgb(255 255 255 / 0%);
}

.compose-mail-close:hover {
    background-color: rgb(255 255 255 / 20%);
}

/* Navbars */

.nav-search input.form-control {
    background-color: rgb(255 255 255 / 20%);
    border: 1px solid rgb(255 255 255 / 45%);
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.nav-search input.form-control::placeholder {
    opacity: 0.5 !important;
    color: #fff !important;
}

.nav-search input.form-control::-ms-input-placeholder {
    color: #fff !important;
}

.nav-search button[type='submit'] {
    background-color: rgb(255 255 255 / 20%);
    border: 1px solid rgb(255 255 255 / 32%);
    color: #fff;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}

.nav-width {
    width: 340px !important;
}

.round-pagination.pagination .page-item:first-child .page-link {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.round-pagination.pagination .page-item:last-child .page-link {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}

/*Extra Css*/

.login-separater span {
    position: relative;
    top: 26px;
    margin-top: -10px;
    background: #ffffff;
    padding: 5px;
    font-size: 12px;
    color: #cbcbcb;
    z-index: 1;
}

.login-img {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.contacts-social a {
    font-size: 16px;
    width: 26px;
    height: 26px;
    line-height: 26px;
    background: #f7f5f5;
    text-align: center;
    border-radius: 0.25rem;
}

.iconFilterTypes {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.iconFilterTypes .tab.active,
.iconFilterTypes .tab:hover {
    font-weight: 700;
    color: #756af8;
}

.iconFilterTypes .tab {
    color: #000;
    font-weight: 700;
    display: inline-block;
    cursor: pointer;
}

.list {
    position: relative;
}

ul.icons {
    list-style: none;
}

ul.icons li {
    position: relative;
}

ul.icons li {
    cursor: pointer;
    padding: 10px;
    width: 204px;
    float: left;
    box-shadow: 0 1px 5px #e3e7eb;
    margin: 13px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background: #f8f9fb;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-transition: all .1s linear;
    -moz-transition: all .1s linear;
    -o-transition: all .1s linear;
    transition: all .1s linear;
}

ul.icons li i {
    color: #673ab7;
    width: 30px;
    height: 30px;
    font-size: 30px;
    text-align: center;
    display: block;
    float: left;
    line-height: 34px;
    margin-left: 5px;
}

ul.icons li:hover {
    background: #eef1f7;
}

ul.icons li span {
    text-indent: 15px;
    display: block;
    line-height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pByfF {
    animation-fill-mode: forwards;
    cursor: pointer;
    display: inline-block;
    flex: 0 0 10%;
    transition: all 0.15s ease 0s;
    border-radius: 8px;
    overflow: hidden;
    outline: none !important;
    background: #f8f9fb;
    box-shadow: 0 1px 5px #e3e7eb;
    width: 122px;
    height: 122px;
    margin: 7px 7px;
    padding: 10px 14px;
}

.pByfF.active .icon-box-inner {
    background: rgb(238, 238, 238);
}

.pByfF .icon-box-inner {
    font-weight: 700;
    position: relative;
    font-size: 16px;
    line-height: 35px;
    text-align: center;
    pointer-events: none;
    margin: 1px;
    border-radius: 8px;
    background: transparent;
    padding: 18px 10px;
}

.pByfF i {
    color: #673ab7;
    font-size: 36px;
}

.pByfF .icon-box-inner .icon-box-name {
    font-size: 12px;
    text-transform: capitalize;
    user-select: none;
    color: #a5a5a5;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 15px;
    overflow: hidden;
    margin: 5px auto 2px;
}

.pByfF:hover {
    background: #eaecf1;
}

.media-icons {
    font-size: 54px;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
}

.dashboard-icons {
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ededed;
    font-size: 42px;
    border-radius: 10px;
}

.widgets-icons {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ededed;
    font-size: 26px;
    border-radius: 10px;
}

.widgets-social {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ededed;
    font-size: 22px;
    border-radius: 10px;
}

.icon-box {
    padding: 10px 14px;
    background: #ffffff;
    box-shadow: 0 1px 5px #e3e7eb;
}

.icon-symbol i {
    font-size: 30px;
    color: #673ab7;
}

.icon-name {}

.p-10 {
    padding: 10px;
}

.p-15 {
    padding: 15px;
}

.font-small {
    font-size: 50%;
}

.font-13 {
    font-size: 13px;
}

.font-14 {
    font-size: 14px;
}

.font-20 {
    font-size: 20px;
}

.font-22 {
    font-size: 22px;
}

.font-24 {
    font-size: 24px;
}

.font-30 {
    font-size: 30px;
}

.font-35 {
    font-size: 35px;
}

.font-40 {
    font-size: 40px;
}

.font-60 {
    font-size: 60px;
}

.radius-10 {
    border-radius: 10px;
}

.radius-15 {
    border-radius: 15px;
}

.radius-30 {
    border-radius: 30px;
}

.row.row-group>div {
    border-right: 1px solid rgb(0 0 0 / 12%);
}

.row.row-group>div:last-child {
    border-right: none;
}

.accordion .card-header:after {
    font-family: 'LineIcons';
    content: "\eb2c";
    float: right;
    color: #673ab7;
}

.accordion .card-header.collapsed:after {
    /* symbol for "collapsed" panels */
    content: "\eb53";
}

.color-acordians .accordion .card-header:after {
    color: #fff;
}

.cursor-pointer {
    cursor: pointer;
}

.chart-container1 {
    position: relative;
    height: 340px;
}

.chart-container2 {
    position: relative;
    height: 400px;
}

.chart-container3 {
    position: relative;
    width: 200px;
    height: 80px;
    right: -1.25rem;
    top: -1.0rem;
}

.gmaps,
.gmaps-panaroma {
    height: 400px;
    background: #eeeeee;
    border-radius: 3px;
}

#location-map {
    width: 100%;
    height: 260px;
}

#geographic-map {
    width: 100%;
    height: 340px;
}

.product-img {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f3f3f3;
    border-radius: 10px;
}

.page-breadcrumb .breadcrumb {
    background-color: transparent;
}

.breadcrumb-title {
    font-size: 20px;
    border-right: 1.5px solid #aaa4a4;
}

.page-breadcrumb .breadcrumb li a {}

.page-breadcrumb .breadcrumb li.breadcrumb-item {
    font-size: 16px;
}

.page-breadcrumb .breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    color: #6c757d;
    font-family: 'LineIcons';
    content: "\ea5c";
}

.vertical-align-middle {
    vertical-align: middle;
}

.back-to-top {
    display: none;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 26px;
    color: white;
    position: fixed;
    border-radius: 10px;
    bottom: 20px;
    right: 12px;
    background-color: #673ab7;
    z-index: 10000;
}

.back-to-top:hover {
    color: white;
    background-color: #000;
    transition: all .5s;
}

.vertical-separater {
    width: 1px;
    background: #dadada;
    height: 50px;
    margin: auto;
}

/*Switcher*/

.btn-switcher {
    position: fixed;
    right: 0px;
    top: 40%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.offcanvas-end {
    width: 300px;
}

/*Responsive*/

@media screen and (max-width: 1280px) {
    .left-topbar {
        width: auto;
    }

    .search-bar {
        padding: 0rem 1.4rem;
    }

    .email-header {
        height: auto;
    }

    .email-content {
        padding: 100px 0px 0px 0px;
    }
}

@media screen and (max-width: 1024px) {
    .top-header {
        left: 0px;
    }

    .search-arrow-back {
        display: inline-block;
        margin-right: 0px;
    }

    .search-btn-mobile {
        display: flex;
    }

    .search-bar {
        display: none;
    }

    .full-search-bar {
        display: flex;
        align-items: center;
        width: 100%;
        position: absolute;
        left: 0;
        z-index: 100;
        background: #fff;
        height: 70px;
        padding: 0rem 1.4rem;
    }

    .sidebar-wrapper {
        width: 260px !important;
        left: -260px;
        box-shadow: none;
        z-index: 12;
    }

    .page-content-wrapper {
        margin-left: 0px;
    }

    .wrapper.toggled .sidebar-wrapper {
        left: 0px;
        box-shadow: 0 .3rem 0.6rem rgba(0, 0, 0, .13);
        transition: all .2s ease-out;
        z-index: 16;
    }

    .wrapper.toggled .page-content-wrapper {
        margin-left: 0px;
    }

    .wrapper.toggled .overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #000;
        opacity: 0.6;
        z-index: 15;
        display: block;
        cursor: move;
        transition: all .2s ease-out;
    }

    .footer {
        margin-left: 0px;
    }

    .wrapper.toggled .footer {
        margin-left: 0px;
    }

    .chat-header {
        border-top-left-radius: 0.25rem;
    }

    .chat-footer {
        border-bottom-left-radius: 0.25rem;
    }

    .chat-sidebar {
        left: -370px;
    }

    .chat-content {
        margin-left: 0px;
    }

    .chat-header {
        left: 0px;
    }

    .chat-footer {
        left: 0px;
    }

    /* chat toggled css */
    .chat-toggled .chat-sidebar {
        left: 0px;
    }

    .chat-toggled .overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 340px;
        background: #000;
        opacity: 0.5;
        z-index: 11;
        display: block;
        cursor: move;
        transition: all .3s ease-out;
    }

    .email-header {
        border-top-left-radius: 0.25rem;
    }

    .email-sidebar {
        left: -280px;
    }

    .email-content {
        margin-left: 0px;
    }

    .email-header {
        left: 0px;
    }

    /* email toggled */
    .email-toggled .email-sidebar {
        left: 0px;
    }

    .email-toggled .overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 250px;
        background: #000;
        opacity: 0.5;
        z-index: 9;
        display: block;
        cursor: move;
        transition: all .3s ease-out;
    }
}

@media only screen and (max-width: 1199px) {
    .row.row-group>div {
        border-right: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
}

@media screen and (max-width: 991px) {
    .section-authentication-login {
        height: 100%;
        padding: 1.4rem;
    }

    .login-img {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
    }

    .login-card {
        border-right: 0px solid #dee2e6 !important;
        border-bottom: 1px solid #dee2e6 !important;
    }

    .authentication-reset-password {
        height: auto;
        padding: 2.0rem 1rem;
    }

    .authentication-lock-screen {
        height: auto;
        padding: 2.0rem 1rem;
    }

    .error-404 {
        height: auto;
        padding: 6.0rem 1rem;
    }

    .compose-mail-popup {
        width: auto;
        position: fixed;
        bottom: -30px;
        right: 0;
        left: 0;
    }
}

@media screen and (max-width: 767px) {
    .user-info {
        display: none;
    }

    .lang span {
        display: none;
    }

    .right-topbar .navbar-nav .nav-link {
        padding: 0rem 14px;
        border-left: 0px solid #ededed;
        font-size: 25px;
    }

    .msg-count {
        left: 26px;
    }

    .search-bar .btn-search {
        padding: 0 20px;
    }

    .authentication-forgot {
        height: auto;
        padding: 2.5rem 1rem;
    }

    .fc .fc-toolbar {
        display: flow-root;
    }
}

@media screen and (max-width: 620px) {
    .top-header .navbar .dropdown-menu::after {
        display: none;
    }

    .top-header .navbar .dropdown {
        position: static !important;
    }

    .top-header .navbar .dropdown-menu {
        width: 100% !important;
    }

    .dropdown-lg-content {
        max-height: 350px;
        overflow-y: scroll;
    }
}

@media screen and (max-width: 520px) {
    .logo-icon {
        display: none;
    }

    .chat-footer-menu,
    .chat-top-header-menu {
        display: none;
    }
}

/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

:root {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #0d6efd;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-primary-rgb: 13, 110, 253;
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success-rgb: 25, 135, 84;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 33, 37, 41;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-bg: #fff
}

*,
::after,
::before {
    box-sizing: border-box
}

@media (prefers-reduced-motion:no-preference) {
    :root {
        scroll-behavior: smooth
    }
}

body {
    margin: 0;
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent
}

hr {
    margin: 1rem 0;
    color: inherit;
    background-color: currentColor;
    border: 0;
    opacity: .25
}

hr:not([size]) {
    height: 1px
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2
}

.h1,
h1 {
    font-size: calc(1.375rem + 1.5vw)
}

@media (min-width:1200px) {

    .h1,
    h1 {
        font-size: 2.5rem
    }
}

.h2,
h2 {
    font-size: calc(1.325rem + .9vw)
}

@media (min-width:1200px) {

    .h2,
    h2 {
        font-size: 2rem
    }
}

.h3,
h3 {
    font-size: calc(1.3rem + .6vw)
}

@media (min-width:1200px) {

    .h3,
    h3 {
        font-size: 1.75rem
    }
}

.h4,
h4 {
    font-size: calc(1.275rem + .3vw)
}

@media (min-width:1200px) {

    .h4,
    h4 {
        font-size: 1.5rem
    }
}

.h5,
h5 {
    font-size: 1.25rem
}

.h6,
h6 {
    font-size: 1rem
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

abbr[data-bs-original-title],
abbr[title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
}

ol,
ul {
    padding-left: 2rem
}

dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 1rem
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 700
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

b,
strong {
    font-weight: bolder
}

.small,
small {
    font-size: .875em
}

.mark,
mark {
    padding: .2em;
    background-color: #fcf8e3
}

sub,
sup {
    position: relative;
    font-size: .75em;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    color: #0d6efd;
    text-decoration: underline
}

a:hover {
    color: #0a58ca
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none
}

code,
kbd,
pre,
samp {
    font-family: var(--bs-font-monospace);
    font-size: 1em;
    direction: ltr;
    unicode-bidi: bidi-override
}

pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: .875em
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal
}

code {
    font-size: .875em;
    color: #d63384;
    word-wrap: break-word
}

a>code {
    color: inherit
}

kbd {
    padding: .2rem .4rem;
    font-size: .875em;
    color: #fff;
    background-color: #212529;
    border-radius: .2rem
}

kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700
}

figure {
    margin: 0 0 1rem
}

img,
svg {
    vertical-align: middle
}

table {
    caption-side: bottom;
    border-collapse: collapse
}

caption {
    padding-top: .5rem;
    padding-bottom: .5rem;
    color: #6c757d;
    text-align: left
}

th {
    text-align: inherit;
    text-align: -webkit-match-parent
}

tbody,
td,
tfoot,
th,
thead,
tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0
}

label {
    display: inline-block
}

button {
    border-radius: 0
}

button:focus:not(:focus-visible) {
    outline: 0
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,
select {
    text-transform: none
}

[role=button] {
    cursor: pointer
}

select {
    word-wrap: normal
}

select:disabled {
    opacity: 1
}

[list]::-webkit-calendar-picker-indicator {
    display: none
}

[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: button
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
    cursor: pointer
}

::-moz-focus-inner {
    padding: 0;
    border-style: none
}

textarea {
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: calc(1.275rem + .3vw);
    line-height: inherit
}

@media (min-width:1200px) {
    legend {
        font-size: 1.5rem
    }
}

legend+* {
    clear: left
}

::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
    padding: 0
}

::-webkit-inner-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: textfield
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-color-swatch-wrapper {
    padding: 0
}

::-webkit-file-upload-button {
    font: inherit
}

::file-selector-button {
    font: inherit
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

iframe {
    border: 0
}

summary {
    display: list-item;
    cursor: pointer
}

progress {
    vertical-align: baseline
}

[hidden] {
    display: none !important
}

.lead {
    font-size: 1.25rem;
    font-weight: 300
}

.display-1 {
    font-size: calc(1.625rem + 4.5vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-1 {
        font-size: 5rem
    }
}

.display-2 {
    font-size: calc(1.575rem + 3.9vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-2 {
        font-size: 4.5rem
    }
}

.display-3 {
    font-size: calc(1.525rem + 3.3vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-3 {
        font-size: 4rem
    }
}

.display-4 {
    font-size: calc(1.475rem + 2.7vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-4 {
        font-size: 3.5rem
    }
}

.display-5 {
    font-size: calc(1.425rem + 2.1vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-5 {
        font-size: 3rem
    }
}

.display-6 {
    font-size: calc(1.375rem + 1.5vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-6 {
        font-size: 2.5rem
    }
}

.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline {
    padding-left: 0;
    list-style: none
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: .5rem
}

.initialism {
    font-size: .875em;
    text-transform: uppercase
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem
}

.blockquote>:last-child {
    margin-bottom: 0
}

.blockquote-footer {
    margin-top: -1rem;
    margin-bottom: 1rem;
    font-size: .875em;
    color: #6c757d
}

.blockquote-footer::before {
    content: "— "
}

.img-fluid {
    max-width: 100%;
    height: auto
}

.img-thumbnail {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    max-width: 100%;
    height: auto
}

.figure {
    display: inline-block
}

.figure-img {
    margin-bottom: .5rem;
    line-height: 1
}

.figure-caption {
    font-size: .875em;
    color: #6c757d
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    width: 100%;
    padding-right: var(--bs-gutter-x, .75rem);
    padding-left: var(--bs-gutter-x, .75rem);
    margin-right: auto;
    margin-left: auto
}

@media (min-width:576px) {

    .container,
    .container-sm {
        max-width: 540px
    }
}

@media (min-width:768px) {

    .container,
    .container-md,
    .container-sm {
        max-width: 720px
    }
}

@media (min-width:992px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 960px
    }
}

@media (min-width:1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1140px
    }
}

@media (min-width:1400px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1320px
    }
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x))
}

.row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y)
}

.col {
    flex: 1 0 0%
}

.row-cols-auto>* {
    flex: 0 0 auto;
    width: auto
}

.row-cols-1>* {
    flex: 0 0 auto;
    width: 100%
}

.row-cols-2>* {
    flex: 0 0 auto;
    width: 50%
}

.row-cols-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%
}

.row-cols-4>* {
    flex: 0 0 auto;
    width: 25%
}

.row-cols-5>* {
    flex: 0 0 auto;
    width: 20%
}

.row-cols-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%
}

.col-auto {
    flex: 0 0 auto;
    width: auto
}

.col-1 {
    flex: 0 0 auto;
    width: 8.33333333%
}

.col-2 {
    flex: 0 0 auto;
    width: 16.66666667%
}

.col-3 {
    flex: 0 0 auto;
    width: 25%
}

.col-4 {
    flex: 0 0 auto;
    width: 33.33333333%
}

.col-5 {
    flex: 0 0 auto;
    width: 41.66666667%
}

.col-6 {
    flex: 0 0 auto;
    width: 50%
}

.col-7 {
    flex: 0 0 auto;
    width: 58.33333333%
}

.col-8 {
    flex: 0 0 auto;
    width: 66.66666667%
}

.col-9 {
    flex: 0 0 auto;
    width: 75%
}

.col-10 {
    flex: 0 0 auto;
    width: 83.33333333%
}

.col-11 {
    flex: 0 0 auto;
    width: 91.66666667%
}

.col-12 {
    flex: 0 0 auto;
    width: 100%
}

.offset-1 {
    margin-left: 8.33333333%
}

.offset-2 {
    margin-left: 16.66666667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333333%
}

.offset-5 {
    margin-left: 41.66666667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333333%
}

.offset-8 {
    margin-left: 66.66666667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333333%
}

.offset-11 {
    margin-left: 91.66666667%
}

.g-0,
.gx-0 {
    --bs-gutter-x: 0
}

.g-0,
.gy-0 {
    --bs-gutter-y: 0
}

.g-1,
.gx-1 {
    --bs-gutter-x: 0.25rem
}

.g-1,
.gy-1 {
    --bs-gutter-y: 0.25rem
}

.g-2,
.gx-2 {
    --bs-gutter-x: 0.5rem
}

.g-2,
.gy-2 {
    --bs-gutter-y: 0.5rem
}

.g-3,
.gx-3 {
    --bs-gutter-x: 1rem
}

.g-3,
.gy-3 {
    --bs-gutter-y: 1rem
}

.g-4,
.gx-4 {
    --bs-gutter-x: 1.5rem
}

.g-4,
.gy-4 {
    --bs-gutter-y: 1.5rem
}

.g-5,
.gx-5 {
    --bs-gutter-x: 3rem
}

.g-5,
.gy-5 {
    --bs-gutter-y: 3rem
}

@media (min-width:576px) {
    .col-sm {
        flex: 1 0 0%
    }

    .row-cols-sm-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-sm-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-sm-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-sm-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-sm-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-sm-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-sm-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-sm-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-sm-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-sm-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-sm-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-sm-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-sm-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-sm-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-sm-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-sm-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-sm-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-sm-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-sm-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-sm-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.33333333%
    }

    .offset-sm-2 {
        margin-left: 16.66666667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.33333333%
    }

    .offset-sm-5 {
        margin-left: 41.66666667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.33333333%
    }

    .offset-sm-8 {
        margin-left: 66.66666667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.33333333%
    }

    .offset-sm-11 {
        margin-left: 91.66666667%
    }

    .g-sm-0,
    .gx-sm-0 {
        --bs-gutter-x: 0
    }

    .g-sm-0,
    .gy-sm-0 {
        --bs-gutter-y: 0
    }

    .g-sm-1,
    .gx-sm-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-sm-1,
    .gy-sm-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-sm-2,
    .gx-sm-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-sm-2,
    .gy-sm-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-sm-3,
    .gx-sm-3 {
        --bs-gutter-x: 1rem
    }

    .g-sm-3,
    .gy-sm-3 {
        --bs-gutter-y: 1rem
    }

    .g-sm-4,
    .gx-sm-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-sm-4,
    .gy-sm-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-sm-5,
    .gx-sm-5 {
        --bs-gutter-x: 3rem
    }

    .g-sm-5,
    .gy-sm-5 {
        --bs-gutter-y: 3rem
    }
}

@media (min-width:768px) {
    .col-md {
        flex: 1 0 0%
    }

    .row-cols-md-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-md-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-md-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-md-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-md-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-md-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-md-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-md-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-md-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-md-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-md-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-md-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-md-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-md-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-md-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-md-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-md-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-md-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-md-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.33333333%
    }

    .offset-md-2 {
        margin-left: 16.66666667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.33333333%
    }

    .offset-md-5 {
        margin-left: 41.66666667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.33333333%
    }

    .offset-md-8 {
        margin-left: 66.66666667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.33333333%
    }

    .offset-md-11 {
        margin-left: 91.66666667%
    }

    .g-md-0,
    .gx-md-0 {
        --bs-gutter-x: 0
    }

    .g-md-0,
    .gy-md-0 {
        --bs-gutter-y: 0
    }

    .g-md-1,
    .gx-md-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-md-1,
    .gy-md-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-md-2,
    .gx-md-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-md-2,
    .gy-md-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-md-3,
    .gx-md-3 {
        --bs-gutter-x: 1rem
    }

    .g-md-3,
    .gy-md-3 {
        --bs-gutter-y: 1rem
    }

    .g-md-4,
    .gx-md-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-md-4,
    .gy-md-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-md-5,
    .gx-md-5 {
        --bs-gutter-x: 3rem
    }

    .g-md-5,
    .gy-md-5 {
        --bs-gutter-y: 3rem
    }
}

@media (min-width:992px) {
    .col-lg {
        flex: 1 0 0%
    }

    .row-cols-lg-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-lg-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-lg-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-lg-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-lg-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-lg-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-lg-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-lg-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-lg-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-lg-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-lg-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-lg-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-lg-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-lg-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-lg-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-lg-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-lg-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-lg-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-lg-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.33333333%
    }

    .offset-lg-2 {
        margin-left: 16.66666667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.33333333%
    }

    .offset-lg-5 {
        margin-left: 41.66666667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.33333333%
    }

    .offset-lg-8 {
        margin-left: 66.66666667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.33333333%
    }

    .offset-lg-11 {
        margin-left: 91.66666667%
    }

    .g-lg-0,
    .gx-lg-0 {
        --bs-gutter-x: 0
    }

    .g-lg-0,
    .gy-lg-0 {
        --bs-gutter-y: 0
    }

    .g-lg-1,
    .gx-lg-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-lg-1,
    .gy-lg-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-lg-2,
    .gx-lg-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-lg-2,
    .gy-lg-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-lg-3,
    .gx-lg-3 {
        --bs-gutter-x: 1rem
    }

    .g-lg-3,
    .gy-lg-3 {
        --bs-gutter-y: 1rem
    }

    .g-lg-4,
    .gx-lg-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-lg-4,
    .gy-lg-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-lg-5,
    .gx-lg-5 {
        --bs-gutter-x: 3rem
    }

    .g-lg-5,
    .gy-lg-5 {
        --bs-gutter-y: 3rem
    }
}

@media (min-width:1200px) {
    .col-xl {
        flex: 1 0 0%
    }

    .row-cols-xl-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xl-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xl-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xl-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-xl-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xl-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xl-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-xl-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-xl-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-xl-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xl-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-xl-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-xl-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-xl-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-xl-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-xl-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-xl-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-xl-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-xl-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-xl-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.33333333%
    }

    .offset-xl-2 {
        margin-left: 16.66666667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.33333333%
    }

    .offset-xl-5 {
        margin-left: 41.66666667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.33333333%
    }

    .offset-xl-8 {
        margin-left: 66.66666667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.33333333%
    }

    .offset-xl-11 {
        margin-left: 91.66666667%
    }

    .g-xl-0,
    .gx-xl-0 {
        --bs-gutter-x: 0
    }

    .g-xl-0,
    .gy-xl-0 {
        --bs-gutter-y: 0
    }

    .g-xl-1,
    .gx-xl-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-xl-1,
    .gy-xl-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-xl-2,
    .gx-xl-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-xl-2,
    .gy-xl-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-xl-3,
    .gx-xl-3 {
        --bs-gutter-x: 1rem
    }

    .g-xl-3,
    .gy-xl-3 {
        --bs-gutter-y: 1rem
    }

    .g-xl-4,
    .gx-xl-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-xl-4,
    .gy-xl-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-xl-5,
    .gx-xl-5 {
        --bs-gutter-x: 3rem
    }

    .g-xl-5,
    .gy-xl-5 {
        --bs-gutter-y: 3rem
    }
}

@media (min-width:1400px) {
    .col-xxl {
        flex: 1 0 0%
    }

    .row-cols-xxl-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xxl-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xxl-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xxl-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-xxl-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xxl-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xxl-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-xxl-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-xxl-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-xxl-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xxl-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-xxl-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-xxl-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-xxl-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-xxl-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-xxl-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-xxl-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-xxl-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-xxl-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-xxl-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xxl-0 {
        margin-left: 0
    }

    .offset-xxl-1 {
        margin-left: 8.33333333%
    }

    .offset-xxl-2 {
        margin-left: 16.66666667%
    }

    .offset-xxl-3 {
        margin-left: 25%
    }

    .offset-xxl-4 {
        margin-left: 33.33333333%
    }

    .offset-xxl-5 {
        margin-left: 41.66666667%
    }

    .offset-xxl-6 {
        margin-left: 50%
    }

    .offset-xxl-7 {
        margin-left: 58.33333333%
    }

    .offset-xxl-8 {
        margin-left: 66.66666667%
    }

    .offset-xxl-9 {
        margin-left: 75%
    }

    .offset-xxl-10 {
        margin-left: 83.33333333%
    }

    .offset-xxl-11 {
        margin-left: 91.66666667%
    }

    .g-xxl-0,
    .gx-xxl-0 {
        --bs-gutter-x: 0
    }

    .g-xxl-0,
    .gy-xxl-0 {
        --bs-gutter-y: 0
    }

    .g-xxl-1,
    .gx-xxl-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-xxl-1,
    .gy-xxl-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-xxl-2,
    .gx-xxl-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-xxl-2,
    .gy-xxl-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-xxl-3,
    .gx-xxl-3 {
        --bs-gutter-x: 1rem
    }

    .g-xxl-3,
    .gy-xxl-3 {
        --bs-gutter-y: 1rem
    }

    .g-xxl-4,
    .gx-xxl-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-xxl-4,
    .gy-xxl-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-xxl-5,
    .gx-xxl-5 {
        --bs-gutter-x: 3rem
    }

    .g-xxl-5,
    .gy-xxl-5 {
        --bs-gutter-y: 3rem
    }
}

.table {
    --bs-table-bg: transparent;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: #212529;
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: #212529;
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: #212529;
    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    vertical-align: top;
    border-color: #dee2e6
}

.table>:not(caption)>*>* {
    padding: .5rem .5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg)
}

.table>tbody {
    vertical-align: inherit
}

.table>thead {
    vertical-align: bottom
}

.table>:not(:first-child) {
    border-top: 2px solid currentColor
}

.caption-top {
    caption-side: top
}

.table-sm>:not(caption)>*>* {
    padding: .25rem .25rem
}

.table-bordered>:not(caption)>* {
    border-width: 1px 0
}

.table-bordered>:not(caption)>*>* {
    border-width: 0 1px
}

.table-borderless>:not(caption)>*>* {
    border-bottom-width: 0
}

.table-borderless>:not(:first-child) {
    border-top-width: 0
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color)
}

.table-active {
    --bs-table-accent-bg: var(--bs-table-active-bg);
    color: var(--bs-table-active-color)
}

.table-hover>tbody>tr:hover>* {
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    color: var(--bs-table-hover-color)
}

.table-primary {
    --bs-table-bg: #cfe2ff;
    --bs-table-striped-bg: #c5d7f2;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #bacbe6;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #bfd1ec;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #bacbe6
}

.table-secondary {
    --bs-table-bg: #e2e3e5;
    --bs-table-striped-bg: #d7d8da;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #cbccce;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #d1d2d4;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #cbccce
}

.table-success {
    --bs-table-bg: #d1e7dd;
    --bs-table-striped-bg: #c7dbd2;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #bcd0c7;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #c1d6cc;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #bcd0c7
}

.table-info {
    --bs-table-bg: #cff4fc;
    --bs-table-striped-bg: #c5e8ef;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #badce3;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #bfe2e9;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #badce3
}

.table-warning {
    --bs-table-bg: #fff3cd;
    --bs-table-striped-bg: #f2e7c3;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #e6dbb9;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #ece1be;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #e6dbb9
}

.table-danger {
    --bs-table-bg: #f8d7da;
    --bs-table-striped-bg: #eccccf;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #dfc2c4;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e5c7ca;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #dfc2c4
}

.table-light {
    --bs-table-bg: #f8f9fa;
    --bs-table-striped-bg: #ecedee;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #dfe0e1;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e5e6e7;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #dfe0e1
}

.table-dark {
    --bs-table-bg: #212529;
    --bs-table-striped-bg: #2c3034;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #373b3e;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #323539;
    --bs-table-hover-color: #fff;
    color: #fff;
    border-color: #373b3e
}

.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}

@media (max-width:575.98px) {
    .table-responsive-sm {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width:767.98px) {
    .table-responsive-md {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width:991.98px) {
    .table-responsive-lg {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width:1199.98px) {
    .table-responsive-xl {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width:1399.98px) {
    .table-responsive-xxl {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

.form-label {
    margin-bottom: .5rem
}

.col-form-label {
    padding-top: calc(.375rem + 1px);
    padding-bottom: calc(.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5
}

.col-form-label-lg {
    padding-top: calc(.5rem + 1px);
    padding-bottom: calc(.5rem + 1px);
    font-size: 1.25rem
}

.col-form-label-sm {
    padding-top: calc(.25rem + 1px);
    padding-bottom: calc(.25rem + 1px);
    font-size: .875rem
}

.form-text {
    margin-top: .25rem;
    font-size: .875em;
    color: #6c757d
}

.form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-control {
        transition: none
    }
}

.form-control[type=file] {
    overflow: hidden
}

.form-control[type=file]:not(:disabled):not([readonly]) {
    cursor: pointer
}

.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #7f4dd6;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(104, 58, 183, 0.25)
}

.form-control::-webkit-date-and-time-value {
    height: 1.5em
}

.form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1
}

.form-control::placeholder {
    color: #6c757d;
    opacity: 1
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1
}

.form-control::-webkit-file-upload-button {
    padding: .375rem .75rem;
    margin: -.375rem -.75rem;
    -webkit-margin-end: .75rem;
    margin-inline-end: .75rem;
    color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.form-control::file-selector-button {
    padding: .375rem .75rem;
    margin: -.375rem -.75rem;
    -webkit-margin-end: .75rem;
    margin-inline-end: .75rem;
    color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-control::-webkit-file-upload-button {
        -webkit-transition: none;
        transition: none
    }

    .form-control::file-selector-button {
        transition: none
    }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #dde0e3
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3
}

.form-control::-webkit-file-upload-button {
    padding: .375rem .75rem;
    margin: -.375rem -.75rem;
    -webkit-margin-end: .75rem;
    margin-inline-end: .75rem;
    color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-control::-webkit-file-upload-button {
        -webkit-transition: none;
        transition: none
    }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #dde0e3
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: .375rem 0;
    margin-bottom: 0;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0
}

.form-control-sm {
    min-height: calc(1.5em + .5rem + 2px);
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: .2rem
}

.form-control-sm::-webkit-file-upload-button {
    padding: .25rem .5rem;
    margin: -.25rem -.5rem;
    -webkit-margin-end: .5rem;
    margin-inline-end: .5rem
}

.form-control-sm::file-selector-button {
    padding: .25rem .5rem;
    margin: -.25rem -.5rem;
    -webkit-margin-end: .5rem;
    margin-inline-end: .5rem
}

.form-control-sm::-webkit-file-upload-button {
    padding: .25rem .5rem;
    margin: -.25rem -.5rem;
    -webkit-margin-end: .5rem;
    margin-inline-end: .5rem
}

.form-control-lg {
    min-height: calc(1.5em + 1rem + 2px);
    padding: .5rem 1rem;
    font-size: 1.25rem;
    border-radius: .3rem
}

.form-control-lg::-webkit-file-upload-button {
    padding: .5rem 1rem;
    margin: -.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem
}

.form-control-lg::file-selector-button {
    padding: .5rem 1rem;
    margin: -.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem
}

.form-control-lg::-webkit-file-upload-button {
    padding: .5rem 1rem;
    margin: -.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem
}

textarea.form-control {
    min-height: calc(1.5em + .75rem + 2px)
}

textarea.form-control-sm {
    min-height: calc(1.5em + .5rem + 2px)
}

textarea.form-control-lg {
    min-height: calc(1.5em + 1rem + 2px)
}

.form-control-color {
    width: 3rem;
    height: auto;
    padding: .375rem
}

.form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer
}

.form-control-color::-moz-color-swatch {
    height: 1.5em;
    border-radius: .25rem
}

.form-control-color::-webkit-color-swatch {
    height: 1.5em;
    border-radius: .25rem
}

.form-select {
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .form-select {
        transition: none
    }
}

.form-select:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
    padding-right: .75rem;
    background-image: none
}

.form-select:disabled {
    background-color: #e9ecef
}

.form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #212529
}

.form-select-sm {
    padding-top: .25rem;
    padding-bottom: .25rem;
    padding-left: .5rem;
    font-size: .875rem;
    border-radius: .2rem
}

.form-select-lg {
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    font-size: 1.25rem;
    border-radius: .3rem
}

.form-check {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5em;
    margin-bottom: .125rem
}

.form-check .form-check-input {
    float: left;
    margin-left: -1.5em
}

.form-check-input {
    width: 1em;
    height: 1em;
    margin-top: .25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, .25);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact
}

.form-check-input[type=checkbox] {
    border-radius: .25em
}

.form-check-input[type=radio] {
    border-radius: 50%
}

.form-check-input:active {
    filter: brightness(90%)
}

.form-check-input:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.form-check-input:checked {
    background-color: #0d6efd;
    border-color: #0d6efd
}

.form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
}

.form-check-input:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e")
}

.form-check-input[type=checkbox]:indeterminate {
    background-color: #0d6efd;
    border-color: #0d6efd;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e")
}

.form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: .5
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    opacity: .5
}

.form-switch {
    padding-left: 2.5em
}

.form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-switch .form-check-input {
        transition: none
    }
}

.form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e")
}

.form-switch .form-check-input:checked {
    background-position: right center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
}

.form-check-inline {
    display: inline-block;
    margin-right: 1rem
}

.btn-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
}

.btn-check:disabled+.btn,
.btn-check[disabled]+.btn {
    pointer-events: none;
    filter: none;
    opacity: .65
}

.form-range {
    width: 100%;
    height: 1.5rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.form-range:focus {
    outline: 0
}

.form-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.form-range::-moz-focus-outer {
    border: 0
}

.form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -.25rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .form-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none
    }
}

.form-range::-webkit-slider-thumb:active {
    background-color: #b6d4fe
}

.form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem
}

.form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .form-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none
    }
}

.form-range::-moz-range-thumb:active {
    background-color: #b6d4fe
}

.form-range::-moz-range-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem
}

.form-range:disabled {
    pointer-events: none
}

.form-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd
}

.form-range:disabled::-moz-range-thumb {
    background-color: #adb5bd
}

.form-floating {
    position: relative
}

.form-floating>.form-control,
.form-floating>.form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25
}

.form-floating>label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem .75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-floating>label {
        transition: none
    }
}

.form-floating>.form-control {
    padding: 1rem .75rem
}

.form-floating>.form-control::-moz-placeholder {
    color: transparent
}

.form-floating>.form-control::placeholder {
    color: transparent
}

.form-floating>.form-control:not(:-moz-placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control:-webkit-autofill {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-select {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label {
    opacity: .65;
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
    opacity: .65;
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.form-control:-webkit-autofill~label {
    opacity: .65;
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%
}

.input-group>.form-control,
.input-group>.form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0
}

.input-group>.form-control:focus,
.input-group>.form-select:focus {
    z-index: 3
}

.input-group .btn {
    position: relative;
    z-index: 2
}

.input-group .btn:focus {
    z-index: 3
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: .25rem
}

.input-group-lg>.btn,
.input-group-lg>.form-control,
.input-group-lg>.form-select,
.input-group-lg>.input-group-text {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    border-radius: .3rem
}

.input-group-sm>.btn,
.input-group-sm>.form-control,
.input-group-sm>.form-select,
.input-group-sm>.input-group-text {
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: .2rem
}

.input-group-lg>.form-select,
.input-group-sm>.form-select {
    padding-right: 3rem
}

.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group.has-validation>.dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation>:nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: #198754
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    color: #fff;
    background-color: rgba(25, 135, 84, .9);
    border-radius: .25rem
}

.is-valid~.valid-feedback,
.is-valid~.valid-tooltip,
.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip {
    display: block
}

.form-control.is-valid,
.was-validated .form-control:valid {
    border-color: #198754;
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem)
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
    border-color: #198754;
    box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .25)
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
    padding-right: calc(1.5em + .75rem);
    background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem)
}

.form-select.is-valid,
.was-validated .form-select:valid {
    border-color: #198754
}

.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"],
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right .75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem)
}

.form-select.is-valid:focus,
.was-validated .form-select:valid:focus {
    border-color: #198754;
    box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .25)
}

.form-check-input.is-valid,
.was-validated .form-check-input:valid {
    border-color: #198754
}

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
    background-color: #198754
}

.form-check-input.is-valid:focus,
.was-validated .form-check-input:valid:focus {
    box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .25)
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
    color: #198754
}

.form-check-inline .form-check-input~.valid-feedback {
    margin-left: .5em
}

.input-group .form-control.is-valid,
.input-group .form-select.is-valid,
.was-validated .input-group .form-control:valid,
.was-validated .input-group .form-select:valid {
    z-index: 1
}

.input-group .form-control.is-valid:focus,
.input-group .form-select.is-valid:focus,
.was-validated .input-group .form-control:valid:focus,
.was-validated .input-group .form-select:valid:focus {
    z-index: 3
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: #dc3545
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    color: #fff;
    background-color: rgba(220, 53, 69, .9);
    border-radius: .25rem
}

.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip,
.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip {
    display: block
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem)
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25)
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
    padding-right: calc(1.5em + .75rem);
    background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem)
}

.form-select.is-invalid,
.was-validated .form-select:invalid {
    border-color: #dc3545
}

.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-position: right .75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem)
}

.form-select.is-invalid:focus,
.was-validated .form-select:invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25)
}

.form-check-input.is-invalid,
.was-validated .form-check-input:invalid {
    border-color: #dc3545
}

.form-check-input.is-invalid:checked,
.was-validated .form-check-input:invalid:checked {
    background-color: #dc3545
}

.form-check-input.is-invalid:focus,
.was-validated .form-check-input:invalid:focus {
    box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25)
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
    color: #dc3545
}

.form-check-inline .form-check-input~.invalid-feedback {
    margin-left: .5em
}

.input-group .form-control.is-invalid,
.input-group .form-select.is-invalid,
.was-validated .input-group .form-control:invalid,
.was-validated .input-group .form-select:invalid {
    z-index: 2
}

.input-group .form-control.is-invalid:focus,
.input-group .form-select.is-invalid:focus,
.was-validated .input-group .form-control:invalid:focus,
.was-validated .input-group .form-select:invalid:focus {
    z-index: 3
}

.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .btn {
        transition: none
    }
}

.btn:hover {
    color: #212529
}

.btn-check:focus+.btn,
.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    pointer-events: none;
    opacity: .65
}

.btn-primary {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd
}

.btn-primary:hover {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca;
    box-shadow: 0 0 0 .25rem rgba(49, 132, 253, .5)
}

.btn-check:active+.btn-primary,
.btn-check:checked+.btn-primary,
.btn-primary.active,
.btn-primary:active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0a58ca;
    border-color: #0a53be
}

.btn-check:active+.btn-primary:focus,
.btn-check:checked+.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(49, 132, 253, .5)
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd
}

.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-secondary:hover {
    color: #fff;
    background-color: #5c636a;
    border-color: #565e64
}

.btn-check:focus+.btn-secondary,
.btn-secondary:focus {
    color: #fff;
    background-color: #5c636a;
    border-color: #565e64;
    box-shadow: 0 0 0 .25rem rgba(130, 138, 145, .5)
}

.btn-check:active+.btn-secondary,
.btn-check:checked+.btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #565e64;
    border-color: #51585e
}

.btn-check:active+.btn-secondary:focus,
.btn-check:checked+.btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(130, 138, 145, .5)
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-success {
    color: #fff;
    background-color: #198754;
    border-color: #198754
}

.btn-success:hover {
    color: #fff;
    background-color: #157347;
    border-color: #146c43
}

.btn-check:focus+.btn-success,
.btn-success:focus {
    color: #fff;
    background-color: #157347;
    border-color: #146c43;
    box-shadow: 0 0 0 .25rem rgba(60, 153, 110, .5)
}

.btn-check:active+.btn-success,
.btn-check:checked+.btn-success,
.btn-success.active,
.btn-success:active,
.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #146c43;
    border-color: #13653f
}

.btn-check:active+.btn-success:focus,
.btn-check:checked+.btn-success:focus,
.btn-success.active:focus,
.btn-success:active:focus,
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(60, 153, 110, .5)
}

.btn-success.disabled,
.btn-success:disabled {
    color: #fff;
    background-color: #198754;
    border-color: #198754
}

.btn-info {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0
}

.btn-info:hover {
    color: #000;
    background-color: #31d2f2;
    border-color: #25cff2
}

.btn-check:focus+.btn-info,
.btn-info:focus {
    color: #000;
    background-color: #31d2f2;
    border-color: #25cff2;
    box-shadow: 0 0 0 .25rem rgba(11, 172, 204, .5)
}

.btn-check:active+.btn-info,
.btn-check:checked+.btn-info,
.btn-info.active,
.btn-info:active,
.show>.btn-info.dropdown-toggle {
    color: #000;
    background-color: #3dd5f3;
    border-color: #25cff2
}

.btn-check:active+.btn-info:focus,
.btn-check:checked+.btn-info:focus,
.btn-info.active:focus,
.btn-info:active:focus,
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(11, 172, 204, .5)
}

.btn-info.disabled,
.btn-info:disabled {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0
}

.btn-warning {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-warning:hover {
    color: #000;
    background-color: #ffca2c;
    border-color: #ffc720
}

.btn-check:focus+.btn-warning,
.btn-warning:focus {
    color: #000;
    background-color: #ffca2c;
    border-color: #ffc720;
    box-shadow: 0 0 0 .25rem rgba(217, 164, 6, .5)
}

.btn-check:active+.btn-warning,
.btn-check:checked+.btn-warning,
.btn-warning.active,
.btn-warning:active,
.show>.btn-warning.dropdown-toggle {
    color: #000;
    background-color: #ffcd39;
    border-color: #ffc720
}

.btn-check:active+.btn-warning:focus,
.btn-check:checked+.btn-warning:focus,
.btn-warning.active:focus,
.btn-warning:active:focus,
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(217, 164, 6, .5)
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-danger:hover {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37
}

.btn-check:focus+.btn-danger,
.btn-danger:focus {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37;
    box-shadow: 0 0 0 .25rem rgba(225, 83, 97, .5)
}

.btn-check:active+.btn-danger,
.btn-check:checked+.btn-danger,
.btn-danger.active,
.btn-danger:active,
.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #b02a37;
    border-color: #a52834
}

.btn-check:active+.btn-danger:focus,
.btn-check:checked+.btn-danger:focus,
.btn-danger.active:focus,
.btn-danger:active:focus,
.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(225, 83, 97, .5)
}

.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-light {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-light:hover {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb
}

.btn-check:focus+.btn-light,
.btn-light:focus {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb;
    box-shadow: 0 0 0 .25rem rgba(211, 212, 213, .5)
}

.btn-check:active+.btn-light,
.btn-check:checked+.btn-light,
.btn-light.active,
.btn-light:active,
.show>.btn-light.dropdown-toggle {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb
}

.btn-check:active+.btn-light:focus,
.btn-check:checked+.btn-light:focus,
.btn-light.active:focus,
.btn-light:active:focus,
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(211, 212, 213, .5)
}

.btn-light.disabled,
.btn-light:disabled {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-dark {
    color: #fff;
    background-color: #212529;
    border-color: #212529
}

.btn-dark:hover {
    color: #fff;
    background-color: #1c1f23;
    border-color: #1a1e21
}

.btn-check:focus+.btn-dark,
.btn-dark:focus {
    color: #fff;
    background-color: #1c1f23;
    border-color: #1a1e21;
    box-shadow: 0 0 0 .25rem rgba(66, 70, 73, .5)
}

.btn-check:active+.btn-dark,
.btn-check:checked+.btn-dark,
.btn-dark.active,
.btn-dark:active,
.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1a1e21;
    border-color: #191c1f
}

.btn-check:active+.btn-dark:focus,
.btn-check:checked+.btn-dark:focus,
.btn-dark.active:focus,
.btn-dark:active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(66, 70, 73, .5)
}

.btn-dark.disabled,
.btn-dark:disabled {
    color: #fff;
    background-color: #212529;
    border-color: #212529
}

.btn-outline-primary {
    color: #0d6efd;
    border-color: #0d6efd
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd
}

.btn-check:focus+.btn-outline-primary,
.btn-outline-primary:focus {
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .5)
}

.btn-check:active+.btn-outline-primary,
.btn-check:checked+.btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd
}

.btn-check:active+.btn-outline-primary:focus,
.btn-check:checked+.btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus {
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .5)
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #0d6efd;
    background-color: transparent
}

.btn-outline-secondary {
    color: #6c757d;
    border-color: #6c757d
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-check:focus+.btn-outline-secondary,
.btn-outline-secondary:focus {
    box-shadow: 0 0 0 .25rem rgba(108, 117, 125, .5)
}

.btn-check:active+.btn-outline-secondary,
.btn-check:checked+.btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-check:active+.btn-outline-secondary:focus,
.btn-check:checked+.btn-outline-secondary:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus,
.btn-outline-secondary:active:focus {
    box-shadow: 0 0 0 .25rem rgba(108, 117, 125, .5)
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent
}

.btn-outline-success {
    color: #198754;
    border-color: #198754
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #198754;
    border-color: #198754
}

.btn-check:focus+.btn-outline-success,
.btn-outline-success:focus {
    box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .5)
}

.btn-check:active+.btn-outline-success,
.btn-check:checked+.btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active {
    color: #fff;
    background-color: #198754;
    border-color: #198754
}

.btn-check:active+.btn-outline-success:focus,
.btn-check:checked+.btn-outline-success:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus,
.btn-outline-success:active:focus {
    box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .5)
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #198754;
    background-color: transparent
}

.btn-outline-info {
    color: #0dcaf0;
    border-color: #0dcaf0
}

.btn-outline-info:hover {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0
}

.btn-check:focus+.btn-outline-info,
.btn-outline-info:focus {
    box-shadow: 0 0 0 .25rem rgba(13, 202, 240, .5)
}

.btn-check:active+.btn-outline-info,
.btn-check:checked+.btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0
}

.btn-check:active+.btn-outline-info:focus,
.btn-check:checked+.btn-outline-info:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus,
.btn-outline-info:active:focus {
    box-shadow: 0 0 0 .25rem rgba(13, 202, 240, .5)
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #0dcaf0;
    background-color: transparent
}

.btn-outline-warning {
    color: #ffc107;
    border-color: #ffc107
}

.btn-outline-warning:hover {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-check:focus+.btn-outline-warning,
.btn-outline-warning:focus {
    box-shadow: 0 0 0 .25rem rgba(255, 193, 7, .5)
}

.btn-check:active+.btn-outline-warning,
.btn-check:checked+.btn-outline-warning,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show,
.btn-outline-warning:active {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-check:active+.btn-outline-warning:focus,
.btn-check:checked+.btn-outline-warning:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus,
.btn-outline-warning:active:focus {
    box-shadow: 0 0 0 .25rem rgba(255, 193, 7, .5)
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent
}

.btn-outline-danger {
    color: #dc3545;
    border-color: #dc3545
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-check:focus+.btn-outline-danger,
.btn-outline-danger:focus {
    box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .5)
}

.btn-check:active+.btn-outline-danger,
.btn-check:checked+.btn-outline-danger,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show,
.btn-outline-danger:active {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-check:active+.btn-outline-danger:focus,
.btn-check:checked+.btn-outline-danger:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus,
.btn-outline-danger:active:focus {
    box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .5)
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent
}

.btn-outline-light {
    color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-outline-light:hover {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-check:focus+.btn-outline-light,
.btn-outline-light:focus {
    box-shadow: 0 0 0 .25rem rgba(248, 249, 250, .5)
}

.btn-check:active+.btn-outline-light,
.btn-check:checked+.btn-outline-light,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show,
.btn-outline-light:active {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-check:active+.btn-outline-light:focus,
.btn-check:checked+.btn-outline-light:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus,
.btn-outline-light:active:focus {
    box-shadow: 0 0 0 .25rem rgba(248, 249, 250, .5)
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent
}

.btn-outline-dark {
    color: #212529;
    border-color: #212529
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #212529;
    border-color: #212529
}

.btn-check:focus+.btn-outline-dark,
.btn-outline-dark:focus {
    box-shadow: 0 0 0 .25rem rgba(33, 37, 41, .5)
}

.btn-check:active+.btn-outline-dark,
.btn-check:checked+.btn-outline-dark,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show,
.btn-outline-dark:active {
    color: #fff;
    background-color: #212529;
    border-color: #212529
}

.btn-check:active+.btn-outline-dark:focus,
.btn-check:checked+.btn-outline-dark:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus,
.btn-outline-dark:active:focus {
    box-shadow: 0 0 0 .25rem rgba(33, 37, 41, .5)
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #212529;
    background-color: transparent
}

.btn-link {
    font-weight: 400;
    color: #0d6efd;
    text-decoration: underline
}

.btn-link:hover {
    color: #0a58ca
}

.btn-link.disabled,
.btn-link:disabled {
    color: #6c757d
}

.btn-group-lg>.btn,
.btn-lg {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    border-radius: .3rem
}

.btn-group-sm>.btn,
.btn-sm {
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: .2rem
}

.fade {
    transition: opacity .15s linear
}

@media (prefers-reduced-motion:reduce) {
    .fade {
        transition: none
    }
}

.fade:not(.show) {
    opacity: 0
}

.collapse:not(.show) {
    display: none
}

.collapsing {
    height: 0;
    overflow: hidden;
    transition: height .35s ease
}

@media (prefers-reduced-motion:reduce) {
    .collapsing {
        transition: none
    }
}

.collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width .35s ease
}

@media (prefers-reduced-motion:reduce) {
    .collapsing.collapse-horizontal {
        transition: none
    }
}

.dropdown,
.dropend,
.dropstart,
.dropup {
    position: relative
}

.dropdown-toggle {
    white-space: nowrap
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent
}

.dropdown-toggle:empty::after {
    margin-left: 0
}

.dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: .5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem
}

.dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: .125rem
}

.dropdown-menu-start {
    --bs-position: start
}

.dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0
}

.dropdown-menu-end {
    --bs-position: end
}

.dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto
}

@media (min-width:576px) {
    .dropdown-menu-sm-start {
        --bs-position: start
    }

    .dropdown-menu-sm-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-sm-end {
        --bs-position: end
    }

    .dropdown-menu-sm-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width:768px) {
    .dropdown-menu-md-start {
        --bs-position: start
    }

    .dropdown-menu-md-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-md-end {
        --bs-position: end
    }

    .dropdown-menu-md-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width:992px) {
    .dropdown-menu-lg-start {
        --bs-position: start
    }

    .dropdown-menu-lg-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-lg-end {
        --bs-position: end
    }

    .dropdown-menu-lg-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width:1200px) {
    .dropdown-menu-xl-start {
        --bs-position: start
    }

    .dropdown-menu-xl-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-xl-end {
        --bs-position: end
    }

    .dropdown-menu-xl-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width:1400px) {
    .dropdown-menu-xxl-start {
        --bs-position: start
    }

    .dropdown-menu-xxl-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-xxl-end {
        --bs-position: end
    }

    .dropdown-menu-xxl-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

.dropup .dropdown-menu[data-bs-popper] {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: .125rem
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropend .dropdown-menu[data-bs-popper] {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: .125rem
}

.dropend .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: 0;
    border-bottom: .3em solid transparent;
    border-left: .3em solid
}

.dropend .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropend .dropdown-toggle::after {
    vertical-align: 0
}

.dropstart .dropdown-menu[data-bs-popper] {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: .125rem
}

.dropstart .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: ""
}

.dropstart .dropdown-toggle::after {
    display: none
}

.dropstart .dropdown-toggle::before {
    display: inline-block;
    margin-right: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: .3em solid;
    border-bottom: .3em solid transparent
}

.dropstart .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropstart .dropdown-toggle::before {
    vertical-align: 0
}

.dropdown-divider {
    height: 0;
    margin: .5rem 0;
    overflow: hidden;
    border-top: 1px solid rgba(0, 0, 0, .15)
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #1e2125;
    background-color: #e9ecef
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0d6efd
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent
}

.dropdown-menu.show {
    display: block
}

.dropdown-header {
    display: block;
    padding: .5rem 1rem;
    margin-bottom: 0;
    font-size: .875rem;
    color: #6c757d;
    white-space: nowrap
}

.dropdown-item-text {
    display: block;
    padding: .25rem 1rem;
    color: #212529
}

.dropdown-menu-dark {
    color: #dee2e6;
    background-color: #343a40;
    border-color: rgba(0, 0, 0, .15)
}

.dropdown-menu-dark .dropdown-item {
    color: #dee2e6
}

.dropdown-menu-dark .dropdown-item:focus,
.dropdown-menu-dark .dropdown-item:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, .15)
}

.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
    color: #fff;
    background-color: #0d6efd
}

.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
    color: #adb5bd
}

.dropdown-menu-dark .dropdown-divider {
    border-color: rgba(0, 0, 0, .15)
}

.dropdown-menu-dark .dropdown-item-text {
    color: #dee2e6
}

.dropdown-menu-dark .dropdown-header {
    color: #adb5bd
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle
}

.btn-group-vertical>.btn,
.btn-group>.btn {
    position: relative;
    flex: 1 1 auto
}

.btn-group-vertical>.btn-check:checked+.btn,
.btn-group-vertical>.btn-check:focus+.btn,
.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:hover,
.btn-group>.btn-check:checked+.btn,
.btn-group>.btn-check:focus+.btn,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus,
.btn-group>.btn:hover {
    z-index: 1
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

.btn-group>.btn-group:not(:first-child),
.btn-group>.btn:not(:first-child) {
    margin-left: -1px
}

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:nth-child(n+3),
.btn-group>:not(.btn-check)+.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.dropdown-toggle-split {
    padding-right: .5625rem;
    padding-left: .5625rem
}

.dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
    margin-left: 0
}

.dropstart .dropdown-toggle-split::before {
    margin-right: 0
}

.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split {
    padding-right: .375rem;
    padding-left: .375rem
}

.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split {
    padding-right: .75rem;
    padding-left: .75rem
}

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
    width: 100%
}

.btn-group-vertical>.btn-group:not(:first-child),
.btn-group-vertical>.btn:not(:first-child) {
    margin-top: -1px
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.btn-group-vertical>.btn~.btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-link {
    display: block;
    padding: .5rem 1rem;
    color: #0d6efd;
    text-decoration: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .nav-link {
        transition: none
    }
}

.nav-link:focus,
.nav-link:hover {
    color: #0a58ca
}

.nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6
}

.nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: #e9ecef #e9ecef #dee2e6;
    isolation: isolate
}

.nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav-pills .nav-link {
    background: 0 0;
    border: 0;
    border-radius: .25rem
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0d6efd
}

.nav-fill .nav-item,
.nav-fill>.nav-link {
    flex: 1 1 auto;
    text-align: center
}

.nav-justified .nav-item,
.nav-justified>.nav-link {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
    width: 100%
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-lg,
.navbar>.container-md,
.navbar>.container-sm,
.navbar>.container-xl,
.navbar>.container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between
}

.navbar-brand {
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    text-decoration: none;
    white-space: nowrap
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0
}

.navbar-nav .dropdown-menu {
    position: static
}

.navbar-text {
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .navbar-toggler {
        transition: none
    }
}

.navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 .25rem
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%
}

.navbar-nav-scroll {
    max-height: var(--bs-scroll-height, 75vh);
    overflow-y: auto
}

@media (min-width:576px) {
    .navbar-expand-sm {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-sm .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-sm .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-sm .navbar-toggler {
        display: none
    }

    .navbar-expand-sm .offcanvas-header {
        display: none
    }

    .navbar-expand-sm .offcanvas {
        position: inherit;
        bottom: 0;
        z-index: 1000;
        flex-grow: 1;
        visibility: visible !important;
        background-color: transparent;
        border-right: 0;
        border-left: 0;
        transition: none;
        transform: none
    }

    .navbar-expand-sm .offcanvas-bottom,
    .navbar-expand-sm .offcanvas-top {
        height: auto;
        border-top: 0;
        border-bottom: 0
    }

    .navbar-expand-sm .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media (min-width:768px) {
    .navbar-expand-md {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-md .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-md .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-md .navbar-toggler {
        display: none
    }

    .navbar-expand-md .offcanvas-header {
        display: none
    }

    .navbar-expand-md .offcanvas {
        position: inherit;
        bottom: 0;
        z-index: 1000;
        flex-grow: 1;
        visibility: visible !important;
        background-color: transparent;
        border-right: 0;
        border-left: 0;
        transition: none;
        transform: none
    }

    .navbar-expand-md .offcanvas-bottom,
    .navbar-expand-md .offcanvas-top {
        height: auto;
        border-top: 0;
        border-bottom: 0
    }

    .navbar-expand-md .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media (min-width:992px) {
    .navbar-expand-lg {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-lg .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-lg .navbar-toggler {
        display: none
    }

    .navbar-expand-lg .offcanvas-header {
        display: none
    }

    .navbar-expand-lg .offcanvas {
        position: inherit;
        bottom: 0;
        z-index: 1000;
        flex-grow: 1;
        visibility: visible !important;
        background-color: transparent;
        border-right: 0;
        border-left: 0;
        transition: none;
        transform: none
    }

    .navbar-expand-lg .offcanvas-bottom,
    .navbar-expand-lg .offcanvas-top {
        height: auto;
        border-top: 0;
        border-bottom: 0
    }

    .navbar-expand-lg .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media (min-width:1200px) {
    .navbar-expand-xl {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-xl .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-xl .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-xl .navbar-toggler {
        display: none
    }

    .navbar-expand-xl .offcanvas-header {
        display: none
    }

    .navbar-expand-xl .offcanvas {
        position: inherit;
        bottom: 0;
        z-index: 1000;
        flex-grow: 1;
        visibility: visible !important;
        background-color: transparent;
        border-right: 0;
        border-left: 0;
        transition: none;
        transform: none
    }

    .navbar-expand-xl .offcanvas-bottom,
    .navbar-expand-xl .offcanvas-top {
        height: auto;
        border-top: 0;
        border-bottom: 0
    }

    .navbar-expand-xl .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media (min-width:1400px) {
    .navbar-expand-xxl {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-xxl .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-xxl .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-xxl .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-xxl .navbar-toggler {
        display: none
    }

    .navbar-expand-xxl .offcanvas-header {
        display: none
    }

    .navbar-expand-xxl .offcanvas {
        position: inherit;
        bottom: 0;
        z-index: 1000;
        flex-grow: 1;
        visibility: visible !important;
        background-color: transparent;
        border-right: 0;
        border-left: 0;
        transition: none;
        transform: none
    }

    .navbar-expand-xxl .offcanvas-bottom,
    .navbar-expand-xxl .offcanvas-top {
        height: auto;
        border-top: 0;
        border-bottom: 0
    }

    .navbar-expand-xxl .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

.navbar-expand {
    flex-wrap: nowrap;
    justify-content: flex-start
}

.navbar-expand .navbar-nav {
    flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
}

.navbar-expand .navbar-nav-scroll {
    overflow: visible
}

.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto
}

.navbar-expand .navbar-toggler {
    display: none
}

.navbar-expand .offcanvas-header {
    display: none
}

.navbar-expand .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none
}

.navbar-expand .offcanvas-bottom,
.navbar-expand .offcanvas-top {
    height: auto;
    border-top: 0;
    border-bottom: 0
}

.navbar-expand .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible
}

.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, .55)
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, .7)
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, .3)
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show>.nav-link {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, .55);
    border-color: rgba(0, 0, 0, .1)
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-light .navbar-text {
    color: rgba(0, 0, 0, .55)
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
    color: rgba(0, 0, 0, .9)
}

.navbar-dark .navbar-brand {
    color: #fff
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, .55)
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, .75)
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, .25)
}

.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show>.nav-link {
    color: #fff
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, .55);
    border-color: rgba(255, 255, 255, .1)
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, .55)
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
    color: #fff
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem
}

.card>hr {
    margin-right: 0;
    margin-left: 0
}

.card>.list-group {
    border-top: inherit;
    border-bottom: inherit
}

.card>.list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
}

.card>.list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: calc(.25rem - 1px);
    border-bottom-left-radius: calc(.25rem - 1px)
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
    border-top: 0
}

.cards-body {
    flex: 1 1 auto;
    padding: 1rem 1rem
}

.card-title {
    margin-bottom: .5rem;
    /* display: flex; */
    
}

.card-subtitle {
    margin-top: -.25rem;
    margin-bottom: 0
}

.card-text:last-child {
    margin-bottom: 0
}

.card-link+.card-link {
    margin-left: 1rem
}

.card-header {
    padding: .5rem 1rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 1px solid rgba(0, 0, 0, .125)
}

.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0
}

.card-footer {
    padding: .5rem 1rem;
    background-color: rgba(0, 0, 0, .03);
    border-top: 1px solid rgba(0, 0, 0, .125)
}

.card-footer:last-child {
    border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px)
}

.card-header-tabs {
    margin-right: -.5rem;
    margin-bottom: -.5rem;
    margin-left: -.5rem;
    border-bottom: 0
}

.card-header-pills {
    margin-right: -.5rem;
    margin-left: -.5rem
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    border-radius: calc(.25rem - 1px)
}

.card-img,
.card-img-bottom,
.card-img-top {
    width: 100%
}

.card-img,
.card-img-top {
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
}

.card-img,
.card-img-bottom {
    border-bottom-right-radius: calc(.25rem - 1px);
    border-bottom-left-radius: calc(.25rem - 1px)
}

.card-group>.card {
    margin-bottom: .75rem
}

@media (min-width:576px) {
    .card-group {
        display: flex;
        flex-flow: row wrap
    }

    .card-group>.card {
        flex: 1 0 0%;
        margin-bottom: 0
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0
    }

    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-header,
    .card-group>.card:not(:last-child) .card-img-top {
        border-top-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-footer,
    .card-group>.card:not(:last-child) .card-img-bottom {
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-header,
    .card-group>.card:not(:first-child) .card-img-top {
        border-top-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-footer,
    .card-group>.card:not(:first-child) .card-img-bottom {
        border-bottom-left-radius: 0
    }
}

.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 1.25rem;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease
}

@media (prefers-reduced-motion:reduce) {
    .accordion-button {
        transition: none
    }
}

.accordion-button:not(.collapsed) {
    color: #0c63e4;
    background-color: #e7f1ff;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125)
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    transform: rotate(-180deg)
}

.accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .accordion-button::after {
        transition: none
    }
}

.accordion-button:hover {
    z-index: 2
}

.accordion-button:focus {
    z-index: 3;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.accordion-header {
    margin-bottom: 0
}

.accordion-item {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125)
}

.accordion-item:first-of-type {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
}

.accordion-item:not(:first-of-type) {
    border-top: 0
}

.accordion-item:last-of-type {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: calc(.25rem - 1px);
    border-bottom-left-radius: calc(.25rem - 1px)
}

.accordion-item:last-of-type .accordion-collapse {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.accordion-body {
    padding: 1rem 1.25rem
}

.accordion-flush .accordion-collapse {
    border-width: 0
}

.accordion-flush .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0
}

.accordion-flush .accordion-item:first-child {
    border-top: 0
}

.accordion-flush .accordion-item:last-child {
    border-bottom: 0
}

.accordion-flush .accordion-item .accordion-button {
    border-radius: 0
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0;
    margin-bottom: 1rem;
    list-style: none
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: .5rem
}

.breadcrumb-item+.breadcrumb-item::before {
    float: left;
    padding-right: .5rem;
    color: #6c757d;
    content: var(--bs-breadcrumb-divider, "/")
}

.breadcrumb-item.active {
    color: #6c757d
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none
}

.page-link {
    position: relative;
    display: block;
    color: #0d6efd;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #dee2e6;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .page-link {
        transition: none
    }
}

.page-link:hover {
    z-index: 2;
    color: #0a58ca;
    background-color: #e9ecef;
    border-color: #dee2e6
}

.page-link:focus {
    z-index: 3;
    color: #0a58ca;
    background-color: #e9ecef;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.page-item:not(:first-child) .page-link {
    margin-left: -1px
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff;
    border-color: #dee2e6
}

.page-link {
    padding: .375rem .75rem
}

.page-item:first-child .page-link {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.page-item:last-child .page-link {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem
}

.pagination-lg .page-link {
    padding: .75rem 1.5rem;
    font-size: 1.25rem
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: .3rem;
    border-bottom-left-radius: .3rem
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem
}

.pagination-sm .page-link {
    padding: .25rem .5rem;
    font-size: .875rem
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem
}

.badge {
    display: inline-block;
    padding: .35em .65em;
    font-size: .75em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem
}

.badge:empty {
    display: none
}

.btn .badge {
    position: relative;
    top: -1px
}

.alert {
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem
}

.alert-heading {
    color: inherit
}

.alert-link {
    font-weight: 700
}

.alert-dismissible {
    padding-right: 3rem
}

.alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem
}

.alert-primary {
    color: #084298;
    background-color: #cfe2ff;
    border-color: #b6d4fe
}

.alert-primary .alert-link {
    color: #06357a
}

.alert-secondary {
    color: #41464b;
    background-color: #e2e3e5;
    border-color: #d3d6d8
}

.alert-secondary .alert-link {
    color: #34383c
}

.alert-success {
    color: #0f5132;
    background-color: #d1e7dd;
    border-color: #badbcc
}

.alert-success .alert-link {
    color: #0c4128
}

.alert-info {
    color: #055160;
    background-color: #cff4fc;
    border-color: #b6effb
}

.alert-info .alert-link {
    color: #04414d
}

.alert-warning {
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5
}

.alert-warning .alert-link {
    color: #523e02
}

.alert-danger {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7
}

.alert-danger .alert-link {
    color: #6a1a21
}

.alert-light {
    color: #636464;
    background-color: #fefefe;
    border-color: #fdfdfe
}

.alert-light .alert-link {
    color: #4f5050
}

.alert-dark {
    color: #141619;
    background-color: #d3d3d4;
    border-color: #bcbebf
}

.alert-dark .alert-link {
    color: #101214
}

@-webkit-keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem
    }
}

@keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem
    }
}

.progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: .75rem;
    background-color: #e9ecef;
    border-radius: .25rem
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #0d6efd;
    transition: width .6s ease
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar {
        transition: none
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem
}

.progress-bar-animated {
    -webkit-animation: 1s linear infinite progress-bar-stripes;
    animation: 1s linear infinite progress-bar-stripes
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar-animated {
        -webkit-animation: none;
        animation: none
    }
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: .25rem
}

.list-group-numbered {
    list-style-type: none;
    counter-reset: section
}

.list-group-numbered>li::before {
    content: counters(section, ".") ". ";
    counter-increment: section
}

.list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit
}

.list-group-item-action:focus,
.list-group-item-action:hover {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa
}

.list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef
}

.list-group-item {
    position: relative;
    display: block;
    padding: .5rem 1rem;
    color: #212529;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125)
}

.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit
}

.list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd
}

.list-group-item+.list-group-item {
    border-top-width: 0
}

.list-group-item+.list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px
}

.list-group-horizontal {
    flex-direction: row
}

.list-group-horizontal>.list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0
}

.list-group-horizontal>.list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0
}

.list-group-horizontal>.list-group-item.active {
    margin-top: 0
}

.list-group-horizontal>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
}

@media (min-width:576px) {
    .list-group-horizontal-sm {
        flex-direction: row
    }

    .list-group-horizontal-sm>.list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media (min-width:768px) {
    .list-group-horizontal-md {
        flex-direction: row
    }

    .list-group-horizontal-md>.list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-md>.list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-md>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media (min-width:992px) {
    .list-group-horizontal-lg {
        flex-direction: row
    }

    .list-group-horizontal-lg>.list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media (min-width:1200px) {
    .list-group-horizontal-xl {
        flex-direction: row
    }

    .list-group-horizontal-xl>.list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media (min-width:1400px) {
    .list-group-horizontal-xxl {
        flex-direction: row
    }

    .list-group-horizontal-xxl>.list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-xxl>.list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xxl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

.list-group-flush {
    border-radius: 0
}

.list-group-flush>.list-group-item {
    border-width: 0 0 1px
}

.list-group-flush>.list-group-item:last-child {
    border-bottom-width: 0
}

.list-group-item-primary {
    color: #084298;
    background-color: #cfe2ff
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
    color: #084298;
    background-color: #bacbe6
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #084298;
    border-color: #084298
}

.list-group-item-secondary {
    color: #41464b;
    background-color: #e2e3e5
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
    color: #41464b;
    background-color: #cbccce
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #41464b;
    border-color: #41464b
}

.list-group-item-success {
    color: #0f5132;
    background-color: #d1e7dd
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
    color: #0f5132;
    background-color: #bcd0c7
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #0f5132;
    border-color: #0f5132
}

.list-group-item-info {
    color: #055160;
    background-color: #cff4fc
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
    color: #055160;
    background-color: #badce3
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #055160;
    border-color: #055160
}

.list-group-item-warning {
    color: #664d03;
    background-color: #fff3cd
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
    color: #664d03;
    background-color: #e6dbb9
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #664d03;
    border-color: #664d03
}

.list-group-item-danger {
    color: #842029;
    background-color: #f8d7da
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
    color: #842029;
    background-color: #dfc2c4
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #842029;
    border-color: #842029
}

.list-group-item-light {
    color: #636464;
    background-color: #fefefe
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
    color: #636464;
    background-color: #e5e5e5
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #636464;
    border-color: #636464
}

.list-group-item-dark {
    color: #141619;
    background-color: #d3d3d4
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
    color: #141619;
    background-color: #bebebf
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #141619;
    border-color: #141619
}

.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em .25em;
    color: #000;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: .25rem;
    opacity: .5
}

.btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75
}

.btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
    opacity: 1
}

.btn-close.disabled,
.btn-close:disabled {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    opacity: .25
}

.btn-close-white {
    filter: invert(1) grayscale(100%) brightness(200%)
}

.toast {
    width: 350px;
    max-width: 100%;
    font-size: .875rem;
    pointer-events: auto;
    background-color: rgba(255, 255, 255, .85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
    border-radius: .25rem
}

.toast.showing {
    opacity: 0
}

.toast:not(.show) {
    display: none
}

.toast-container {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 100%;
    pointer-events: none
}

.toast-container>:not(:last-child) {
    margin-bottom: .75rem
}

.toast-header {
    display: flex;
    align-items: center;
    padding: .5rem .75rem;
    color: #6c757d;
    background-color: rgba(255, 255, 255, .85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
}

.toast-header .btn-close {
    margin-right: -.375rem;
    margin-left: .75rem
}

.toast-body {
    padding: .75rem;
    word-wrap: break-word
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none
}

.modal.fade .modal-dialog {
    transition: transform .3s ease-out;
    transform: translate(0, -50px)
}

@media (prefers-reduced-motion:reduce) {
    .modal.fade .modal-dialog {
        transition: none
    }
}

.modal.show .modal-dialog {
    transform: none
}

.modal.modal-static .modal-dialog {
    transform: scale(1.02)
}

.modal-dialog-scrollable {
    height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem)
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem;
    outline: 0
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    background-color: #000
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: .5
}

.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px)
}

.modal-header .btn-close {
    padding: .5rem .5rem;
    margin: -.5rem -.5rem -.5rem auto
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: .75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px)
}

.modal-footer>* {
    margin: .25rem
}

@media (min-width:576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto
    }

    .modal-dialog-scrollable {
        height: calc(100% - 3.5rem)
    }

    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem)
    }

    .modal-sm {
        max-width: 300px
    }
}

@media (min-width:992px) {

    .modal-lg,
    .modal-xl {
        max-width: 800px
    }
}

@media (min-width:1200px) {
    .modal-xl {
        max-width: 1140px
    }
}

.modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0
}

.modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0
}

.modal-fullscreen .modal-header {
    border-radius: 0
}

.modal-fullscreen .modal-body {
    overflow-y: auto
}

.modal-fullscreen .modal-footer {
    border-radius: 0
}

@media (max-width:575.98px) {
    .modal-fullscreen-sm-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-sm-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-sm-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-sm-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-sm-down .modal-footer {
        border-radius: 0
    }
}

@media (max-width:767.98px) {
    .modal-fullscreen-md-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-md-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-md-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-md-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-md-down .modal-footer {
        border-radius: 0
    }
}

@media (max-width:991.98px) {
    .modal-fullscreen-lg-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-lg-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-lg-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-lg-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-lg-down .modal-footer {
        border-radius: 0
    }
}

@media (max-width:1199.98px) {
    .modal-fullscreen-xl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-xl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-xl-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-xl-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-xl-down .modal-footer {
        border-radius: 0
    }
}

@media (max-width:1399.98px) {
    .modal-fullscreen-xxl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-xxl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-xxl-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-xxl-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-xxl-down .modal-footer {
        border-radius: 0
    }
}

.tooltip {
    position: absolute;
    z-index: 1080;
    display: block;
    margin: 0;
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .875rem;
    word-wrap: break-word;
    opacity: 0
}

.tooltip.show {
    opacity: .9
}

.tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: .8rem;
    height: .4rem
}

.tooltip .tooltip-arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-tooltip-auto[data-popper-placement^=top],
.bs-tooltip-top {
    padding: .4rem 0
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow,
.bs-tooltip-top .tooltip-arrow {
    bottom: 0
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
    top: -1px;
    border-width: .4rem .4rem 0;
    border-top-color: #000
}

.bs-tooltip-auto[data-popper-placement^=right],
.bs-tooltip-end {
    padding: 0 .4rem
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow,
.bs-tooltip-end .tooltip-arrow {
    left: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before {
    right: -1px;
    border-width: .4rem .4rem .4rem 0;
    border-right-color: #000
}

.bs-tooltip-auto[data-popper-placement^=bottom],
.bs-tooltip-bottom {
    padding: .4rem 0
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow,
.bs-tooltip-bottom .tooltip-arrow {
    top: 0
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 .4rem .4rem;
    border-bottom-color: #000
}

.bs-tooltip-auto[data-popper-placement^=left],
.bs-tooltip-start {
    padding: 0 .4rem
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
    right: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
    left: -1px;
    border-width: .4rem 0 .4rem .4rem;
    border-left-color: #000
}

.tooltip-inner {
    max-width: 200px;
    padding: .25rem .5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: .25rem
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1070;
    display: block;
    max-width: 276px;
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem
}

.popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: .5rem
}

.popover .popover-arrow::after,
.popover .popover-arrow::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow,
.bs-popover-top>.popover-arrow {
    bottom: calc(-.5rem - 1px)
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.bs-popover-top>.popover-arrow::before {
    bottom: 0;
    border-width: .5rem .5rem 0;
    border-top-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.bs-popover-top>.popover-arrow::after {
    bottom: 1px;
    border-width: .5rem .5rem 0;
    border-top-color: #fff
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow,
.bs-popover-end>.popover-arrow {
    left: calc(-.5rem - 1px);
    width: .5rem;
    height: 1rem
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.bs-popover-end>.popover-arrow::before {
    left: 0;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.bs-popover-end>.popover-arrow::after {
    left: 1px;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: #fff
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow,
.bs-popover-bottom>.popover-arrow {
    top: calc(-.5rem - 1px)
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.bs-popover-bottom>.popover-arrow::before {
    top: 0;
    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.bs-popover-bottom>.popover-arrow::after {
    top: 1px;
    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: #fff
}

.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.bs-popover-bottom .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -.5rem;
    content: "";
    border-bottom: 1px solid #f0f0f0
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow,
.bs-popover-start>.popover-arrow {
    right: calc(-.5rem - 1px);
    width: .5rem;
    height: 1rem
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.bs-popover-start>.popover-arrow::before {
    right: 0;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.bs-popover-start>.popover-arrow::after {
    right: 1px;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: #fff
}

.popover-header {
    padding: .5rem 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    background-color: #f0f0f0;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px)
}

.popover-header:empty {
    display: none
}

.popover-body {
    padding: 1rem 1rem;
    color: #212529
}

.carousel {
    position: relative
}

.carousel.pointer-event {
    touch-action: pan-y
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: ""
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .carousel-item {
        transition: none
    }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
    display: block
}

.active.carousel-item-end,
.carousel-item-next:not(.carousel-item-start) {
    transform: translateX(100%)
}

.active.carousel-item-start,
.carousel-item-prev:not(.carousel-item-end) {
    transform: translateX(-100%)
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none
}

.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end,
.carousel-fade .carousel-item.active {
    z-index: 1;
    opacity: 1
}

.carousel-fade .active.carousel-item-end,
.carousel-fade .active.carousel-item-start {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s .6s
}

@media (prefers-reduced-motion:reduce) {

    .carousel-fade .active.carousel-item-end,
    .carousel-fade .active.carousel-item-start {
        transition: none
    }
}

.carousel-control-next,
.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease
}

@media (prefers-reduced-motion:reduce) {

    .carousel-control-next,
    .carousel-control-prev {
        transition: none
    }
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9
}

.carousel-control-prev {
    left: 0
}

.carousel-control-next {
    right: 0
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none
}

.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease
}

@media (prefers-reduced-motion:reduce) {
    .carousel-indicators [data-bs-target] {
        transition: none
    }
}

.carousel-indicators .active {
    opacity: 1
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center
}

.carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
    filter: invert(1) grayscale(100)
}

.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #000
}

.carousel-dark .carousel-caption {
    color: #000
}

@-webkit-keyframes spinner-border {
    to {
        transform: rotate(360deg)
    }
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg)
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -.125em;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: .75s linear infinite spinner-border;
    animation: .75s linear infinite spinner-border
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: .2em
}

@-webkit-keyframes spinner-grow {
    0% {
        transform: scale(0)
    }

    50% {
        opacity: 1;
        transform: none
    }
}

@keyframes spinner-grow {
    0% {
        transform: scale(0)
    }

    50% {
        opacity: 1;
        transform: none
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -.125em;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: .75s linear infinite spinner-grow;
    animation: .75s linear infinite spinner-grow
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem
}

@media (prefers-reduced-motion:reduce) {

    .spinner-border,
    .spinner-grow {
        -webkit-animation-duration: 1.5s;
        animation-duration: 1.5s
    }
}

.offcanvas {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    visibility: hidden;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    transition: transform .3s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .offcanvas {
        transition: none
    }
}

.offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000
}

.offcanvas-backdrop.fade {
    opacity: 0
}

.offcanvas-backdrop.show {
    opacity: .5
}

.offcanvas-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem
}

.offcanvas-header .btn-close {
    padding: .5rem .5rem;
    margin-top: -.5rem;
    margin-right: -.5rem;
    margin-bottom: -.5rem
}

.offcanvas-title {
    margin-bottom: 0;
    line-height: 1.5
}

.offcanvas-body {
    flex-grow: 1;
    padding: 1rem 1rem;
    overflow-y: auto
}

.offcanvas-start {
    top: 0;
    left: 0;
    width: 400px;
    border-right: 1px solid rgba(0, 0, 0, .2);
    transform: translateX(-100%)
}

.offcanvas-end {
    top: 0;
    right: 0;
    width: 400px;
    border-left: 1px solid rgba(0, 0, 0, .2);
    transform: translateX(100%)
}

.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    transform: translateY(-100%)
}

.offcanvas-bottom {
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%;
    border-top: 1px solid rgba(0, 0, 0, .2);
    transform: translateY(100%)
}

.offcanvas.show {
    transform: none
}

.placeholder {
    display: inline-block;
    min-height: 1em;
    vertical-align: middle;
    cursor: wait;
    background-color: currentColor;
    opacity: .5
}

.placeholder.btn::before {
    display: inline-block;
    content: ""
}

.placeholder-xs {
    min-height: .6em
}

.placeholder-sm {
    min-height: .8em
}

.placeholder-lg {
    min-height: 1.2em
}

.placeholder-glow .placeholder {
    -webkit-animation: placeholder-glow 2s ease-in-out infinite;
    animation: placeholder-glow 2s ease-in-out infinite
}

@-webkit-keyframes placeholder-glow {
    50% {
        opacity: .2
    }
}

@keyframes placeholder-glow {
    50% {
        opacity: .2
    }
}

.placeholder-wave {
    -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
    mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
    -webkit-mask-size: 200% 100%;
    mask-size: 200% 100%;
    -webkit-animation: placeholder-wave 2s linear infinite;
    animation: placeholder-wave 2s linear infinite
}

@-webkit-keyframes placeholder-wave {
    100% {
        -webkit-mask-position: -200% 0%;
        mask-position: -200% 0%
    }
}

@keyframes placeholder-wave {
    100% {
        -webkit-mask-position: -200% 0%;
        mask-position: -200% 0%
    }
}

.clearfix::after {
    display: block;
    clear: both;
    content: ""
}

.link-primary {
    color: #0d6efd
}

.link-primary:focus,
.link-primary:hover {
    color: #0a58ca
}

.link-secondary {
    color: #6c757d
}

.link-secondary:focus,
.link-secondary:hover {
    color: #565e64
}

.link-success {
    color: #198754
}

.link-success:focus,
.link-success:hover {
    color: #146c43
}

.link-info {
    color: #0dcaf0
}

.link-info:focus,
.link-info:hover {
    color: #3dd5f3
}

.link-warning {
    color: #ffc107
}

.link-warning:focus,
.link-warning:hover {
    color: #ffcd39
}

.link-danger {
    color: #dc3545
}

.link-danger:focus,
.link-danger:hover {
    color: #b02a37
}

.link-light {
    color: #f8f9fa
}

.link-light:focus,
.link-light:hover {
    color: #f9fafb
}

.link-dark {
    color: #212529
}

.link-dark:focus,
.link-dark:hover {
    color: #1a1e21
}

.ratio {
    position: relative;
    width: 100%
}

.ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""
}

.ratio>* {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.ratio-1x1 {
    --bs-aspect-ratio: 100%
}

.ratio-4x3 {
    --bs-aspect-ratio: 75%
}

.ratio-16x9 {
    --bs-aspect-ratio: 56.25%
}

.ratio-21x9 {
    --bs-aspect-ratio: 42.8571428571%
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030
}

.sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020
}

@media (min-width:576px) {
    .sticky-sm-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media (min-width:768px) {
    .sticky-md-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media (min-width:992px) {
    .sticky-lg-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media (min-width:1200px) {
    .sticky-xl-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media (min-width:1400px) {
    .sticky-xxl-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

.hstack {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch
}

.vstack {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-self: stretch
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: ""
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.vr {
    display: inline-block;
    align-self: stretch;
    width: 1px;
    min-height: 1em;
    background-color: currentColor;
    opacity: .25
}

.align-baseline {
    vertical-align: baseline !important
}

.align-top {
    vertical-align: top !important
}

.align-middle {
    vertical-align: middle !important
}

.align-bottom {
    vertical-align: bottom !important
}

.align-text-bottom {
    vertical-align: text-bottom !important
}

.align-text-top {
    vertical-align: text-top !important
}

.float-start {
    float: left !important
}

.float-end {
    float: right !important
}

.float-none {
    float: none !important
}

.opacity-0 {
    opacity: 0 !important
}

.opacity-25 {
    opacity: .25 !important
}

.opacity-50 {
    opacity: .5 !important
}

.opacity-75 {
    opacity: .75 !important
}

.opacity-100 {
    opacity: 1 !important
}

.overflow-auto {
    overflow: auto !important
}

.overflow-hidden {
    overflow: hidden !important
}

.overflow-visible {
    overflow: visible !important
}

.overflow-scroll {
    overflow: scroll !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-grid {
    display: grid !important
}

.d-table {
    display: table !important
}

.d-table-row {
    display: table-row !important
}

.d-table-cell {
    display: table-cell !important
}

.d-flex {
    display: flex !important
}

.d-inline-flex {
    display: inline-flex !important
}

.d-none {
    display: none !important
}

.shadow {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important
}

.shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important
}

.shadow-none {
    box-shadow: none !important
}

.position-static {
    position: static !important
}

.position-relative {
    position: relative !important
}

.position-absolute {
    position: absolute !important
}

.position-fixed {
    position: fixed !important
}

.position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important
}

.top-0 {
    top: 0 !important
}

.top-50 {
    top: 50% !important
}

.top-100 {
    top: 100% !important
}

.bottom-0 {
    bottom: 0 !important
}

.bottom-50 {
    bottom: 50% !important
}

.bottom-100 {
    bottom: 100% !important
}

.start-0 {
    left: 0 !important
}

.start-50 {
    left: 50% !important
}

.start-100 {
    left: 100% !important
}

.end-0 {
    right: 0 !important
}

.end-50 {
    right: 50% !important
}

.end-100 {
    right: 100% !important
}

.translate-middle {
    transform: translate(-50%, -50%) !important
}

.translate-middle-x {
    transform: translateX(-50%) !important
}

.translate-middle-y {
    transform: translateY(-50%) !important
}

.border {
    border: 1px solid #dee2e6 !important
}

.border-0 {
    border: 0 !important
}

.border-top {
    border-top: 1px solid #dee2e6 !important
}

.border-top-0 {
    border-top: 0 !important
}

.border-end {
    border-right: 1px solid #dee2e6 !important
}

.border-end-0 {
    border-right: 0 !important
}

.border-bottom {
    border-bottom: 1px solid #dee2e6 !important
}

.border-bottom-0 {
    border-bottom: 0 !important
}

.border-start {
    border-left: 1px solid #dee2e6 !important
}

.border-start-0 {
    border-left: 0 !important
}

.border-primary {
    border-color: #0d6efd !important
}

.border-secondary {
    border-color: #6c757d !important
}

.border-success {
    border-color: #198754 !important
}

.border-info {
    border-color: #0dcaf0 !important
}

.border-warning {
    border-color: #ffc107 !important
}

.border-danger {
    border-color: #dc3545 !important
}

.border-light {
    border-color: #f8f9fa !important
}

.border-dark {
    border-color: #212529 !important
}

.border-white {
    border-color: #fff !important
}

.border-1 {
    border-width: 1px !important
}

.border-2 {
    border-width: 2px !important
}

.border-3 {
    border-width: 3px !important
}

.border-4 {
    border-width: 4px !important
}

.border-5 {
    border-width: 5px !important
}

.w-25 {
    width: 25% !important
}

.w-50 {
    width: 50% !important
}

.w-75 {
    width: 75% !important
}

.w-100 {
    width: 100% !important
}

.w-auto {
    width: auto !important
}

.mw-100 {
    max-width: 100% !important
}

.vw-100 {
    width: 100vw !important
}

.min-vw-100 {
    min-width: 100vw !important
}

.h-25 {
    height: 25% !important
}

.h-50 {
    height: 50% !important
}

.h-75 {
    height: 75% !important
}

.h-100 {
    height: 100% !important
}

.h-auto {
    height: auto !important
}

.mh-100 {
    max-height: 100% !important
}

.vh-100 {
    height: 100vh !important
}

.min-vh-100 {
    min-height: 100vh !important
}

.flex-fill {
    flex: 1 1 auto !important
}

.flex-row {
    flex-direction: row !important
}

.flex-column {
    flex-direction: column !important
}

.flex-row-reverse {
    flex-direction: row-reverse !important
}

.flex-column-reverse {
    flex-direction: column-reverse !important
}

.flex-grow-0 {
    flex-grow: 0 !important
}

.flex-grow-1 {
    flex-grow: 1 !important
}

.flex-shrink-0 {
    flex-shrink: 0 !important
}

.flex-shrink-1 {
    flex-shrink: 1 !important
}

.flex-wrap {
    flex-wrap: wrap !important
}

.flex-nowrap {
    flex-wrap: nowrap !important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
}

.gap-0 {
    gap: 0 !important
}

.gap-1 {
    gap: .25rem !important
}

.gap-2 {
    gap: .5rem !important
}

.gap-3 {
    gap: 1rem !important
}

.gap-4 {
    gap: 1.5rem !important
}

.gap-5 {
    gap: 3rem !important
}

.justify-content-start {
    justify-content: flex-start !important
}

.justify-content-end {
    justify-content: flex-end !important
}

.justify-content-center {
    justify-content: center !important
}

.justify-content-between {
    justify-content: space-between !important
}

.justify-content-around {
    justify-content: space-around !important
}

.justify-content-evenly {
    justify-content: space-evenly !important
}

.align-items-start {
    align-items: flex-start !important
}

.align-items-end {
    align-items: flex-end !important
}

.align-items-center {
    align-items: center !important
}

.align-items-baseline {
    align-items: baseline !important
}

.align-items-stretch {
    align-items: stretch !important
}

.align-content-start {
    align-content: flex-start !important
}

.align-content-end {
    align-content: flex-end !important
}

.align-content-center {
    align-content: center !important
}

.align-content-between {
    align-content: space-between !important
}

.align-content-around {
    align-content: space-around !important
}

.align-content-stretch {
    align-content: stretch !important
}

.align-self-auto {
    align-self: auto !important
}

.align-self-start {
    align-self: flex-start !important
}

.align-self-end {
    align-self: flex-end !important
}

.align-self-center {
    align-self: center !important
}

.align-self-baseline {
    align-self: baseline !important
}

.align-self-stretch {
    align-self: stretch !important
}

.order-first {
    order: -1 !important
}

.order-0 {
    order: 0 !important
}

.order-1 {
    order: 1 !important
}

.order-2 {
    order: 2 !important
}

.order-3 {
    order: 3 !important
}

.order-4 {
    order: 4 !important
}

.order-5 {
    order: 5 !important
}

.order-last {
    order: 6 !important
}

.m-0 {
    margin: 0 !important
}

.m-1 {
    margin: .25rem !important
}

.m-2 {
    margin: .5rem !important
}

.m-3 {
    margin: 1rem !important
}

.m-4 {
    margin: 1.5rem !important
}

.m-5 {
    margin: 3rem !important
}

.m-auto {
    margin: auto !important
}

.mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important
}

.mx-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important
}

.mx-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important
}

.mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important
}

.mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important
}

.mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
}

.my-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important
}

.my-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important
}

.my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
}

.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
}

.my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
}

.mt-0 {
    margin-top: 0 !important
}

.mt-1 {
    margin-top: .25rem !important
}

.mt-2 {
    margin-top: .5rem !important
}

.mt-3 {
    margin-top: 1rem !important
}

.mt-4 {
    margin-top: 1.5rem !important
}

.mt-5 {
    margin-top: 3rem !important
}

.mt-auto {
    margin-top: auto !important
}

.me-0 {
    margin-right: 0 !important
}

.me-1 {
    margin-right: .25rem !important
}

.me-2 {
    margin-right: .5rem !important
}

.me-3 {
    margin-right: 1rem !important
}

.me-4 {
    margin-right: 1.5rem !important
}

.me-5 {
    margin-right: 3rem !important
}

.me-auto {
    margin-right: auto !important
}

.mb-0 {
    margin-bottom: 0 !important
}

.mb-1 {
    margin-bottom: .25rem !important
}

.mb-2 {
    margin-bottom: .5rem !important
}

.mb-3 {
    margin-bottom: 1rem !important
}

.mb-4 {
    margin-bottom: 1.5rem !important
}

.mb-5 {
    margin-bottom: 3rem !important
}

.mb-auto {
    margin-bottom: auto !important
}

.ms-0 {
    margin-left: 0 !important
}

.ms-1 {
    margin-left: .25rem !important
}

.ms-2 {
    margin-left: .5rem !important
}

.ms-3 {
    margin-left: 1rem !important
}

.ms-4 {
    margin-left: 1.5rem !important
}

.ms-5 {
    margin-left: 3rem !important
}

.ms-auto {
    margin-left: auto !important
}

.p-0 {
    padding: 0 !important
}

.p-1 {
    padding: .25rem !important
}

.p-2 {
    padding: .5rem !important
}

.p-3 {
    padding: 1rem !important
}

.p-4 {
    padding: 1.5rem !important
}

.p-5 {
    padding: 3rem !important
}

.px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important
}

.px-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important
}

.px-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important
}

.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important
}

.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
}

.px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.py-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important
}

.py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
}

.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
}

.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
}

.pt-0 {
    padding-top: 0 !important
}

.pt-1 {
    padding-top: .25rem !important
}

.pt-2 {
    padding-top: .5rem !important
}

.pt-3 {
    padding-top: 1rem !important
}

.pt-4 {
    padding-top: 1.5rem !important
}

.pt-5 {
    padding-top: 3rem !important
}

.pe-0 {
    padding-right: 0 !important
}

.pe-1 {
    padding-right: .25rem !important
}

.pe-2 {
    padding-right: .5rem !important
}

.pe-3 {
    padding-right: 1rem !important
}

.pe-4 {
    padding-right: 1.5rem !important
}

.pe-5 {
    padding-right: 3rem !important
}

.pb-0 {
    padding-bottom: 0 !important
}

.pb-1 {
    padding-bottom: .25rem !important
}

.pb-2 {
    padding-bottom: .5rem !important
}

.pb-3 {
    padding-bottom: 1rem !important
}

.pb-4 {
    padding-bottom: 1.5rem !important
}

.pb-5 {
    padding-bottom: 3rem !important
}

.ps-0 {
    padding-left: 0 !important
}

.ps-1 {
    padding-left: .25rem !important
}

.ps-2 {
    padding-left: .5rem !important
}

.ps-3 {
    padding-left: 1rem !important
}

.ps-4 {
    padding-left: 1.5rem !important
}

.ps-5 {
    padding-left: 3rem !important
}

.font-monospace {
    font-family: var(--bs-font-monospace) !important
}

.fs-1 {
    font-size: calc(1.375rem + 1.5vw) !important
}

.fs-2 {
    font-size: calc(1.325rem + .9vw) !important
}

.fs-3 {
    font-size: calc(1.3rem + .6vw) !important
}

.fs-4 {
    font-size: calc(1.275rem + .3vw) !important
}

.fs-5 {
    font-size: 1.25rem !important
}

.fs-6 {
    font-size: 1rem !important
}

.fst-italic {
    font-style: italic !important
}

.fst-normal {
    font-style: normal !important
}

.fw-light {
    font-weight: 300 !important
}

.fw-lighter {
    font-weight: lighter !important
}

.fw-normal {
    font-weight: 400 !important
}

.fw-bold {
    font-weight: 700 !important
}

.fw-bolder {
    font-weight: bolder !important
}

.lh-1 {
    line-height: 1 !important
}

.lh-sm {
    line-height: 1.25 !important
}

.lh-base {
    line-height: 1.5 !important
}

.lh-lg {
    line-height: 2 !important
}

.text-start {
    text-align: left !important
}

.text-end {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

.text-decoration-none {
    text-decoration: none !important
}

.text-decoration-underline {
    text-decoration: underline !important
}

.text-decoration-line-through {
    text-decoration: line-through !important
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.text-wrap {
    white-space: normal !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-break {
    word-wrap: break-word !important;
    word-break: break-word !important
}

.text-primary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important
}

.text-secondary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important
}

.text-success {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important
}

.text-info {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important
}

.text-warning {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important
}

.text-danger {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important
}

.text-light {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important
}

.text-dark {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important
}

.text-black {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important
}

.text-white {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important
}

.text-body {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important
}

.text-muted {
    --bs-text-opacity: 1;
    color: #6c757d !important
}

.text-black-50 {
    --bs-text-opacity: 1;
    color: rgba(0, 0, 0, .5) !important
}

.text-white-50 {
    --bs-text-opacity: 1;
    color: rgba(255, 255, 255, .5) !important
}

.text-reset {
    --bs-text-opacity: 1;
    color: inherit !important
}

.text-opacity-25 {
    --bs-text-opacity: 0.25
}

.text-opacity-50 {
    --bs-text-opacity: 0.5
}

.text-opacity-75 {
    --bs-text-opacity: 0.75
}

.text-opacity-100 {
    --bs-text-opacity: 1
}

.bg-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important
}

.bg-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important
}

.bg-success {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important
}

.bg-info {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important
}

.bg-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important
}

.bg-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important
}

.bg-light {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important
}

.bg-dark {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important
}

.bg-black {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important
}

.bg-white {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important
}

.bg-body {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important
}

.bg-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent !important
}

.bg-opacity-10 {
    --bs-bg-opacity: 0.1
}

.bg-opacity-25 {
    --bs-bg-opacity: 0.25
}

.bg-opacity-50 {
    --bs-bg-opacity: 0.5
}

.bg-opacity-75 {
    --bs-bg-opacity: 0.75
}

.bg-opacity-100 {
    --bs-bg-opacity: 1
}

.bg-gradient {
    background-image: var(--bs-gradient) !important
}

.user-select-all {
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    user-select: all !important
}

.user-select-auto {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    user-select: auto !important
}

.user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    user-select: none !important
}

.pe-none {
    pointer-events: none !important
}

.pe-auto {
    pointer-events: auto !important
}

.rounded {
    border-radius: .25rem !important
}

.rounded-0 {
    border-radius: 0 !important
}

.rounded-1 {
    border-radius: .2rem !important
}

.rounded-2 {
    border-radius: .25rem !important
}

.rounded-3 {
    border-radius: .3rem !important
}

.rounded-circle {
    border-radius: 50% !important
}

.rounded-pill {
    border-radius: 50rem !important
}

.rounded-top {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important
}

.rounded-end {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important
}

.rounded-bottom {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important
}

.rounded-start {
    border-bottom-left-radius: .25rem !important;
    border-top-left-radius: .25rem !important
}

.visible {
    visibility: visible !important
}

.invisible {
    visibility: hidden !important
}

@media (min-width:576px) {
    .float-sm-start {
        float: left !important
    }

    .float-sm-end {
        float: right !important
    }

    .float-sm-none {
        float: none !important
    }

    .d-sm-inline {
        display: inline !important
    }

    .d-sm-inline-block {
        display: inline-block !important
    }

    .d-sm-block {
        display: block !important
    }

    .d-sm-grid {
        display: grid !important
    }

    .d-sm-table {
        display: table !important
    }

    .d-sm-table-row {
        display: table-row !important
    }

    .d-sm-table-cell {
        display: table-cell !important
    }

    .d-sm-flex {
        display: flex !important
    }

    .d-sm-inline-flex {
        display: inline-flex !important
    }

    .d-sm-none {
        display: none !important
    }

    .flex-sm-fill {
        flex: 1 1 auto !important
    }

    .flex-sm-row {
        flex-direction: row !important
    }

    .flex-sm-column {
        flex-direction: column !important
    }

    .flex-sm-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-sm-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-sm-grow-0 {
        flex-grow: 0 !important
    }

    .flex-sm-grow-1 {
        flex-grow: 1 !important
    }

    .flex-sm-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-sm-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-sm-wrap {
        flex-wrap: wrap !important
    }

    .flex-sm-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .gap-sm-0 {
        gap: 0 !important
    }

    .gap-sm-1 {
        gap: .25rem !important
    }

    .gap-sm-2 {
        gap: .5rem !important
    }

    .gap-sm-3 {
        gap: 1rem !important
    }

    .gap-sm-4 {
        gap: 1.5rem !important
    }

    .gap-sm-5 {
        gap: 3rem !important
    }

    .justify-content-sm-start {
        justify-content: flex-start !important
    }

    .justify-content-sm-end {
        justify-content: flex-end !important
    }

    .justify-content-sm-center {
        justify-content: center !important
    }

    .justify-content-sm-between {
        justify-content: space-between !important
    }

    .justify-content-sm-around {
        justify-content: space-around !important
    }

    .justify-content-sm-evenly {
        justify-content: space-evenly !important
    }

    .align-items-sm-start {
        align-items: flex-start !important
    }

    .align-items-sm-end {
        align-items: flex-end !important
    }

    .align-items-sm-center {
        align-items: center !important
    }

    .align-items-sm-baseline {
        align-items: baseline !important
    }

    .align-items-sm-stretch {
        align-items: stretch !important
    }

    .align-content-sm-start {
        align-content: flex-start !important
    }

    .align-content-sm-end {
        align-content: flex-end !important
    }

    .align-content-sm-center {
        align-content: center !important
    }

    .align-content-sm-between {
        align-content: space-between !important
    }

    .align-content-sm-around {
        align-content: space-around !important
    }

    .align-content-sm-stretch {
        align-content: stretch !important
    }

    .align-self-sm-auto {
        align-self: auto !important
    }

    .align-self-sm-start {
        align-self: flex-start !important
    }

    .align-self-sm-end {
        align-self: flex-end !important
    }

    .align-self-sm-center {
        align-self: center !important
    }

    .align-self-sm-baseline {
        align-self: baseline !important
    }

    .align-self-sm-stretch {
        align-self: stretch !important
    }

    .order-sm-first {
        order: -1 !important
    }

    .order-sm-0 {
        order: 0 !important
    }

    .order-sm-1 {
        order: 1 !important
    }

    .order-sm-2 {
        order: 2 !important
    }

    .order-sm-3 {
        order: 3 !important
    }

    .order-sm-4 {
        order: 4 !important
    }

    .order-sm-5 {
        order: 5 !important
    }

    .order-sm-last {
        order: 6 !important
    }

    .m-sm-0 {
        margin: 0 !important
    }

    .m-sm-1 {
        margin: .25rem !important
    }

    .m-sm-2 {
        margin: .5rem !important
    }

    .m-sm-3 {
        margin: 1rem !important
    }

    .m-sm-4 {
        margin: 1.5rem !important
    }

    .m-sm-5 {
        margin: 3rem !important
    }

    .m-sm-auto {
        margin: auto !important
    }

    .mx-sm-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-sm-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-sm-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-sm-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-sm-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-sm-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-sm-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-sm-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-sm-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-sm-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-sm-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-sm-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-sm-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-sm-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-sm-0 {
        margin-top: 0 !important
    }

    .mt-sm-1 {
        margin-top: .25rem !important
    }

    .mt-sm-2 {
        margin-top: .5rem !important
    }

    .mt-sm-3 {
        margin-top: 1rem !important
    }

    .mt-sm-4 {
        margin-top: 1.5rem !important
    }

    .mt-sm-5 {
        margin-top: 3rem !important
    }

    .mt-sm-auto {
        margin-top: auto !important
    }

    .me-sm-0 {
        margin-right: 0 !important
    }

    .me-sm-1 {
        margin-right: .25rem !important
    }

    .me-sm-2 {
        margin-right: .5rem !important
    }

    .me-sm-3 {
        margin-right: 1rem !important
    }

    .me-sm-4 {
        margin-right: 1.5rem !important
    }

    .me-sm-5 {
        margin-right: 3rem !important
    }

    .me-sm-auto {
        margin-right: auto !important
    }

    .mb-sm-0 {
        margin-bottom: 0 !important
    }

    .mb-sm-1 {
        margin-bottom: .25rem !important
    }

    .mb-sm-2 {
        margin-bottom: .5rem !important
    }

    .mb-sm-3 {
        margin-bottom: 1rem !important
    }

    .mb-sm-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-sm-5 {
        margin-bottom: 3rem !important
    }

    .mb-sm-auto {
        margin-bottom: auto !important
    }

    .ms-sm-0 {
        margin-left: 0 !important
    }

    .ms-sm-1 {
        margin-left: .25rem !important
    }

    .ms-sm-2 {
        margin-left: .5rem !important
    }

    .ms-sm-3 {
        margin-left: 1rem !important
    }

    .ms-sm-4 {
        margin-left: 1.5rem !important
    }

    .ms-sm-5 {
        margin-left: 3rem !important
    }

    .ms-sm-auto {
        margin-left: auto !important
    }

    .p-sm-0 {
        padding: 0 !important
    }

    .p-sm-1 {
        padding: .25rem !important
    }

    .p-sm-2 {
        padding: .5rem !important
    }

    .p-sm-3 {
        padding: 1rem !important
    }

    .p-sm-4 {
        padding: 1.5rem !important
    }

    .p-sm-5 {
        padding: 3rem !important
    }

    .px-sm-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-sm-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-sm-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-sm-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-sm-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-sm-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-sm-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-sm-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-sm-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-sm-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-sm-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-sm-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .pt-sm-0 {
        padding-top: 0 !important
    }

    .pt-sm-1 {
        padding-top: .25rem !important
    }

    .pt-sm-2 {
        padding-top: .5rem !important
    }

    .pt-sm-3 {
        padding-top: 1rem !important
    }

    .pt-sm-4 {
        padding-top: 1.5rem !important
    }

    .pt-sm-5 {
        padding-top: 3rem !important
    }

    .pe-sm-0 {
        padding-right: 0 !important
    }

    .pe-sm-1 {
        padding-right: .25rem !important
    }

    .pe-sm-2 {
        padding-right: .5rem !important
    }

    .pe-sm-3 {
        padding-right: 1rem !important
    }

    .pe-sm-4 {
        padding-right: 1.5rem !important
    }

    .pe-sm-5 {
        padding-right: 3rem !important
    }

    .pb-sm-0 {
        padding-bottom: 0 !important
    }

    .pb-sm-1 {
        padding-bottom: .25rem !important
    }

    .pb-sm-2 {
        padding-bottom: .5rem !important
    }

    .pb-sm-3 {
        padding-bottom: 1rem !important
    }

    .pb-sm-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-sm-5 {
        padding-bottom: 3rem !important
    }

    .ps-sm-0 {
        padding-left: 0 !important
    }

    .ps-sm-1 {
        padding-left: .25rem !important
    }

    .ps-sm-2 {
        padding-left: .5rem !important
    }

    .ps-sm-3 {
        padding-left: 1rem !important
    }

    .ps-sm-4 {
        padding-left: 1.5rem !important
    }

    .ps-sm-5 {
        padding-left: 3rem !important
    }

    .text-sm-start {
        text-align: left !important
    }

    .text-sm-end {
        text-align: right !important
    }

    .text-sm-center {
        text-align: center !important
    }
}

@media (min-width:768px) {
    .float-md-start {
        float: left !important
    }

    .float-md-end {
        float: right !important
    }

    .float-md-none {
        float: none !important
    }

    .d-md-inline {
        display: inline !important
    }

    .d-md-inline-block {
        display: inline-block !important
    }

    .d-md-block {
        display: block !important
    }

    .d-md-grid {
        display: grid !important
    }

    .d-md-table {
        display: table !important
    }

    .d-md-table-row {
        display: table-row !important
    }

    .d-md-table-cell {
        display: table-cell !important
    }

    .d-md-flex {
        display: flex !important
    }

    .d-md-inline-flex {
        display: inline-flex !important
    }

    .d-md-none {
        display: none !important
    }

    .flex-md-fill {
        flex: 1 1 auto !important
    }

    .flex-md-row {
        flex-direction: row !important
    }

    .flex-md-column {
        flex-direction: column !important
    }

    .flex-md-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-md-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-md-grow-0 {
        flex-grow: 0 !important
    }

    .flex-md-grow-1 {
        flex-grow: 1 !important
    }

    .flex-md-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-md-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-md-wrap {
        flex-wrap: wrap !important
    }

    .flex-md-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .gap-md-0 {
        gap: 0 !important
    }

    .gap-md-1 {
        gap: .25rem !important
    }

    .gap-md-2 {
        gap: .5rem !important
    }

    .gap-md-3 {
        gap: 1rem !important
    }

    .gap-md-4 {
        gap: 1.5rem !important
    }

    .gap-md-5 {
        gap: 3rem !important
    }

    .justify-content-md-start {
        justify-content: flex-start !important
    }

    .justify-content-md-end {
        justify-content: flex-end !important
    }

    .justify-content-md-center {
        justify-content: center !important
    }

    .justify-content-md-between {
        justify-content: space-between !important
    }

    .justify-content-md-around {
        justify-content: space-around !important
    }

    .justify-content-md-evenly {
        justify-content: space-evenly !important
    }

    .align-items-md-start {
        align-items: flex-start !important
    }

    .align-items-md-end {
        align-items: flex-end !important
    }

    .align-items-md-center {
        align-items: center !important
    }

    .align-items-md-baseline {
        align-items: baseline !important
    }

    .align-items-md-stretch {
        align-items: stretch !important
    }

    .align-content-md-start {
        align-content: flex-start !important
    }

    .align-content-md-end {
        align-content: flex-end !important
    }

    .align-content-md-center {
        align-content: center !important
    }

    .align-content-md-between {
        align-content: space-between !important
    }

    .align-content-md-around {
        align-content: space-around !important
    }

    .align-content-md-stretch {
        align-content: stretch !important
    }

    .align-self-md-auto {
        align-self: auto !important
    }

    .align-self-md-start {
        align-self: flex-start !important
    }

    .align-self-md-end {
        align-self: flex-end !important
    }

    .align-self-md-center {
        align-self: center !important
    }

    .align-self-md-baseline {
        align-self: baseline !important
    }

    .align-self-md-stretch {
        align-self: stretch !important
    }

    .order-md-first {
        order: -1 !important
    }

    .order-md-0 {
        order: 0 !important
    }

    .order-md-1 {
        order: 1 !important
    }

    .order-md-2 {
        order: 2 !important
    }

    .order-md-3 {
        order: 3 !important
    }

    .order-md-4 {
        order: 4 !important
    }

    .order-md-5 {
        order: 5 !important
    }

    .order-md-last {
        order: 6 !important
    }

    .m-md-0 {
        margin: 0 !important
    }

    .m-md-1 {
        margin: .25rem !important
    }

    .m-md-2 {
        margin: .5rem !important
    }

    .m-md-3 {
        margin: 1rem !important
    }

    .m-md-4 {
        margin: 1.5rem !important
    }

    .m-md-5 {
        margin: 3rem !important
    }

    .m-md-auto {
        margin: auto !important
    }

    .mx-md-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-md-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-md-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-md-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-md-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-md-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-md-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-md-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-md-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-md-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-md-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-md-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-md-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-md-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-md-0 {
        margin-top: 0 !important
    }

    .mt-md-1 {
        margin-top: .25rem !important
    }

    .mt-md-2 {
        margin-top: .5rem !important
    }

    .mt-md-3 {
        margin-top: 1rem !important
    }

    .mt-md-4 {
        margin-top: 1.5rem !important
    }

    .mt-md-5 {
        margin-top: 3rem !important
    }

    .mt-md-auto {
        margin-top: auto !important
    }

    .me-md-0 {
        margin-right: 0 !important
    }

    .me-md-1 {
        margin-right: .25rem !important
    }

    .me-md-2 {
        margin-right: .5rem !important
    }

    .me-md-3 {
        margin-right: 1rem !important
    }

    .me-md-4 {
        margin-right: 1.5rem !important
    }

    .me-md-5 {
        margin-right: 3rem !important
    }

    .me-md-auto {
        margin-right: auto !important
    }

    .mb-md-0 {
        margin-bottom: 0 !important
    }

    .mb-md-1 {
        margin-bottom: .25rem !important
    }

    .mb-md-2 {
        margin-bottom: .5rem !important
    }

    .mb-md-3 {
        margin-bottom: 1rem !important
    }

    .mb-md-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-md-5 {
        margin-bottom: 3rem !important
    }

    .mb-md-auto {
        margin-bottom: auto !important
    }

    .ms-md-0 {
        margin-left: 0 !important
    }

    .ms-md-1 {
        margin-left: .25rem !important
    }

    .ms-md-2 {
        margin-left: .5rem !important
    }

    .ms-md-3 {
        margin-left: 1rem !important
    }

    .ms-md-4 {
        margin-left: 1.5rem !important
    }

    .ms-md-5 {
        margin-left: 3rem !important
    }

    .ms-md-auto {
        margin-left: auto !important
    }

    .p-md-0 {
        padding: 0 !important
    }

    .p-md-1 {
        padding: .25rem !important
    }

    .p-md-2 {
        padding: .5rem !important
    }

    .p-md-3 {
        padding: 1rem !important
    }

    .p-md-4 {
        padding: 1.5rem !important
    }

    .p-md-5 {
        padding: 3rem !important
    }

    .px-md-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-md-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-md-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-md-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-md-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-md-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-md-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-md-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-md-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-md-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-md-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-md-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .pt-md-0 {
        padding-top: 0 !important
    }

    .pt-md-1 {
        padding-top: .25rem !important
    }

    .pt-md-2 {
        padding-top: .5rem !important
    }

    .pt-md-3 {
        padding-top: 1rem !important
    }

    .pt-md-4 {
        padding-top: 1.5rem !important
    }

    .pt-md-5 {
        padding-top: 3rem !important
    }

    .pe-md-0 {
        padding-right: 0 !important
    }

    .pe-md-1 {
        padding-right: .25rem !important
    }

    .pe-md-2 {
        padding-right: .5rem !important
    }

    .pe-md-3 {
        padding-right: 1rem !important
    }

    .pe-md-4 {
        padding-right: 1.5rem !important
    }

    .pe-md-5 {
        padding-right: 3rem !important
    }

    .pb-md-0 {
        padding-bottom: 0 !important
    }

    .pb-md-1 {
        padding-bottom: .25rem !important
    }

    .pb-md-2 {
        padding-bottom: .5rem !important
    }

    .pb-md-3 {
        padding-bottom: 1rem !important
    }

    .pb-md-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-md-5 {
        padding-bottom: 3rem !important
    }

    .ps-md-0 {
        padding-left: 0 !important
    }

    .ps-md-1 {
        padding-left: .25rem !important
    }

    .ps-md-2 {
        padding-left: .5rem !important
    }

    .ps-md-3 {
        padding-left: 1rem !important
    }

    .ps-md-4 {
        padding-left: 1.5rem !important
    }

    .ps-md-5 {
        padding-left: 3rem !important
    }

    .text-md-start {
        text-align: left !important
    }

    .text-md-end {
        text-align: right !important
    }

    .text-md-center {
        text-align: center !important
    }
}

@media (min-width:992px) {
    .float-lg-start {
        float: left !important
    }

    .float-lg-end {
        float: right !important
    }

    .float-lg-none {
        float: none !important
    }

    .d-lg-inline {
        display: inline !important
    }

    .d-lg-inline-block {
        display: inline-block !important
    }

    .d-lg-block {
        display: block !important
    }

    .d-lg-grid {
        display: grid !important
    }

    .d-lg-table {
        display: table !important
    }

    .d-lg-table-row {
        display: table-row !important
    }

    .d-lg-table-cell {
        display: table-cell !important
    }

    .d-lg-flex {
        display: flex !important
    }

    .d-lg-inline-flex {
        display: inline-flex !important
    }

    .d-lg-none {
        display: none !important
    }

    .flex-lg-fill {
        flex: 1 1 auto !important
    }

    .flex-lg-row {
        flex-direction: row !important
    }

    .flex-lg-column {
        flex-direction: column !important
    }

    .flex-lg-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-lg-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-lg-grow-0 {
        flex-grow: 0 !important
    }

    .flex-lg-grow-1 {
        flex-grow: 1 !important
    }

    .flex-lg-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-lg-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-lg-wrap {
        flex-wrap: wrap !important
    }

    .flex-lg-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .gap-lg-0 {
        gap: 0 !important
    }

    .gap-lg-1 {
        gap: .25rem !important
    }

    .gap-lg-2 {
        gap: .5rem !important
    }

    .gap-lg-3 {
        gap: 1rem !important
    }

    .gap-lg-4 {
        gap: 1.5rem !important
    }

    .gap-lg-5 {
        gap: 3rem !important
    }

    .justify-content-lg-start {
        justify-content: flex-start !important
    }

    .justify-content-lg-end {
        justify-content: flex-end !important
    }

    .justify-content-lg-center {
        justify-content: center !important
    }

    .justify-content-lg-between {
        justify-content: space-between !important
    }

    .justify-content-lg-around {
        justify-content: space-around !important
    }

    .justify-content-lg-evenly {
        justify-content: space-evenly !important
    }

    .align-items-lg-start {
        align-items: flex-start !important
    }

    .align-items-lg-end {
        align-items: flex-end !important
    }

    .align-items-lg-center {
        align-items: center !important
    }

    .align-items-lg-baseline {
        align-items: baseline !important
    }

    .align-items-lg-stretch {
        align-items: stretch !important
    }

    .align-content-lg-start {
        align-content: flex-start !important
    }

    .align-content-lg-end {
        align-content: flex-end !important
    }

    .align-content-lg-center {
        align-content: center !important
    }

    .align-content-lg-between {
        align-content: space-between !important
    }

    .align-content-lg-around {
        align-content: space-around !important
    }

    .align-content-lg-stretch {
        align-content: stretch !important
    }

    .align-self-lg-auto {
        align-self: auto !important
    }

    .align-self-lg-start {
        align-self: flex-start !important
    }

    .align-self-lg-end {
        align-self: flex-end !important
    }

    .align-self-lg-center {
        align-self: center !important
    }

    .align-self-lg-baseline {
        align-self: baseline !important
    }

    .align-self-lg-stretch {
        align-self: stretch !important
    }

    .order-lg-first {
        order: -1 !important
    }

    .order-lg-0 {
        order: 0 !important
    }

    .order-lg-1 {
        order: 1 !important
    }

    .order-lg-2 {
        order: 2 !important
    }

    .order-lg-3 {
        order: 3 !important
    }

    .order-lg-4 {
        order: 4 !important
    }

    .order-lg-5 {
        order: 5 !important
    }

    .order-lg-last {
        order: 6 !important
    }

    .m-lg-0 {
        margin: 0 !important
    }

    .m-lg-1 {
        margin: .25rem !important
    }

    .m-lg-2 {
        margin: .5rem !important
    }

    .m-lg-3 {
        margin: 1rem !important
    }

    .m-lg-4 {
        margin: 1.5rem !important
    }

    .m-lg-5 {
        margin: 3rem !important
    }

    .m-lg-auto {
        margin: auto !important
    }

    .mx-lg-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-lg-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-lg-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-lg-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-lg-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-lg-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-lg-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-lg-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-lg-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-lg-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-lg-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-lg-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-lg-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-lg-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-lg-0 {
        margin-top: 0 !important
    }

    .mt-lg-1 {
        margin-top: .25rem !important
    }

    .mt-lg-2 {
        margin-top: .5rem !important
    }

    .mt-lg-3 {
        margin-top: 1rem !important
    }

    .mt-lg-4 {
        margin-top: 1.5rem !important
    }

    .mt-lg-5 {
        margin-top: 3rem !important
    }

    .mt-lg-auto {
        margin-top: auto !important
    }

    .me-lg-0 {
        margin-right: 0 !important
    }

    .me-lg-1 {
        margin-right: .25rem !important
    }

    .me-lg-2 {
        margin-right: .5rem !important
    }

    .me-lg-3 {
        margin-right: 1rem !important
    }

    .me-lg-4 {
        margin-right: 1.5rem !important
    }

    .me-lg-5 {
        margin-right: 3rem !important
    }

    .me-lg-auto {
        margin-right: auto !important
    }

    .mb-lg-0 {
        margin-bottom: 0 !important
    }

    .mb-lg-1 {
        margin-bottom: .25rem !important
    }

    .mb-lg-2 {
        margin-bottom: .5rem !important
    }

    .mb-lg-3 {
        margin-bottom: 1rem !important
    }

    .mb-lg-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-lg-5 {
        margin-bottom: 3rem !important
    }

    .mb-lg-auto {
        margin-bottom: auto !important
    }

    .ms-lg-0 {
        margin-left: 0 !important
    }

    .ms-lg-1 {
        margin-left: .25rem !important
    }

    .ms-lg-2 {
        margin-left: .5rem !important
    }

    .ms-lg-3 {
        margin-left: 1rem !important
    }

    .ms-lg-4 {
        margin-left: 1.5rem !important
    }

    .ms-lg-5 {
        margin-left: 3rem !important
    }

    .ms-lg-auto {
        margin-left: auto !important
    }

    .p-lg-0 {
        padding: 0 !important
    }

    .p-lg-1 {
        padding: .25rem !important
    }

    .p-lg-2 {
        padding: .5rem !important
    }

    .p-lg-3 {
        padding: 1rem !important
    }

    .p-lg-4 {
        padding: 1.5rem !important
    }

    .p-lg-5 {
        padding: 3rem !important
    }

    .px-lg-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-lg-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-lg-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-lg-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-lg-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-lg-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-lg-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-lg-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-lg-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-lg-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-lg-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-lg-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .pt-lg-0 {
        padding-top: 0 !important
    }

    .pt-lg-1 {
        padding-top: .25rem !important
    }

    .pt-lg-2 {
        padding-top: .5rem !important
    }

    .pt-lg-3 {
        padding-top: 1rem !important
    }

    .pt-lg-4 {
        padding-top: 1.5rem !important
    }

    .pt-lg-5 {
        padding-top: 3rem !important
    }

    .pe-lg-0 {
        padding-right: 0 !important
    }

    .pe-lg-1 {
        padding-right: .25rem !important
    }

    .pe-lg-2 {
        padding-right: .5rem !important
    }

    .pe-lg-3 {
        padding-right: 1rem !important
    }

    .pe-lg-4 {
        padding-right: 1.5rem !important
    }

    .pe-lg-5 {
        padding-right: 3rem !important
    }

    .pb-lg-0 {
        padding-bottom: 0 !important
    }

    .pb-lg-1 {
        padding-bottom: .25rem !important
    }

    .pb-lg-2 {
        padding-bottom: .5rem !important
    }

    .pb-lg-3 {
        padding-bottom: 1rem !important
    }

    .pb-lg-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-lg-5 {
        padding-bottom: 3rem !important
    }

    .ps-lg-0 {
        padding-left: 0 !important
    }

    .ps-lg-1 {
        padding-left: .25rem !important
    }

    .ps-lg-2 {
        padding-left: .5rem !important
    }

    .ps-lg-3 {
        padding-left: 1rem !important
    }

    .ps-lg-4 {
        padding-left: 1.5rem !important
    }

    .ps-lg-5 {
        padding-left: 3rem !important
    }

    .text-lg-start {
        text-align: left !important
    }

    .text-lg-end {
        text-align: right !important
    }

    .text-lg-center {
        text-align: center !important
    }
}

@media (min-width:1200px) {
    .float-xl-start {
        float: left !important
    }

    .float-xl-end {
        float: right !important
    }

    .float-xl-none {
        float: none !important
    }

    .d-xl-inline {
        display: inline !important
    }

    .d-xl-inline-block {
        display: inline-block !important
    }

    .d-xl-block {
        display: block !important
    }

    .d-xl-grid {
        display: grid !important
    }

    .d-xl-table {
        display: table !important
    }

    .d-xl-table-row {
        display: table-row !important
    }

    .d-xl-table-cell {
        display: table-cell !important
    }

    .d-xl-flex {
        display: flex !important
    }

    .d-xl-inline-flex {
        display: inline-flex !important
    }

    .d-xl-none {
        display: none !important
    }

    .flex-xl-fill {
        flex: 1 1 auto !important
    }

    .flex-xl-row {
        flex-direction: row !important
    }

    .flex-xl-column {
        flex-direction: column !important
    }

    .flex-xl-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-xl-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-xl-grow-0 {
        flex-grow: 0 !important
    }

    .flex-xl-grow-1 {
        flex-grow: 1 !important
    }

    .flex-xl-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-xl-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-xl-wrap {
        flex-wrap: wrap !important
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .gap-xl-0 {
        gap: 0 !important
    }

    .gap-xl-1 {
        gap: .25rem !important
    }

    .gap-xl-2 {
        gap: .5rem !important
    }

    .gap-xl-3 {
        gap: 1rem !important
    }

    .gap-xl-4 {
        gap: 1.5rem !important
    }

    .gap-xl-5 {
        gap: 3rem !important
    }

    .justify-content-xl-start {
        justify-content: flex-start !important
    }

    .justify-content-xl-end {
        justify-content: flex-end !important
    }

    .justify-content-xl-center {
        justify-content: center !important
    }

    .justify-content-xl-between {
        justify-content: space-between !important
    }

    .justify-content-xl-around {
        justify-content: space-around !important
    }

    .justify-content-xl-evenly {
        justify-content: space-evenly !important
    }

    .align-items-xl-start {
        align-items: flex-start !important
    }

    .align-items-xl-end {
        align-items: flex-end !important
    }

    .align-items-xl-center {
        align-items: center !important
    }

    .align-items-xl-baseline {
        align-items: baseline !important
    }

    .align-items-xl-stretch {
        align-items: stretch !important
    }

    .align-content-xl-start {
        align-content: flex-start !important
    }

    .align-content-xl-end {
        align-content: flex-end !important
    }

    .align-content-xl-center {
        align-content: center !important
    }

    .align-content-xl-between {
        align-content: space-between !important
    }

    .align-content-xl-around {
        align-content: space-around !important
    }

    .align-content-xl-stretch {
        align-content: stretch !important
    }

    .align-self-xl-auto {
        align-self: auto !important
    }

    .align-self-xl-start {
        align-self: flex-start !important
    }

    .align-self-xl-end {
        align-self: flex-end !important
    }

    .align-self-xl-center {
        align-self: center !important
    }

    .align-self-xl-baseline {
        align-self: baseline !important
    }

    .align-self-xl-stretch {
        align-self: stretch !important
    }

    .order-xl-first {
        order: -1 !important
    }

    .order-xl-0 {
        order: 0 !important
    }

    .order-xl-1 {
        order: 1 !important
    }

    .order-xl-2 {
        order: 2 !important
    }

    .order-xl-3 {
        order: 3 !important
    }

    .order-xl-4 {
        order: 4 !important
    }

    .order-xl-5 {
        order: 5 !important
    }

    .order-xl-last {
        order: 6 !important
    }

    .m-xl-0 {
        margin: 0 !important
    }

    .m-xl-1 {
        margin: .25rem !important
    }

    .m-xl-2 {
        margin: .5rem !important
    }

    .m-xl-3 {
        margin: 1rem !important
    }

    .m-xl-4 {
        margin: 1.5rem !important
    }

    .m-xl-5 {
        margin: 3rem !important
    }

    .m-xl-auto {
        margin: auto !important
    }

    .mx-xl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-xl-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-xl-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-xl-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-xl-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-xl-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-xl-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-xl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-xl-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-xl-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-xl-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-xl-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-xl-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-xl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-xl-0 {
        margin-top: 0 !important
    }

    .mt-xl-1 {
        margin-top: .25rem !important
    }

    .mt-xl-2 {
        margin-top: .5rem !important
    }

    .mt-xl-3 {
        margin-top: 1rem !important
    }

    .mt-xl-4 {
        margin-top: 1.5rem !important
    }

    .mt-xl-5 {
        margin-top: 3rem !important
    }

    .mt-xl-auto {
        margin-top: auto !important
    }

    .me-xl-0 {
        margin-right: 0 !important
    }

    .me-xl-1 {
        margin-right: .25rem !important
    }

    .me-xl-2 {
        margin-right: .5rem !important
    }

    .me-xl-3 {
        margin-right: 1rem !important
    }

    .me-xl-4 {
        margin-right: 1.5rem !important
    }

    .me-xl-5 {
        margin-right: 3rem !important
    }

    .me-xl-auto {
        margin-right: auto !important
    }

    .mb-xl-0 {
        margin-bottom: 0 !important
    }

    .mb-xl-1 {
        margin-bottom: .25rem !important
    }

    .mb-xl-2 {
        margin-bottom: .5rem !important
    }

    .mb-xl-3 {
        margin-bottom: 1rem !important
    }

    .mb-xl-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-xl-5 {
        margin-bottom: 3rem !important
    }

    .mb-xl-auto {
        margin-bottom: auto !important
    }

    .ms-xl-0 {
        margin-left: 0 !important
    }

    .ms-xl-1 {
        margin-left: .25rem !important
    }

    .ms-xl-2 {
        margin-left: .5rem !important
    }

    .ms-xl-3 {
        margin-left: 1rem !important
    }

    .ms-xl-4 {
        margin-left: 1.5rem !important
    }

    .ms-xl-5 {
        margin-left: 3rem !important
    }

    .ms-xl-auto {
        margin-left: auto !important
    }

    .p-xl-0 {
        padding: 0 !important
    }

    .p-xl-1 {
        padding: .25rem !important
    }

    .p-xl-2 {
        padding: .5rem !important
    }

    .p-xl-3 {
        padding: 1rem !important
    }

    .p-xl-4 {
        padding: 1.5rem !important
    }

    .p-xl-5 {
        padding: 3rem !important
    }

    .px-xl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-xl-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-xl-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-xl-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-xl-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-xl-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-xl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-xl-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-xl-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-xl-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-xl-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-xl-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .pt-xl-0 {
        padding-top: 0 !important
    }

    .pt-xl-1 {
        padding-top: .25rem !important
    }

    .pt-xl-2 {
        padding-top: .5rem !important
    }

    .pt-xl-3 {
        padding-top: 1rem !important
    }

    .pt-xl-4 {
        padding-top: 1.5rem !important
    }

    .pt-xl-5 {
        padding-top: 3rem !important
    }

    .pe-xl-0 {
        padding-right: 0 !important
    }

    .pe-xl-1 {
        padding-right: .25rem !important
    }

    .pe-xl-2 {
        padding-right: .5rem !important
    }

    .pe-xl-3 {
        padding-right: 1rem !important
    }

    .pe-xl-4 {
        padding-right: 1.5rem !important
    }

    .pe-xl-5 {
        padding-right: 3rem !important
    }

    .pb-xl-0 {
        padding-bottom: 0 !important
    }

    .pb-xl-1 {
        padding-bottom: .25rem !important
    }

    .pb-xl-2 {
        padding-bottom: .5rem !important
    }

    .pb-xl-3 {
        padding-bottom: 1rem !important
    }

    .pb-xl-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-xl-5 {
        padding-bottom: 3rem !important
    }

    .ps-xl-0 {
        padding-left: 0 !important
    }

    .ps-xl-1 {
        padding-left: .25rem !important
    }

    .ps-xl-2 {
        padding-left: .5rem !important
    }

    .ps-xl-3 {
        padding-left: 1rem !important
    }

    .ps-xl-4 {
        padding-left: 1.5rem !important
    }

    .ps-xl-5 {
        padding-left: 3rem !important
    }

    .text-xl-start {
        text-align: left !important
    }

    .text-xl-end {
        text-align: right !important
    }

    .text-xl-center {
        text-align: center !important
    }
}

@media (min-width:1400px) {
    .float-xxl-start {
        float: left !important
    }

    .float-xxl-end {
        float: right !important
    }

    .float-xxl-none {
        float: none !important
    }

    .d-xxl-inline {
        display: inline !important
    }

    .d-xxl-inline-block {
        display: inline-block !important
    }

    .d-xxl-block {
        display: block !important
    }

    .d-xxl-grid {
        display: grid !important
    }

    .d-xxl-table {
        display: table !important
    }

    .d-xxl-table-row {
        display: table-row !important
    }

    .d-xxl-table-cell {
        display: table-cell !important
    }

    .d-xxl-flex {
        display: flex !important
    }

    .d-xxl-inline-flex {
        display: inline-flex !important
    }

    .d-xxl-none {
        display: none !important
    }

    .flex-xxl-fill {
        flex: 1 1 auto !important
    }

    .flex-xxl-row {
        flex-direction: row !important
    }

    .flex-xxl-column {
        flex-direction: column !important
    }

    .flex-xxl-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-xxl-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-xxl-grow-0 {
        flex-grow: 0 !important
    }

    .flex-xxl-grow-1 {
        flex-grow: 1 !important
    }

    .flex-xxl-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-xxl-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-xxl-wrap {
        flex-wrap: wrap !important
    }

    .flex-xxl-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-xxl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .gap-xxl-0 {
        gap: 0 !important
    }

    .gap-xxl-1 {
        gap: .25rem !important
    }

    .gap-xxl-2 {
        gap: .5rem !important
    }

    .gap-xxl-3 {
        gap: 1rem !important
    }

    .gap-xxl-4 {
        gap: 1.5rem !important
    }

    .gap-xxl-5 {
        gap: 3rem !important
    }

    .justify-content-xxl-start {
        justify-content: flex-start !important
    }

    .justify-content-xxl-end {
        justify-content: flex-end !important
    }

    .justify-content-xxl-center {
        justify-content: center !important
    }

    .justify-content-xxl-between {
        justify-content: space-between !important
    }

    .justify-content-xxl-around {
        justify-content: space-around !important
    }

    .justify-content-xxl-evenly {
        justify-content: space-evenly !important
    }

    .align-items-xxl-start {
        align-items: flex-start !important
    }

    .align-items-xxl-end {
        align-items: flex-end !important
    }

    .align-items-xxl-center {
        align-items: center !important
    }

    .align-items-xxl-baseline {
        align-items: baseline !important
    }

    .align-items-xxl-stretch {
        align-items: stretch !important
    }

    .align-content-xxl-start {
        align-content: flex-start !important
    }

    .align-content-xxl-end {
        align-content: flex-end !important
    }

    .align-content-xxl-center {
        align-content: center !important
    }

    .align-content-xxl-between {
        align-content: space-between !important
    }

    .align-content-xxl-around {
        align-content: space-around !important
    }

    .align-content-xxl-stretch {
        align-content: stretch !important
    }

    .align-self-xxl-auto {
        align-self: auto !important
    }

    .align-self-xxl-start {
        align-self: flex-start !important
    }

    .align-self-xxl-end {
        align-self: flex-end !important
    }

    .align-self-xxl-center {
        align-self: center !important
    }

    .align-self-xxl-baseline {
        align-self: baseline !important
    }

    .align-self-xxl-stretch {
        align-self: stretch !important
    }

    .order-xxl-first {
        order: -1 !important
    }

    .order-xxl-0 {
        order: 0 !important
    }

    .order-xxl-1 {
        order: 1 !important
    }

    .order-xxl-2 {
        order: 2 !important
    }

    .order-xxl-3 {
        order: 3 !important
    }

    .order-xxl-4 {
        order: 4 !important
    }

    .order-xxl-5 {
        order: 5 !important
    }

    .order-xxl-last {
        order: 6 !important
    }

    .m-xxl-0 {
        margin: 0 !important
    }

    .m-xxl-1 {
        margin: .25rem !important
    }

    .m-xxl-2 {
        margin: .5rem !important
    }

    .m-xxl-3 {
        margin: 1rem !important
    }

    .m-xxl-4 {
        margin: 1.5rem !important
    }

    .m-xxl-5 {
        margin: 3rem !important
    }

    .m-xxl-auto {
        margin: auto !important
    }

    .mx-xxl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-xxl-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-xxl-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-xxl-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-xxl-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-xxl-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-xxl-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-xxl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-xxl-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-xxl-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-xxl-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-xxl-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-xxl-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-xxl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-xxl-0 {
        margin-top: 0 !important
    }

    .mt-xxl-1 {
        margin-top: .25rem !important
    }

    .mt-xxl-2 {
        margin-top: .5rem !important
    }

    .mt-xxl-3 {
        margin-top: 1rem !important
    }

    .mt-xxl-4 {
        margin-top: 1.5rem !important
    }

    .mt-xxl-5 {
        margin-top: 3rem !important
    }

    .mt-xxl-auto {
        margin-top: auto !important
    }

    .me-xxl-0 {
        margin-right: 0 !important
    }

    .me-xxl-1 {
        margin-right: .25rem !important
    }

    .me-xxl-2 {
        margin-right: .5rem !important
    }

    .me-xxl-3 {
        margin-right: 1rem !important
    }

    .me-xxl-4 {
        margin-right: 1.5rem !important
    }

    .me-xxl-5 {
        margin-right: 3rem !important
    }

    .me-xxl-auto {
        margin-right: auto !important
    }

    .mb-xxl-0 {
        margin-bottom: 0 !important
    }

    .mb-xxl-1 {
        margin-bottom: .25rem !important
    }

    .mb-xxl-2 {
        margin-bottom: .5rem !important
    }

    .mb-xxl-3 {
        margin-bottom: 1rem !important
    }

    .mb-xxl-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-xxl-5 {
        margin-bottom: 3rem !important
    }

    .mb-xxl-auto {
        margin-bottom: auto !important
    }

    .ms-xxl-0 {
        margin-left: 0 !important
    }

    .ms-xxl-1 {
        margin-left: .25rem !important
    }

    .ms-xxl-2 {
        margin-left: .5rem !important
    }

    .ms-xxl-3 {
        margin-left: 1rem !important
    }

    .ms-xxl-4 {
        margin-left: 1.5rem !important
    }

    .ms-xxl-5 {
        margin-left: 3rem !important
    }

    .ms-xxl-auto {
        margin-left: auto !important
    }

    .p-xxl-0 {
        padding: 0 !important
    }

    .p-xxl-1 {
        padding: .25rem !important
    }

    .p-xxl-2 {
        padding: .5rem !important
    }

    .p-xxl-3 {
        padding: 1rem !important
    }

    .p-xxl-4 {
        padding: 1.5rem !important
    }

    .p-xxl-5 {
        padding: 3rem !important
    }

    .px-xxl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-xxl-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-xxl-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-xxl-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-xxl-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-xxl-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-xxl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-xxl-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-xxl-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-xxl-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-xxl-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-xxl-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .pt-xxl-0 {
        padding-top: 0 !important
    }

    .pt-xxl-1 {
        padding-top: .25rem !important
    }

    .pt-xxl-2 {
        padding-top: .5rem !important
    }

    .pt-xxl-3 {
        padding-top: 1rem !important
    }

    .pt-xxl-4 {
        padding-top: 1.5rem !important
    }

    .pt-xxl-5 {
        padding-top: 3rem !important
    }

    .pe-xxl-0 {
        padding-right: 0 !important
    }

    .pe-xxl-1 {
        padding-right: .25rem !important
    }

    .pe-xxl-2 {
        padding-right: .5rem !important
    }

    .pe-xxl-3 {
        padding-right: 1rem !important
    }

    .pe-xxl-4 {
        padding-right: 1.5rem !important
    }

    .pe-xxl-5 {
        padding-right: 3rem !important
    }

    .pb-xxl-0 {
        padding-bottom: 0 !important
    }

    .pb-xxl-1 {
        padding-bottom: .25rem !important
    }

    .pb-xxl-2 {
        padding-bottom: .5rem !important
    }

    .pb-xxl-3 {
        padding-bottom: 1rem !important
    }

    .pb-xxl-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-xxl-5 {
        padding-bottom: 3rem !important
    }

    .ps-xxl-0 {
        padding-left: 0 !important
    }

    .ps-xxl-1 {
        padding-left: .25rem !important
    }

    .ps-xxl-2 {
        padding-left: .5rem !important
    }

    .ps-xxl-3 {
        padding-left: 1rem !important
    }

    .ps-xxl-4 {
        padding-left: 1.5rem !important
    }

    .ps-xxl-5 {
        padding-left: 3rem !important
    }

    .text-xxl-start {
        text-align: left !important
    }

    .text-xxl-end {
        text-align: right !important
    }

    .text-xxl-center {
        text-align: center !important
    }
}

@media (min-width:1200px) {
    .fs-1 {
        font-size: 2.5rem !important
    }

    .fs-2 {
        font-size: 2rem !important
    }

    .fs-3 {
        font-size: 1.75rem !important
    }

    .fs-4 {
        font-size: 1.5rem !important
    }
}

@media print {
    .d-print-inline {
        display: inline !important
    }

    .d-print-inline-block {
        display: inline-block !important
    }

    .d-print-block {
        display: block !important
    }

    .d-print-grid {
        display: grid !important
    }

    .d-print-table {
        display: table !important
    }

    .d-print-table-row {
        display: table-row !important
    }

    .d-print-table-cell {
        display: table-cell !important
    }

    .d-print-flex {
        display: flex !important
    }

    .d-print-inline-flex {
        display: inline-flex !important
    }

    .d-print-none {
        display: none !important
    }
}

/*# sourceMappingURL=bootstrap.min.css.map */

html.dark-sidebar .sidebar-wrapper {
    background: #16191c;
    border-right: 1px solid #e4e4e400;
}

html.dark-sidebar .sidebar-wrapper .sidebar-header {
    background: #16191c;
    border-right: 1px solid #e4e4e400;
    border-bottom: 1px solid rgb(255 255 255 / 15%);
}

html.dark-sidebar .sidebar-wrapper .sidebar-header .logo-text {
    color: #e7e9ec;
}

html.dark-sidebar .sidebar-wrapper .sidebar-header .toggle-btn {
    color: #e7e9ec;
}

html.dark-sidebar .sidebar-wrapper .metismenu a {
    color: #9ea4aa;
}

html.dark-sidebar .sidebar-wrapper .metismenu a:hover,
html.dark-sidebar .sidebar-wrapper .metismenu a:focus,
html.dark-sidebar .sidebar-wrapper .metismenu a:active,
html.dark-sidebar .sidebar-wrapper .metismenu .mm-active>a {
    color: #fff;
    text-decoration: none;
    background: rgb(255 255 255 / 18%);
}

html.dark-sidebar .sidebar-wrapper .metismenu ul {
    border: 1px solid #ffffff00;
    background: rgb(255 255 255 / 8%);
}

html.dark-sidebar .simplebar-scrollbar:before {
    background: rgba(255, 255, 255, 0.40);
}

html.ColorLessIcons .sidebar-wrapper .metismenu .icon-color-1,
html.ColorLessIcons .sidebar-wrapper .metismenu .icon-color-2,
html.ColorLessIcons .sidebar-wrapper .metismenu .icon-color-3,
html.ColorLessIcons .sidebar-wrapper .metismenu .icon-color-4,
html.ColorLessIcons .sidebar-wrapper .metismenu .icon-color-5,
html.ColorLessIcons .sidebar-wrapper .metismenu .icon-color-6,
html.ColorLessIcons .sidebar-wrapper .metismenu .icon-color-7,
html.ColorLessIcons .sidebar-wrapper .metismenu .icon-color-8,
html.ColorLessIcons .sidebar-wrapper .metismenu .icon-color-9,
html.ColorLessIcons .sidebar-wrapper .metismenu .icon-color-10,
html.ColorLessIcons .sidebar-wrapper .metismenu .icon-color-11,
html.ColorLessIcons .sidebar-wrapper .metismenu .icon-color-12 {
    color: inherit;
}

/*Dark Theme*/

html.dark-theme .switcher-wrapper {
    background: #16191c;
}

html.dark-theme body {
    color: #ccced1;
    background-color: #16191c;
}

html.dark-theme .card {
    background-color: #1a1e21;
    box-shadow: none;
    border: 1px solid rgb(255 255 255 / 10%);
}

html.dark-theme .card-header {
    border-bottom: 1px solid rgb(255 255 255 / 13%);
}

html.dark-theme .card-footer {
    border-top: 1px solid rgb(255 255 255 / 13%);
}

html.dark-theme .text-secondary {
    color: #868f96 !important;
}

html.dark-theme hr {
    border-top: 1px solid rgb(255 255 255 / 10%);
}

html.dark-theme .top-header {
    background-color: #1a1e21;
    border-bottom: 1px solid rgb(237 237 237 / 12%);
}

html.dark-theme .toggle-btn {
    color: #ccced1;
}

html.dark-theme .right-topbar .navbar-nav .nav-link {
    border-left: 1px solid rgb(255 255 255 / 12%);
    color: #ccced1;
}

html.dark-theme .search-bar input {
    color: #ccced1;
    background-color: #16191c;
    border: 1px solid rgb(241 241 241 / 15%);
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 0%);
}

html.dark-theme .search-bar input::placeholder {
    color: #ccced1 !important;
    opacity: .5 !important;
    /* Firefox */
}

html.dark-theme::placeholder {
    color: #ccced1 !important;
    opacity: .5 !important;
    /* Firefox */
}

html.dark-theme .search-bar .btn-search {
    background: #16191c;
    border: 1px solid rgb(217 211 211 / 30%);
}

html.dark-theme .search-bar .btn-search i {
    color: #ccced1;
}

html.dark-theme .dropdown-menu {
    color: #ccced1;
    background-color: #16191c;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 0.30);
    border: 1px solid rgb(255 255 255 / 12%);
}

html.dark-theme .btn-close {
    filter: invert(1) grayscale(100%) brightness(200%);
}

html.dark-theme .switcher-body .offcanvas {
    color: #acafb3;
    background-color: #16191c;
}

html.dark-theme .dropdown-item {
    color: #acafb3;
}

html.dark-theme .dropdown-menu .dropdown-item:hover {
    background-color: #1a1e21;
}

html.dark-theme .dropdown-divider {
    border-top: 1px solid rgb(255 255 255 / 12%);
}

html.dark-theme .dropdown-lg .dropdown-menu .dropdown-item {
    border-bottom: 1px solid rgb(255 255 255 / 12%);
}

html.dark-theme .dropdown-lg .msg-name {
    color: #fff;
}

html.dark-theme .dropdown-lg .msg-info {
    color: #80868c;
}

html.dark-theme .dropdown-lg .msg-time {
    color: #b1afaf;
}

html.dark-theme .dropdown-lg .msg-footer {
    color: #bcc9d8;
    border-top: 1px solid rgb(255 255 255 / 12%);
}

html.dark-theme .btn-white {
    color: #ccced1;
    background-color: #16191c;
    border-color: rgb(206 212 218 / 45%);
    border-top: 1px solid #63686f;
    border-left: 1px solid #63686f;
}

html.dark-theme .user-img {
    border: 1px solid rgb(255 255 255 / 28%);
}

html.dark-theme .table {
    --bs-table-bg: transparent;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: #ffffff;
    --bs-table-striped-bg: rgba(255, 255, 255, 0.05);
    --bs-table-active-color: #ffffff;
    --bs-table-active-bg: rgb(255 255 255 / 10%);
    --bs-table-hover-color: #ffffff;
    --bs-table-hover-bg: rgb(255 255 255 / 8%);
    width: 100%;
    margin-bottom: 1rem;
    color: #fcfcfc;
    vertical-align: top;
    border-color: rgb(255 255 255 / 12%);
}

html.dark-theme .border {
    border: 1px solid rgb(255 255 255 / 12%) !important;
}

html.dark-theme .top-header .navbar .dropdown-menu::after {
    background: #16191c;
    border-top: 1px solid #5c636b;
    border-left: 1px solid #5c636b;
}

html.dark-theme .top-header .navbar .dropdown-lg .dropdown-menu::after {
    background: #673ab7;
    border-top: 1px solid #673ab7;
    border-left: 1px solid #673ab7;
}

html.dark-theme .highcharts-background {
    fill: rgb(255 255 255 / 0%);
}

html.dark-theme .highcharts-title {
    fill: #ccced1 !important;
    font-size: 1.5em;
}

html.dark-theme .highcharts-root text {
    fill: #8f949a;
}

html.dark-theme .highcharts-grid-line {
    fill: none;
    stroke: rgb(255 255 255 / 12%);
}

html.dark-theme .sidebar-wrapper {
    background: #1a1e21;
    border-right: 1px solid rgb(255 255 255 / 12%)
}

html.dark-theme .sidebar-wrapper .sidebar-header {
    background: #1a1e21;
    border-right: 1px solid rgb(255 255 255 / 12%);
    border-bottom: 1px solid rgb(255 255 255 / 12%);
}

html.dark-theme .sidebar-wrapper .sidebar-header .logo-text {
    color: #e7e9ec;
}

html.dark-theme .sidebar-wrapper .sidebar-header .toggle-btn {
    color: #e7e9ec;
}

html.dark-theme .sidebar-wrapper .sidebar-header .toggle-btn:hover {
    color: #fff;
}

html.dark-theme .sidebar-wrapper .sidebar-header .toggle-btn:active {
    color: #fff;
    background: rgb(255 255 255 / 25%);
}

html.dark-theme .sidebar-wrapper .metismenu a {
    color: #9ea4aa;
}

html.dark-theme .sidebar-wrapper .metismenu a:hover,
html.dark-theme .sidebar-wrapper .metismenu a:focus,
html.dark-theme .sidebar-wrapper .metismenu a:active,
html.dark-theme .sidebar-wrapper .metismenu .mm-active>a {
    color: #fff;
    text-decoration: none;
    background: rgb(255 255 255 / 18%);
}

html.dark-theme .sidebar-wrapper .metismenu ul {
    border: 1px solid #ffffff00;
    background: rgb(255 255 255 / 8%);
}

html.dark-theme .simplebar-scrollbar:before {
    background: rgba(255, 255, 255, 0.40);
}

html.dark-theme .footer {
    background: #16191c;
    border-top: 1px solid rgb(255 255 255 / 12%);
}

html.dark-theme .btn-light-warning {
    color: #ff9305;
}

html.dark-theme .btn-light-dark {
    color: #757c83;
}

html.dark-theme .modal-content {
    background-color: #1a1e21;
}

html.dark-theme .modal-header {
    border-bottom: 1px solid rgb(222 226 230 / 16%);
}

html.dark-theme .modal-footer {
    border-top: 1px solid rgb(222 226 230 / 16%);
}

/*email box*/

html.dark-theme .email-wrapper {
    background: #1a1e21;
    box-shadow: 0 0.1rem 0.7rem rgb(0 0 0 / 24%);
}

html.dark-theme .email-sidebar {
    background: #1a1e21;
    border-right: 1px solid rgb(255 255 255 / 0.12);
}

html.dark-theme .email-sidebar-header {
    background: #1a1e21;
    border-bottom: 1px solid rgb(255 255 255 / 0.12);
    border-right: 0px solid rgb(255 255 255 / 12%);
}

html.dark-theme .email-navigation {
    border-bottom: 1px solid rgb(255 255 255 / 13%);
}

html.dark-theme .email-navigation a.list-group-item {
    color: #ccced1;
    background-color: #1a1e21;
    border-bottom: 1px solid rgb(0 0 0 / 0%);
}

html.dark-theme .email-navigation a.list-group-item:hover {
    background-color: rgb(0 123 255 / 15%);
}

html.dark-theme .email-meeting a.list-group-item {
    color: #ccced1;
    background-color: #1a1e21;
    border-bottom: 1px solid rgb(0 0 0 / 0%);
}

html.dark-theme .email-meeting a.list-group-item:hover {
    background-color: rgb(0 123 255 / 15%);
}

html.dark-theme .list-group-item {
    color: #e6e7e8;
    background-color: #1a1e21;
    border: 1px solid rgb(255 255 255 / 13%);
}

html.dark-theme .email-navigation a.list-group-item.active {
    color: #673AB7;
    font-weight: 600;
    background-color: rgb(103 58 183 / 21%);
}

html.dark-theme .list-group-flush>.list-group-item {
    border-width: 0 0 1px;
}

html.dark-theme .border-top {
    border-top: 1px solid rgb(255 255 255 / 13%) !important;
}

html.dark-theme .email-header {
    background: #1a1e21;
    border-bottom: 1px solid rgb(255 255 255 / 13%);
}

html.dark-theme .email-content {
    background: #1a1e21;
}

html.dark-theme .email-list div.email-message {
    background: #1a1e21;
    border-bottom: 1px solid rgb(255 255 255 / 12%);
    color: #ccced1;
}

html.dark-theme .email-list div.email-message:hover {
    transition: all .2s ease-out;
    background: #434a50;
}

html.dark-theme .bg-body {
    background-color: #16191c !important;
}

html.dark-theme .email-time {
    font-size: 13px;
    color: #8c969e;
}

html.dark-theme .chat-time {
    font-size: 13px;
    color: #8c969e;
}

html.dark-theme .widgets-icons {
    color: #16191c;
}

html.dark-theme .invoice {
    background-color: #1a1e21;
}

/*chat box*/

html.dark-theme .chat-wrapper {
    background: #1a1e21;
    box-shadow: 0 0.1rem 0.7rem rgb(0 0 0 / 22%);
}

html.dark-theme .chat-sidebar {
    background: #1a1e21;
    border-right: 1px solid rgb(255 255 255 / 13%);
}

html.dark-theme .chat-sidebar-header {
    background: #1a1e21;
    border-bottom: 1px solid rgb(255 255 255 / 13%);
    border-right: 0px solid rgb(255 255 255 / 13%);
}

html.dark-theme .chat-header {
    background: #1a1e21;
    border-bottom: 1px solid rgb(255 255 255 / 13%);
}

html.dark-theme .chat-tab-menu li a.nav-link {
    color: #c3c5c8;
}

html.dark-theme .chat-title {
    color: #ccced1;
}

html.dark-theme .chat-msg {
    font-size: 14px;
    color: #848992;
}

html.dark-theme .chat-top-header-menu a {
    color: #c3c5c8;
    background-color: #16191c;
    border: 1px solid rgb(255 255 255 / 15%);
}

html.dark-theme .chat-content-leftside .chat-left-msg {
    background-color: #16191c;
}

html.dark-theme .chat-content-rightside .chat-right-msg {
    background-color: #41484e;
}

html.dark-theme .chat-footer {
    background: #1a1e21;
    border-top: 1px solid rgb(255 255 255 / 13%);
}

html.dark-theme .chat-footer-menu a {
    color: #c3c5c8;
    background-color: #16191c;
    border: 1px solid rgb(255 255 255 / 15%);
}

html.dark-theme .fm-menu .list-group a {
    color: #c3c5c8;
}

html.dark-theme .fm-icon-box {
    background: #16191c;
    color: #ffffff;
}

html.dark-theme .contacts-social a {
    background: #16191c;
}

html.dark-theme .invoice table td,
html.dark-theme .invoice table th {
    background: rgb(255 255 255 / 12%);
    border-bottom: 1px solid rgb(255 255 255 / 12%);
}

html.dark-theme .invoice table .no {
    color: #fff;
    background: #007bff;
}

html.dark-theme .invoice main .notices {
    background: #16191c;
}

html.dark-theme .invoice footer {
    color: #ccced1;
    border-top: 1px solid rgb(255 255 255 / 12%);
}

html.dark-theme .fc-theme-standard td,
html.dark-theme .fc-theme-standard th {
    border: 1px solid rgb(255 255 255 / 0.12);
}

html.dark-theme .fc-theme-standard .fc-scrollgrid {
    border: 1px solid rgb(255 255 255 / 12%);
}

html.dark-theme .fc .fc-col-header-cell-cushion {
    color: #ffffff;
}

html.dark-theme .fc .fc-daygrid-day-number {
    color: #ffffff;
}

/* components */

html.dark-theme .breadcrumb {
    background-color: #16191c;
}

html.dark-theme .breadcrumb-item.active {
    color: #8b969f;
}

html.dark-theme .breadcrumb-title {
    border-right: 1.5px solid rgb(255 255 255 / 18%);
}

html.dark-theme .btn-outline-secondary {
    color: #c5cace;
    border-color: #c2c9ce;
}

html.dark-theme .btn-light {
    color: #ffffff;
    background-color: rgb(255 255 255 / 15%);
    border-color: rgb(255 255 255 / 22%);
}

html.dark-theme .btn-light:hover {
    color: #ffffff;
    background-color: rgb(255 255 255 / 25%);
    border-color: rgb(255 255 255 / 32%);
}

html.dark-theme .btn-dark {
    color: #fff;
    background-color: #181e22;
    border-color: #181e22;
}

html.dark-theme .btn-dark:hover {
    color: #fff;
    background-color: #13181b;
    border-color: #13181b;
}

html.dark-theme .btn-outline-dark {
    color: #ffffff;
    border-color: #181e22;
}

html.dark-theme .page-link {
    color: #b2b6ba;
    background-color: #16191c;
    border: 1px solid rgb(255 255 255 / 0.12);
}

html.dark-theme .page-item.disabled .page-link {
    color: #b0b6bc;
    background-color: rgb(255 255 255 / 28%);
    border-color: rgb(222 226 230 / 14%);
}

html.dark-theme .progress {
    background-color: rgb(255 255 255 / 14%);
}

html.dark-theme .chip {
    color: rgb(198 200 203);
    background-color: #16191c;
    border: 1px solid rgb(255 255 255 / 15%);
}

html.dark-theme .bg-light {
    background-color: rgb(255 255 255 / 15%) !important;
}

html.dark-theme ul.icons li {
    box-shadow: 0 1px 5px #13171a;
    background: #16191c;
}

html.dark-theme ul.icons li i {
    color: #ffffff;
}

html.dark-theme .icon-box {
    background: #1a1e21;
    box-shadow: 0 1px 5px #161a1e;
}

html.dark-theme .pByfF i {
    color: #ffffff;
}

html.dark-theme .form-control {
    color: #c0c8d1;
    background-color: #16191c;
    border: 1px solid rgb(255 255 255 / 34%);
}

html.dark-theme .bootstrap-tagsinput {
    background-color: #16191c;
    border: 1px solid rgb(255 255 255 / 35%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 37%);
    color: #e5e0e0;
}

html.dark-theme .input-group-text {
    color: #d1d7de;
    background-color: #3e464c;
    border: 1px solid rgb(255 255 255 / 35%);
}

html.dark-theme .table-bordered td,
html.dark-theme .table-bordered th {
    border: 1px solid rgb(255 255 255 / 12%);
}

html.dark-theme .table-dark {
    background-color: #1d2126;
}

html.dark-theme .table .thead-light th {
    background-color: rgb(255 255 255 / 20%);
    border-color: #ffffff;
}

html.dark-theme .table-bordered {
    border: 1px solid rgb(255 255 255 / 12%);
}

html.dark-theme .login-card {
    border-right: 1px solid rgb(255 255 255 / 12%) !important;
}

html.dark-theme .border-bottom {
    border-bottom: 1px solid rgb(255 255 255 / 14%) !important;
}

html.dark-theme .navbar-light .navbar-nav .nav-link {
    color: rgb(255 255 255 / 50%);
}

html.dark-theme .navbar-light .navbar-nav .active>.nav-link,
html.dark-theme .navbar-light .navbar-nav .nav-link.active,
html.dark-theme .navbar-light .navbar-nav .nav-link.show,
html.dark-theme .navbar-light .navbar-nav .show>.nav-link {
    color: rgb(255 255 255 / 90%);
}

/* background colors */

html.dark-theme .bg-light-purple {
    background-color: rgb(130 128 255 / 32%) !important;
}

html.dark-theme .bg-light-primary {
    background-color: rgb(103 58 183 / 33%) !important;
}

html.dark-theme .bg-light-danger {
    background-color: rgb(220 53 69 / 33%) !important;
}

html.dark-theme .bg-light-shineblue {
    background-color: rgb(1 215 255 / 33%) !important;
}

html.dark-theme .bg-light-cyne {
    background-color: rgb(0 139 139 / 32%) !important;
}

html.dark-theme .bg-light-warning {
    background-color: rgb(255 193 7 / 32%) !important;
}

html.dark-theme .bg-light-success {
    background-color: rgb(40 167 69 / 33%) !important;
}

html.dark-theme .bg-light-info {
    background-color: rgb(25 143 237 / 22%) !important;
}

html.dark-theme .bg-light-sinata {
    background-color: rgb(165 43 187 / 33%) !important;
}

html.dark-theme .bg-light-mehandi {
    background: rgb(131 152 56 / 32%) !important;
}

html.dark-theme .logo-white {
    display: block;
}

html.dark-theme .logo-dark {
    display: none;
}

html.dark-theme .email-toggle-btn {
    color: #c9cbce;
    background-color: #ffffff00;
}

html.dark-theme .chat-toggle-btn {
    color: #c9cbce;
    background-color: #ffffff00;
    border: 0px solid rgb(0 0 0 / 15%);
}

html.dark-theme .apexcharts-pie-label,
html.dark-theme .apexcharts-datalabels,
html.dark-theme .apexcharts-datalabel,
html.dark-theme .apexcharts-datalabel-label,
html.dark-theme .apexcharts-datalabel-value {
    fill: #fff;
}

/*Responsive*/

@media screen and (max-width: 1024px) {
    html.dark-theme .wrapper.toggled .overlay {
        background: #fff;
        opacity: 0.4;
    }

    html.dark-theme .chat-toggled .overlay {
        background: #fff;
        opacity: 0.4;
    }

    html.dark-theme .email-toggled .overlay {
        background: #fff;
        opacity: 0.4;
    }

    html.dark-theme .full-search-bar {
        background: #1a1e21;
    }

    html.dark-theme .search-bar .btn-search-back {
        background: rgb(3 11 35 / 0%);
        border: 1px solid rgb(217 211 211 / 0%);
        height: calc(1.6em + .75rem + 2px);
    }

    html.dark-theme .search-bar .btn-search-back i {
        color: #ccced1;
    }
}

@media screen and (max-width: 767px) {
    html.dark-theme .right-topbar .navbar-nav .nav-link {
        border-left: 0px solid #ededed;
    }
}

.paginations{
    display: flex;
    justify-content: space-between;
}

.paginations button{
    background-color: #428BFA;
    color: white;
    border-radius: 5px;
    width: 100px;
    height: 5vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
font-size: 17px;
}

.paginations div{
    /* border: 1px solid black; */
width: 35px;
background-color: #EEE;
display: flex;
justify-content: center;
border-radius: 50%;
align-items: center;
font-size: 18px;
}